export const baseURLApi = process.env.REACT_APP_BASE_URL_API
export const baseURLImg = process.env.REACT_APP_BASE_URL_IMG
export const baseURL = process.env.REACT_APP_BASE_URL



// export const baseURLApi = 'https://vakeeltech.b2bsolutions.tech/backend/api/'

// export const baseURLImg = 'https://vakeeltech.b2bsolutions.tech/backend'

// export const baseURL = 'https://vakeeltech.b2bsolutions.tech/backend'


// export const baseURLApi = 'http://localhost:5800/api/'

// export const baseURLImg = 'http://localhost:5800'

// export const baseURL = 'http://localhost:5800'

// export const baseURLApi = 'https://payearthapi.alcyone.in/api/'

// export const baseURLImg = 'https://payearthapi.alcyone.in'

// export const baseURL = 'https://payearthapi.alcyone.in'



// export const baseURLApi = 'http://122.168.125.73:8058/api/'

// export const baseURLImg = 'http://122.168.125.73:8058'
// export const baseURL = 'http://122.168.125.73:8058'

// export const baseURLApi = 'http://192.168.1.21:8058/api/'
 
// export const baseURLImg = 'http://192.168.1.21:8058'
// export const baseURL = 'http://192.168.1.21:8058'



// export const baseURLApi = 'http://122.168.125.73:8058/api/'
 
// export const baseURLImg = 'http://122.168.125.73:8058'
// export const baseURL = 'http://122.168.125.73:8058'
import React, { useEffect, useState } from 'react';
import { Input, Button } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { resetPassword } from '../../services/vakeel/auth-service';
import resetpwdSchema from '../../validation-schemas/vakeel/resetpwd-schema';
import { checkToken } from '../../services/vakeel/profile-service';
import ButtonLoading from '../../components/loading/ButtonLoading';

const checkResetToken = async (setIsValid, token) => {
    const response = await checkToken(token);
    setIsValid(response.data);
}
const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [isTokenValid, setIsValid] = useState(true)
    const params = useParams();
    const token = params.id
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: resetpwdSchema,
        onSubmit: async (values, { resetForm }) => {
            const reqBody = {
                newPassword: values.password,
                token: token
            }
            setLoading(true)
            const response = await resetPassword(reqBody);
            if (response.status === 200) {
                setTimeout(() => {
                    setLoading(false)
                    toast.success("Your Password has been reset successfully.", { autoClose: 3000 });
                    navigate('/')
                }, 2000)
            } else if (response.status === 400 || 500) {
                setTimeout(() => {
                    setLoading(false);
                    toast.error(response.data.message, { autoClose: 3000 });
                }, 2000)
            }
        },
    });

    useEffect(() => {
        checkResetToken(setIsValid, token);
    }, [setIsValid, token]);

    if (!isTokenValid) {
        return (
            <div className='h-screen flex items-center max-w-[600px] m-auto'>
                <div className='container mx-auto px-5 max-w-screen-xl max-md:px-3'>
                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo mx-auto rounded-lg max-md:py-5 max-md:px-5 mt-5 '>
                        <p className='text-md text-center text-white font-medium mb-1'>We're sorry, but it seems like the link to reset your password has expired. For security reasons, password reset links are only valid for a limited time.</p>
                        <p className='text-md text-center text-white font-medium mb-1'>Please initiate the 'Forgot Password' process again to receive a new link and reset your password.</p>
                        <div className='mt-3'><Button className='w-fit m-auto bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => {
                            navigate('/forgot-password')
                        }}>Forgot Password</Button></div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='size-full flex min-h-screen'>
            <div className="min-h-screen min-h-ful w-2/5 max-lg:hidden max-xl:block">
                <div className='wrap_bg bg-cover bg-center w-full h-full bg-no-repeat'></div>
            </div>
            <div className='min-h-screen size-full flex items-center justify-center w-3/5 flex-col gap-6 px-4 max-lg:w-full py-4'>
                <img className='' src={header_logo} alt=''></img>
                <div className='w-full max-w-xl py-8 px-10 flex flex-col justify-center bg-popo rounded-lg max-lg:py-4 max-lg:px-5'>
                    <div className=' mb-7 max-lg:mb-4 flex items-center gap-3'>
                        <h2 className='lg:text-2xl md:text-xl sm:text-xl text-xl font-bold mb-0 text-white'>Reset your password</h2>
                    </div>
                    <div className="w-full">
                        <form onSubmit={formik.handleSubmit}>
                            <div className='mb-3'>
                                <Input className='rounded-md' type="password" size="lg" placeholder="Password" name="password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} />
                                {formik.touched.password && formik.errors.password ? (
                                    <small className="text-danger validationError">{formik.errors.password}</small>
                                ) : ''}
                            </div>
                            <div className='mb-3'>
                                <Input className='rounded-md' type="password" size="lg" placeholder="Confirm Password" name="confirmPassword" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.confirmPassword} />
                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <small className="text-danger validationError">{formik.errors.confirmPassword}</small>
                                ) : ''}
                            </div>
                            <Button className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type="submit" size="lg" disabled={!formik.isValid}> {loading &&
                                <ButtonLoading />
                            }Submit</Button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' />
        </div>
    )
}
export default ResetPassword;
import React, { useState } from 'react'
import { Input, Button, IconButton } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { ControlsChevronLeft } from '@heathmont/moon-icons-tw';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import forgotpwdSchema from '../../validation-schemas/vakeel/forgotpwd-schema';
import { forgotPassword } from '../../services/vakeel/auth-service';
import ButtonLoading from '../../components/loading/ButtonLoading';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: forgotpwdSchema,
        onSubmit: async (values, { resetForm }) => {
            const reqBody = {
                email: values.email
            }
            setLoading(true)
            const response = await forgotPassword(reqBody);
            if (response.status === 200) {
                setTimeout(() => {
                    setLoading(false)
                    toast.success("Mail Sent: Please check your email to reset password.", { autoClose: 3000 });
                    navigate('/');
                }, 2000)
            } else if (response.status === 404) {
                setTimeout(() => {
                    toast.error(response.data.message, { autoClose: 3000 });
                    setLoading(false)
                }, 2000)
            }
        },
    });

    return (
        <div className='size-full flex min-h-screen'>
            <div className="min-h-screen min-h-ful w-2/5 max-lg:hidden max-xl:block">
                <div className='wrap_bg bg-cover bg-center w-full h-full bg-no-repeat'></div>
            </div>
            <div className='min-h-screen size-full flex items-center justify-center w-3/5 flex-col gap-6 px-4 max-lg:w-full py-4'>
                <img className='' src={header_logo} alt=''></img>
                <div className='w-full max-w-xl py-8 px-10 flex flex-col justify-center bg-popo rounded-lg max-lg:py-4 max-lg:px-5'>
                    <div className=' mb-7 max-lg:mb-4 flex items-center gap-3'>
                        {loading ? (
                            <span>
                                <IconButton className='bg-popo' icon={<ControlsChevronLeft />} disabled />
                            </span>
                        ) : (
                            <Link to="/">
                                <IconButton className='bg-popo' icon={<ControlsChevronLeft />} />
                            </Link>
                        )}
                        <h2 className='lg:text-2xl md:text-xl sm:text-xl text-xl text-goten font-bold mb-0'>Forgot Password</h2>
                    </div>
                    <div className="w-full">
                        <form onSubmit={formik.handleSubmit}>
                            <div className='mb-3 relative pb-4'>
                                <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="email" size="lg" readOnly={loading ? true : false} placeholder="Email Address" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                {formik.touched.email && formik.errors.email ? (
                                    <small className="text-danger validationError">{formik.errors.email}</small>
                                ) : ''}
                            </div>
                            <Button className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type="submit" size="lg" disabled={!formik.isValid || loading}> {loading &&
                                <ButtonLoading />
                            }Submit
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' />
        </div>
    )
}

export default ForgotPassword;
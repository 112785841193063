import React, { useEffect, useState } from 'react'
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from 'react-router-dom';
import { appointmensByDate, appointmentList, caseDetails } from '../../services/vakeel/dashboard-service';
import { dateFormatter, formateTimeZone, getFormattedTime } from '../../utils/date-formatter';
import EventModal from './EventModal';
// import { casesDetails } from '../../services/user/case-service';
// export let caseDetails = {}
const FullCalendar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [dateEvents, setDateEvents] = useState([]);
    const [eventDate, setEventDate] = useState('');
    const [events, setEvents] = useState([
    ]);
    const navigate = useNavigate()
    const handleEventClick = (info) => {
        let data = info.event._def.extendedProps.data
        viewDetails(data)
    };
    const viewDetails = async (data) => {
        data = { ...data, appointment_date: dateFormatter(data.appointment_date) }
        localStorage.setItem('eventDetails', JSON.stringify(data));
        const response = await caseDetails(data?.appointment_id);
        console.log('responseDetails', response)
        if (Object.keys(response.data).length > 0) {
            navigate(`/case-detail/${data.appointment_id}`)
        }
    }
    const getAppointments = async (vakeelId) => {
        const response = await appointmentList(vakeelId);
        if (response.status === 200) {
            const { data } = response
            if (data.length > 0) {
                let arr = []
                let obj = {}
                data.forEach((x) => {
                    obj = {
                        title: x.client_first_name ? `${x.client_first_name} ${x.client_last_name}` : "Anonymous User",
                        date: dateFormatter(x.appointment_date), data: { ...x, start_time: formateTimeZone(x.start_time), end_time: formateTimeZone(x.end_time) }
                    }
                    arr.push(obj)
                })
                setEvents(arr)
                console.log('data', data)
                localStorage.setItem('allCases', JSON.stringify(arr));
            }
        }
    }

    useEffect(() => {
        getAppointments(userInfo.user_id);
    }, []);

    const handleDateClick = async (info) => {
        setEventDate(info.dateStr)
        const response = await appointmensByDate(userInfo.user_id, info.dateStr);
        if (response.status === 200) {
            if (response.data.length > 0) {
                setDateEvents(response.data)
                openModal()
            }
        }
    }
    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <div>
            <Fullcalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                headerToolbar={{
                    start: "today prev,next", // will normally be on the left. if RTL, will be on the right
                    center: "title",
                    end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
                }}
                // height={"90vh"}
                events={events}
                editable={true}
                selectable={true}
                // select={(info) => {
                //    console.log('info', info)
                // }}
                eventClick={handleEventClick}
                dateClick={(info) => handleDateClick(info)}
                eventContent={(eventInfo) => {
                    const data = eventInfo.event._def.extendedProps.data
                    const start_time = getFormattedTime(data?.start_time)
                    // const end_time = getFormattedTime(data?.end_time)
                    return (
                        <div style={{ display: "flex", justifyContent: 'space-between', padding: "2px", flexWrap: 'wrap', overflow: 'hidden' }}>
                            <p className='capitalize'>{eventInfo.event.title}</p>
                            <p>{start_time}</p>
                        </div>
                    )
                }
                }
                // validRange={{
                //     start: currentDate,
                //   }}
                eventDrop={(info) => console.log('event-info', info)}

            />
            <EventModal isOpen={isOpen} closeModal={closeModal} dateEvents={dateEvents} eventDate={eventDate} viewDetails={viewDetails} />
        </div>
    )
}

export default FullCalendar;

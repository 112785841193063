import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ControlsChevronLeft, ControlsChevronRight, ControlsClose, DevicesPhone, FilesFolderOpen, GenericDownload, MailLink, MapsMarker, TimeClock } from '@heathmont/moon-icons-tw'
import userimg from '../../images/pp_img.png'
import userIcon from '../../images/usericon_l.svg'
import { Button, Drawer, IconButton, Input, Modal, NativeSelect, Textarea } from '@heathmont/moon-core-tw'
import Navbar from '../../components/vakeel/Navbar'
import { addremarks, allVakeelCase, approve, caseDetails, rescheduleAppointment } from '../../services/vakeel/dashboard-service'
import { dateFormatter, formateDate, formateDateShort, getFormattedTime } from '../../utils/date-formatter'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import rescheduleSchema from '../../validation-schemas/vakeel/reschedule-schema'
import { baseURLApi, baseURLImg } from '../../config'
import Comments from '../comments/Comments'
import axios from 'axios'
import { getEvidenceFile } from '../../services/user/case-service'


const CaseDetail = () => {
    const params = useParams();
    const id = params.id
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [reLoad, setReload] = useState(false);
    const [reMark, setRemark] = useState('');

    const closeModal = () => {
        setIsOpen(false)
        formik.resetForm()
        setEndTime('')
    };
    const openModal = () => setIsOpen(true);

    const [isOpenRemark, setIsOpenRemark] = useState(false);
    const closeModalRemark = () => setIsOpenRemark(false);
    const openModalRemark = () => setIsOpenRemark(true);

    const [isOpenFiles, setIsOpenFiles] = useState(false);
    const closeModalFiles = () => {
        setIsOpenFiles(false);
        // setEvidence([])
    };
    const openModalFiles = () => setIsOpenFiles(true);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const eventDetails = JSON.parse(localStorage.getItem('eventDetails'));
    console.log("=>>>>>>>>>>>>>>>>>>>>>>",eventDetails)
    const profileApproved = localStorage.getItem('profileApproved');
    // let allCases = JSON.parse(localStorage.getItem('allCases'));
    // allCases = allCases.filter((x) => x.data.appointment_id != eventDetails.appointment_id);

    useEffect(() => {
        if (profileApproved === 0) {
            navigate('/profile-submitted');
        }
    })
    const [otherCases, setOtherCases] = useState([])
    const getAllVakeelCases = async () => {
        const response = await allVakeelCase(userInfo?.user_id);
        console.log('all-cases', response);
        if (response.status === 200) {
            // console.log('other-cases', response.data.filter((x) => x.appointment_id != eventDetails.appointment_id))
            setOtherCases(response.data.filter((x) => x?.appointment_id != eventDetails?.appointment_id));
        }
    };

    useEffect(() => {
        getAllVakeelCases()
        // getAllCase();
    }, [id]);

    const [isStartOpen, setIsStartOpen] = useState(true);
    const handleStartClick = () => setIsStartOpen(true);

    const [caseBrief, setCaseBrief] = useState({});
    const [remarks, setRemarks] = useState([]);
    const [evidence, setEvidence] = useState([])
    const [urls, setUrls] = useState([]);

    const getCaseDetails = async () => {
        // const response = await caseDetails(51);
        setEvidence([])
        const response = await caseDetails(eventDetails?.appointment_id);
        if (response.status === 200) {
            setCaseBrief(response.data);
            console.log('case_details', response.data);
            setRemarks(response.data.remark);
            if (response.data.evidence_url != null) {
                const arr = [];
                response.data.evidence_url.forEach((x) => {
                    arr.push(...x)
                })
                setEvidence(arr)
            }
        }
    }


    useEffect(() => {
        getCaseDetails();
    }, [id]);

    const handleReschedule = async (reqBody, values) => {
        // alert(1)
        const response = await rescheduleAppointment(reqBody);

        if (response.status === 200) {
            setReload(!reLoad);
            let data = { ...eventDetails };
            data = { ...data, appointment_date: values.appointment_date, start_time: `${values.appointment_date} ${values.start_time}`, end_time: `${values.appointment_date} ${values.end_time}` }
            localStorage.setItem('eventDetails', JSON.stringify(data));
            toast.success("Appointment scheduled successfully.", { autoClose: 3000 });
        }
    }

    const [endTime, setEndTime] = useState('');
    const formik = useFormik({
        initialValues: {
            appointment_date: '',
            start_time: '',
            end_time: endTime,
        },
        validationSchema: rescheduleSchema,
        onSubmit: async (values) => {
            const reqBody = {
                appointment_date: values.appointment_date,
                start_time: `${values.appointment_date} ${values.start_time}`,
                end_time: `${values.appointment_date} ${endTime}`,
                appointment_id: eventDetails?.appointment_id
            }
            // console.log('values', reqBody)
            // return
            closeModal()
            handleReschedule(reqBody, values);
            // getCaseDetails()
        },
    });
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    // useEffect(() => {
    //     const disableTime = new Date(eventDetails?.start_time);

    //     const disableThreshold = new Date(disableTime);
    //     disableThreshold.setMinutes(disableTime.getMinutes() - 1440);

    //     const checkAndDisableButton = () => {
    //         const currentTime = new Date();

    //         if (currentTime >= disableThreshold) {
    //             setButtonDisabled(true);
    //             console.log('Button disabled!');
    //         } else {
    //             console.log('Button still enabled.');
    //         }
    //     };

    //     checkAndDisableButton();

    //     const intervalId = setInterval(checkAndDisableButton, 1000); // Check every second

    //     return () => {
    //         clearInterval(intervalId);
    //         setButtonDisabled(false)
    //     };

    // }, [id, reLoad]);

    const [schedule, setSchedule] = useState(false)
    // useEffect(() => {
    //     const enableTime = new Date(eventDetails?.start_time);

    //     // // Calculate the time 2 hours after the enable time

    //     const enableThreshold = new Date(enableTime);
    //     enableThreshold.setMinutes(enableTime.getMinutes() + 1);

    //     const checkAndEnableButton = () => {
    //         const currentTime = new Date();

    //         if (currentTime >= enableThreshold) {
    //             setButtonDisabled(false);
    //             setSchedule(true);
    //         } else {
    //         }
    //     };

    //     checkAndEnableButton();

    //     const intervalId = setInterval(checkAndEnableButton, 1000); // Check every second

    //     return () => {
    //         clearInterval(intervalId);
    //         setSchedule(false);
    //     };

    // }, [id, reLoad]);


    const [minDate, setMinDate] = useState('');

    useEffect(() => {
        const setMinimumDate = () => {
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0];
            setMinDate(formattedDate);
        };

        setMinimumDate();
    }, []);

    const addRemark = async () => {
        const currentDate = new Date();
        // Format the date as a string in ISO 8601 format
        const isoDateString = currentDate.toISOString();

        const reqBody = {
            datetime: isoDateString,
            content: reMark
        }
        const response = await addremarks(caseBrief?.case_detail_id, reqBody);
        if (response.status === 200) {
            toast.success("Remark added successfully.", { autoClose: 3000 });
            closeModalRemark();
            setRemark('')
            getCaseDetails()
        } else {
            toast.error("Internal server error.", { autoClose: 3000 });
        }
    }
    const approveConsultation = async () => {
        const response = await approve(eventDetails?.appointment_id);
        if (response.status === 200) {
            toast.success('You have accepted case successfully.');
            getCaseDetails()
        } else {
            console.log('something went wrong');
            toast.error('something went wrong')
        }
    }

    const timeSlots = [
        '9:00 AM', '9:20 AM', '9:40 AM',
        '10:00 AM', '10:20 AM', '10:40 AM',
        '11:00 AM', '11:20 AM', '11:40 AM',
        '12:00 PM', '12:20 PM', '12:40 PM',
        '1:00 PM', '1:20 PM', '1:40 PM',
        '2:00 PM', '2:20 PM', '2:40 PM',
        '3:00 PM', '3:20 PM', '3:40 PM',
        '4:00 PM', '4:20 PM', '4:40 PM',
        '5:00 PM', '5:20 PM', '5:40 PM',
        '6:00 PM', '6:20 PM', '6:40 PM',
        '7:00 PM', '7:20 PM', '7:40 PM',
        '8:00 PM', '8:20 PM', '8:40 PM',
        '9:00 PM'
        // Add more time slots as needed
    ];

    const handleTimeSelect = (selectedTime) => {
        // Find the index of the selected time
        const selectedIndex = timeSlots.indexOf(selectedTime);

        // Update the state with the next time slot (45 minutes later)
        if (selectedIndex !== -1 && selectedIndex + 1 < timeSlots.length) {
            setEndTime(timeSlots[selectedIndex + 2]);
            console.log(timeSlots[selectedIndex + 3])
        }
    };

    const today = new Date();
    const minDateR = new Date(today);
    minDateR.setDate(today.getDate() + 3); // Minimum date is 3 days from today

    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 23); // Maximum date is 23 days from today

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getFile = async (case_id, files) => {
        try {
            //   const formData = new FormData();
            //   formData.append('image', file);
            console.log(files)
            const reqBody = {
                file_name: files,
                caseId: case_id,
                isFetchUrl: true

            }
            // Make a POST request to your Node.js server for file upload
            const response = await getEvidenceFile(reqBody)//await axios.post(`${baseURLApi}user/get-file`, reqBody);
            if (response.status == 200) {
                console.log('response', response)
                setUrls(response.data)
                openModalFiles();
            }
            console.log('File fetched successfully', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-20'>
                <div className='mx-auto px-5 max-md:px-3'>

                    <Link onClick={handleStartClick} type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none">
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </Link>

                    <div className='flex gap-5'>

                        <Drawer open={isStartOpen} setOpen={setIsStartOpen} className='w-64 z-10 md:hidden'>
                            <Drawer.Panel className='w-64 z-10' position="start">
                                <div className='h-full fixed top-0 left-0 z-10 pt-24 px-6 bg-popo mx-auto w-64' aria-label="Sidebar">
                                    <Link to='/dashboard' className='text-[18px] font-normal text-white border-b pb-4 mb-4 flex gap-2 items-center focus:outline-none'> <ControlsChevronLeft className='text-[25px]' />
                                        Calendar View</Link>
                                    <h4 className='mb-0 text-[16px]' style={{ color: '#ffffff96' }}>Vakeel's Other cases</h4>
                                    {
                                        otherCases.length >= 1 ? '' :
                                            <h4 className='mb-0 text-[16px] text-zinc-500'>Cases not found</h4>

                                    }
                                    <ul className='my-4'>
                                        {/* {allCases && allCases.map((x, i) => {
                                            return (
                                                <li className='mb-4' onClick={() => {
                                                    let data = x.data
                                                    data = { ...data, appointment_date: dateFormatter(data.appointment_date) }
                                                    localStorage.setItem('eventDetails', JSON.stringify(data));
                                                    navigate(`/case-detail/${data?.appointment_id}`)
                                                }}><Link className='flex gap-3'><span className='max-w-[26px] min-w-[26px] max-h-[25px] min-h-[25px] rounded-[5px] flex align-middle justify-center border' >{x.data.case_name.charAt(0)}</span>{x.data.case_name}</Link></li>
                                            )
                                        })} */}
                                        {otherCases.length > 0 && otherCases.map((value, i) => {
                                            console.log('otherCases', otherCases)
                                            return (
                                                <li className='mb-4' onClick={() => {
                                                    // let data = x.data
                                                    // data = { ...data, appointment_date: dateFormatter(data.appointment_date) }
                                                    // localStorage.setItem('eventDetails', JSON.stringify(data));
                                                    // navigate(`/case-detail/${data?.appointment_id}`)
                                                    let data = {
                                                        "appointment_id": value?.appointment_id,
                                                        "client_user_id": value?.client_id,
                                                        "appointment_date": value?.appointment_date,
                                                        "start_time": value?.start_time,
                                                        "end_time": value?.end_time,
                                                        "case_name": "",
                                                        "case_type": "",
                                                        "status": true,
                                                        "client_first_name": value?.client_first_name,
                                                        "client_last_name": value?.client_last_name,
                                                        "lawyer_first_name": '',
                                                        "lawyer_last_name": ''
                                                    }
                                                    localStorage.setItem('eventDetails', JSON.stringify(data));
                                                    navigate(`/case-detail/${value?.appointment_id}`)
                                                }}>
                                                    <Link className='flex gap-3 text-white'>#case{value?.case_detail_id}{value?.case_name}</Link>
                                                    {/* <Link className='flex gap-3'><span className='max-w-[26px] min-w-[26px] max-h-[25px] min-h-[25px] rounded-[5px] flex align-middle justify-center border'>{value?.case_name.charAt(0)}</span>{value?.case_name}</Link> */}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </Drawer.Panel>
                        </Drawer>

                        <div className='h-full fixed top-0 left-0 z-10 pt-24 px-6 bg-popo mx-auto rounded-lg w-64 max-md:hidden' aria-label="Sidebar">
                            <Link to='/dashboard' className='text-[18px] font-normal text-white border-b pb-4 mb-4 flex gap-2 items-center focus:outline-none'> <ControlsChevronLeft className='text-[25px]' /> Calendar View</Link>
                            <h4 className='mb-0 text-[16px] text-white opacity-75'>Vakeel's Other cases</h4>
                            {
                                otherCases.length >= 1 ? '' :
                                    <h4 className='mb-0 text-[16px] text-white opacity-75'>Cases not found</h4>

                            }
                            <ul className='my-4 sidebar_scroll'>
                                {otherCases?.length > 0 && otherCases.map((value, i) => {
                                    return (
                                        <li className='mb-4' onClick={() => {
                                            // let data = x.data
                                            // data = { ...data, appointment_date: dateFormatter(data.appointment_date) }
                                            // localStorage.setItem('eventDetails', JSON.stringify(data));
                                            // navigate(`/case-detail/${data?.appointment_id}`)
                                            let data = {
                                                "appointment_id": value?.appointment_id,
                                                "client_user_id": value?.client_id,
                                                "appointment_date": value?.appointment_date,
                                                "start_time": value?.start_time,
                                                "end_time": value?.end_time,
                                                "case_name": "",
                                                "case_type": "",
                                                "status": true,
                                                "client_first_name": value?.client_first_name,
                                                "client_last_name": value?.client_last_name,
                                                "lawyer_first_name": '',
                                                "lawyer_last_name": ''
                                            }
                                            localStorage.setItem('eventDetails', JSON.stringify(data));
                                            navigate(`/case-detail/${value?.appointment_id}`)
                                        }}>
                                            <Link className='flex gap-3 text-white opacity-80 hover:opacity-100'>#Case-{value?.case_detail_id} {value?.case_name}</Link>
                                            {/* <Link className='flex gap-3'><span className='max-w-[26px] min-w-[26px] max-h-[25px] min-h-[25px] rounded-[5px] flex align-middle justify-center border'>{value?.case_name.charAt(0)}</span>{value?.case_name}</Link> */}
                                        </li>
                                    )
                                })}
                                {/* {allCases && allCases.map((x, i) => {
                                    return (
                                        <li className='mb-4' onClick={() => {
                                            let data = x.data
                                            data = { ...data, appointment_date: dateFormatter(data.appointment_date) }
                                            localStorage.setItem('eventDetails', JSON.stringify(data));
                                            navigate(`/case-detail/${data?.appointment_id}`)
                                        }}><Link className='flex gap-3'><span className='max-w-[26px] min-w-[26px] max-h-[25px] min-h-[25px] rounded-[5px] flex align-middle justify-center border'>{x.data.case_name.charAt(0)}</span>{x.data.case_name}</Link></li>
                                    )
                                })} */}
                            </ul>
                        </div>

                        {Object.keys(caseBrief).length > 0 &&
                            <div className='flex gap-5 max-xl:flex-col ml-64 max-md:ml-0 w-full'>
                                <div className='w-full  flex flex-col bg-popo mx-auto rounded-lg relative min-w-[570px] max-xl:min-w-full'>

                                    <div className='py-6 px-6 max-md:py-4 max-md:px-4 middle_col_scroller'>
                                        <div className='flex items-top gap-3 max-md:gap-3 border-b pb-4'>
                                            <div>
                                                <img className='min-w-10 max-w-10 min-h-10 max-h-10 border rounded-full max-md:min-w-10 max-md:max-w-10 max-md:min-h-10 max-md:max-h-10 object-cover' src={caseBrief.image_url ? `${baseURLImg}/${caseBrief?.image_url}`:userIcon  } alt='#'></img>
                                                {/* <img className='min-w-10 max-w-10 min-h-10 max-h-10 border rounded-full max-md:min-w-10 max-md:max-w-10 max-md:min-h-10 max-md:max-h-10 object-cover' src={`${baseURLImg}/${eventDetails?.image_url}`} alt='#'></img> */}
                                            </div>
                                            <div className='flex flex-col gap-2'>
                                                <h4 className='mb-0 lg:text-[25px] md:text-[22px] sm:text-[20px] text-[20px] font-medium text-white leading-5 capitalize'>
                                                    {eventDetails?.client_first_name ? eventDetails?.client_first_name + " " + eventDetails?.client_last_name : "Anonymous User"}
                                                    {/* {`${eventDetails?.client_first_name} ${eventDetails?.client_last_name}`} */}
                                                </h4>
                                                <p className='mb-0 text-[16px] font-normal text-white'>{formateDate(eventDetails?.appointment_date)}, {new Date(eventDetails?.appointment_date).getFullYear()}</p>
                                                {/* <p className='mb-0 text-[16px] font-normal text-black'>Tuesday, January 29, 2024</p> */}
                                            </div>
                                        </div>
                                        <div className='border-b py-4'>
                                            <div className='flex items-center gap-5 mb-3 text-white max-md:gap-3 w-fit'>
                                                {/* <div className=''><MapsMarker className='text-white text-2xl' /></div> */}
                                                <div><p className='text-[16px] font-normal '>{caseBrief?.address}</p></div>
                                            </div>
                                            <div className='w-full max-md:py-4 rounded-lg mb-5'>
                                                <ul className={`flex flex-col gap-2`}>
                                                    <li className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white'><TimeClock className='max-w-[20px] min-w-[20px] text-[20px]' /> {getFormattedTime(caseBrief?.start_time)}-{getFormattedTime(caseBrief?.end_time)} | {formateDate(caseBrief?.appointment_date)}, {new Date(eventDetails?.appointment_date).getFullYear()}</li>
                                                    {/* <li className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white'><TimeClock className='max-w-[20px] min-w-[20px] text-[20px]' /> 11 - 12am | Tuesday, January 29, 2024</li> */}
                                                    <li><a className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-chichi opacity-75 underline underline-offset-1 hover:opacity-100' href={caseBrief?.meet_link} target='_blank'><MailLink className='max-w-[20px] min-w-[20px] text-[20px]' />{caseBrief?.meet_link}</a> </li>
                                                    {/* <li><Link className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white hover:text-indigo-900'><DevicesPhone className='max-w-[20px] min-w-[20px] text-[20px]' /> +91-9356624532</Link> </li> */}
                                                </ul>
                                            </div>
                                            {/* <Link className='inline-flex items-center gap-5 hover:text-purple-600 focus:outline-none text-white max-md:gap-3 w-fit'>
                                            <div className=''><DevicesPhone className='text-white text-2xl' /></div>
                                            <div><p className='text-[16px] font-normal '>+91-9356624532</p></div>
                                        </Link> */}
                                        </div>
                                        <div className='pt-4 pb-2'>
                                            <p className='text-[20px] font-medium text-white capitalize'>#Case-{caseBrief?.case_detail_id} {caseBrief?.case_name}</p>
                                            <h4 className='mb-0 lg:text-[25px] md:text-[22px] sm:text-[20px] text-[20px] font-medium text-white capitalize'>Case Brief{` (${caseBrief?.case_name})`}</h4>
                                            <ul className='my-4'>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Status :</span>
                                                    <p className='w-full mb-0 text-[16px] font-normal text-white'> {caseBrief?.case_status || 'Open'}</p>
                                                </li>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Type of Incident :</span>
                                                    <p className='w-full mb-0 text-[16px] font-normal text-white'>{caseBrief?.incident_type}</p>
                                                </li>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Incident occurrence date :</span>
                                                    <p className='w-full mb-0 text-[16px] font-normal text-white'>{dateFormatter(caseBrief?.incident_date)}</p>
                                                    {/* <p className='w-full mb-0 text-[16px] font-normal text-black'> 31st November 2023 | 10:15 am </p> */}
                                                </li>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Incident Location :</span>
                                                    <p className='w-full t-lg text-[16px] text-white'> {caseBrief?.incident_location}</p>
                                                </li>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Witness details :</span>
                                                    <p className='w-full mb-0 text-[16px] font-normal text-white'> {caseBrief?.witness_details} </p>
                                                </li>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Suspect detail :</span>
                                                    <p className='w-full mb-0 text-[16px] font-normal text-white'> {caseBrief?.suspect_details} </p>
                                                </li>
                                                <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                    <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Damages in the incident :</span>
                                                    <p className='w-full mb-0 text-[16px] font-normal text-white'> {caseBrief?.damages_desc}</p>
                                                </li>
                                            </ul>
                                            <h4 className='mb-0 lg:text-[25px] md:text-[22px] sm:text-[20px] text-[20px] font-medium text-white'>Remark/Feedback</h4>
                                            {remarks?.length > 0 && remarks.map((x) => {
                                                return (
                                                    <>
                                                        <div className='my-3'>
                                                            <p className='min-w-[220px] mb-0 text-[14px] text-white opacity-75'>{formateDateShort(x.datetime)} {getFormattedTime(x.datetime)}</p>
                                                            <p className='w-full mb-0 text-[16px] font-normal text-white'>{x.content}</p>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>

                                    <div className='w-full py-5 px-5 bg-popo mx-auto rounded-lg max-md:py-4 max-md:px-4 shadow-[0px_-5.338px_21.352px_0px_rgba(0,0,0,0.16)] max-lg:py-3 max-lg:px-3'>
                                        <div className='flex justify-between gap-3 max-sm:gap-1 max-sm:flex-col'>
                                            <div>
                                                {/* <a href={caseBrief?.evidence_url} target="_blank"> */}
                                                <Button onClick={() => {
                                                    let arr = []
                                                    evidence.forEach((x) => {
                                                        arr.push(x.name);
                                                    })
                                                    // console.log('arr', arr)
                                                    getFile(caseBrief?.case_detail_id, arr)
                                                }} disabled={evidence.length == 0} size="lg" className='py-5 px-10 border bg-transparent hover:border text-white' iconLeft={<FilesFolderOpen />} variant="outline">Shared Folder</Button>

                                                {/* </a> */}
                                            </div>
                                            <div className='flex gap-2 max-sm:justify-between'>
                                                <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={openModalRemark}>Remark / Feedback</Button>
                                                {/* <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={openModal}>Reschedule</Button> */}
                                                {caseBrief?.is_confirm ?
                                                    <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={openModal}>Reschedule</Button>
                                                    :
                                                    <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => approveConsultation()}>Accept Case Request</Button>
                                                }
                                                {/* <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={openModal} disabled={isButtonDisabled}>{schedule ? 'Schedule' : 'Reschedule'}</Button> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {
                                    eventDetails?.client_user_id != undefined &&
                                    <Comments scroll='chat_scroller chat_scroller_vakeel' receiverId={eventDetails?.client_user_id} room={`userId${eventDetails?.client_user_id}-lawyerId${userInfo?.user_id}`} is_confirm={caseBrief?.is_confirm} case_detail_id={caseBrief?.case_detail_id}/>
                                    // <Comments scroll='chat_scroller chat_scroller_vakeel' receiverId={eventDetails?.client_user_id} room={`userId${eventDetails?.client_user_id}-lawyerId${userInfo?.user_id}`} is_confirm={caseBrief?.is_confirm} />
                                }

                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal open={isOpen} onClose={closeModal} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            {schedule ? 'Schedule' : 'Reschedule'}
                        </h3>
                        <div className="">
                            {/* onClick={closeModal} */}
                            <IconButton onClick={closeModal} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        {/* <form onSubmit={(e) => handleReschedule(e)}> */}
                        {/* <div className="p-4">
                            <div>
                                <div className='mb-3'>
                                    <label className='block mb-2 text-[16px] font-normal '>Appointment Date</label>
                                    <Input className='rounded-md' type="date" min={minDate} size="md" placeholder="Appointment Date"
                                        // value={appointmentDate}
                                        // onChange={(e) => setAppointmentDate(e.target.value)}
                                        name='appointment_date'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.appointment_date
                                        }
                                    />
                                    {formik.touched.appointment_date && formik.errors.appointment_date ? (
                                        <small className="text-danger validationError">{formik.errors.appointment_date}</small>
                                    ) : ''}
                                </div>
                                <div className='flex gap-3 w-full'>
                                    <div className='mb-3 w-full'>
                                        <label className='block mb-2 text-[16px] font-normal '>Start Time</label>
                                        <Input className='rounded-md' type="time" size="md" placeholder="Start"
                                            name='start_time'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start_time}
                                        />
                                        {formik.touched.start_time && formik.errors.start_time ? (
                                            <small className="text-danger validationError">{formik.errors.start_time}</small>
                                        ) : ''}

                                    </div>
                                    <div className='mb-3 w-full'>
                                        <label className='block mb-2 text-[16px] font-normal '>End Time</label>
                                        <Input className='rounded-md' type="time" size="md" placeholder="End"
                                            name='end_time'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end_time}
                                        />
                                        {formik.touched.end_time && formik.errors.end_time ? (
                                            <small className="text-danger validationError">{formik.errors.end_time}</small>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="p-4">
                            <div>
                                <div className='mb-4 pb-2 relative'>
                                    <label className='block mb-2 text-[16px] font-normal text-white'>Appointment Date</label>
                                    <Input className='rounded-md' type="date" size="md" placeholder="Appointment Date"
                                        name='appointment_date'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.appointment_date}
                                        min={formatDate(minDateR)}
                                        max={formatDate(maxDate)}

                                    />
                                    {formik.touched.appointment_date && formik.errors.appointment_date ? (
                                        <small className="text-danger validationError">{formik.errors.appointment_date}</small>
                                    ) : ''}
                                </div>

                                <div className='flex gap-3 w-full'>
                                    <div className='mb-4 pb-2 w-full relative'>
                                        <label className='block mb-2 text-[16px] text-white font-normal '>Start Time</label>
                                        <NativeSelect size="lg" name='start_time' className='text-white' onChange={(e) => {
                                            formik.handleChange(e)
                                            handleTimeSelect(e.target.value)
                                        }} onBlur={formik.handleBlur} value={formik.values.start_time}>
                                            <option value="">Select start time</option>
                                            {timeSlots.slice(0, 35).map((slot) => (
                                                <option key={slot} value={slot}>{slot}</option>
                                            ))}
                                        </NativeSelect>
                                        {formik.touched.start_time && formik.errors.start_time ? (
                                            <small className="text-danger validationError">{formik.errors.start_time}</small>
                                        ) : ''}
                                    </div>
                                    <div className='mb-4 pb-2 w-full relative'>
                                        <label className='block mb-2 text-[16px] font-normal text-white'> End Time</label>
                                        <NativeSelect size="lg" name='end_time' className='text-white' onChange={formik.handleChange} onBlur={formik.handleBlur} value={endTime} readOnly>
                                            <option value=''>End time</option>
                                            <option value={endTime}>{endTime}</option>
                                        </NativeSelect>
                                        {/* <NativeSelect size="lg" name='end_time' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.end_time}>
                                                        <option value="">Select end time</option>
                                                        {timeSlots.map((slot) => (
                                                            <option key={slot} value={slot}>{slot}</option>
                                                        ))}
                                                    </NativeSelect> */}
                                        {formik.touched.end_time && formik.errors.end_time ? (
                                            <small className="text-danger validationError">{formik.errors.end_time}</small>
                                        ) : ''}
                                    </div>
                                </div>
                                <div>
                                    <small className='text-white'>Please select time in between 9 AM to 9 PM. </small>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 border-t-2 border-beerus flex justify-end">
                            <Button disabled={!formik.isValid} className='bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type='submit' size='lg'>Submit</Button>
                        </div>
                    </form>
                </Modal.Panel>
            </Modal>

            {/* remark/feedback */}
            <Modal open={isOpenRemark} onClose={closeModalRemark} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            Add a remark
                        </h3>
                        <div className="">
                            <IconButton onClick={closeModalRemark} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className='mb-3'>
                                <Textarea className="rounded-md" size="lg" name='remark' onChange={(e) => setRemark(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="p-4 border-t-2 border-beerus flex justify-end">
                        <Button onClick={addRemark} size='lg' className='w-fit rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' disabled={reMark === ''}>Submit</Button>
                    </div>
                </Modal.Panel>
            </Modal>

            <Modal open={isOpenFiles} onClose={closeModalFiles} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            Uploaded Files
                        </h3>
                        <div className="">
                            {/* onClick={closeModal} */}
                            <IconButton onClick={closeModalFiles} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <ul className="p-4 modal_scroll">
                        {caseBrief && urls?.length > 0 ? urls.map((x, index) => {
                            return (
                                <li className='mb-2' key={index}>
                                    <a href={x.url} target='_blank' className='text-md visited:text-white font-medium text-white opacity-70 hover:text-white hover:opacity-100 focus:outline-none w-fit
                    flex items-center gap-2'># {x.name}</a>
                                </li>

                            )
                        }) : ''
                        }
                    </ul>
                </Modal.Panel>
            </Modal>

            {/* <Modal open={isOpenFiles} onClose={closeModalFiles} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            Uploaded Files
                        </h3>
                        <div className="">
                            <IconButton onClick={closeModalFiles} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <ul className="p-4 modal_scroll">
                        {caseBrief && urls?.length > 0 ? urls.map((x, index) => {
                            return (
                                <li className='mb-2' key={index}>
                                    <a href={x.url} target='_blank' className='text-md visited:text-white font-medium text-white opacity-70 hover:text-white hover:opacity-100 focus:outline-none w-fit
                    flex items-center gap-2'># {x.name}</a>
                                </li>

                            )
                        }) : ''
                        }
                    </ul>
                </Modal.Panel>
            </Modal> */}
        </div>
    )
}

export default CaseDetail

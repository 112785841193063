import api from "../api"

const getLawyers = (reqBody) => {
    return api.get('user/lawyers', reqBody).catch((err) => err.response)
}
const getThreeLawyers = (reqBody) => {
    return api.post('vakeel/get-3-vakeel', reqBody).catch((err) => err.response)
}
const getAssignedLawyer = (user_id, caseId) => {
    return api.get(`user/assigned-lawyer?user_id=${user_id}&caseId=${caseId}`).catch((err) => err.response)
}

export { getLawyers, getAssignedLawyer, getThreeLawyers }
import React, { useEffect, useState, useRef } from 'react';
import { IconButton, Textarea } from '@heathmont/moon-core-tw';
import { ControlsChevronRight } from '@heathmont/moon-icons-tw';
import { io } from 'socket.io-client';
import { baseURL, baseURLImg } from '../../config';
import { formateDateShort, getFormattedTime } from '../../utils/date-formatter';
import { getAllMessages } from '../../services/comment-service';
import userIcon from '../../images/usericon_s.svg'

const Comments = (props) => {
  const [user, setUser] = useState(null);
  const room = props.room;
  const receiverId = props.receiverId;
  const case_detail_id = props.case_detail_id
  let userImg = localStorage.getItem('userProfileImg');
  if(userImg == 'null') userImg = null
  const profileImg = localStorage.getItem('profileImg');
  // const obj = {sendee}
  // const [room, setRoom] = useState('vakeelUser');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const socketRef = useRef();
  const loggedInUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  const senderId = loggedInUserInfo.user_id;

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    setUser(userInfo.full_name ? userInfo.full_name : "Anonymous User");
  }, []);

  useEffect(() => {
    // socketRef.current = io('http://122.168.125.73:8058');
    socketRef.current = io(baseURL, { path: "/socket" });
    console.log("=??????????????????",socketRef.current)

    const handleConnect = () => {
      socketRef.current.emit('login', user);
      socketRef.current.emit('join-room', room);
      //   alert('connected');
      setSocketConnected(true);
    };

    socketRef.current.on('connect_error', function (error) {
      console.log(error);
    });

    socketRef.current.on('connect', handleConnect);

    socketRef.current.on('login-success', (loggedInUser) => {
      setUser(loggedInUser);
    });

    socketRef.current.on('receive-message', (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    return () => {
      socketRef.current.off('connect', handleConnect);
      //socketRef.current.disconnect();
    };
  }, [user, room]);
  const handleSendMessage = () => {
    if (socketConnected) {
      socketRef.current.emit('send-message', { room, message, receiverId, senderId  ,case_detail_id});
      setMessage('');
    } else {
      alert('Socket is not connected yet. Please wait.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const getMessages = async (room_id) => {
    const response = await getAllMessages(room_id , case_detail_id);
    if (response.status === 200) {
      const data = []
      response.data.forEach((x) => {
        const obj = {
          sentAt: x.sent_at,
          senderId: x.sender_id,
          receiverId: x.receiver_id,
          user: x.full_name ? x.full_name : "Anonymous User" ,
          message: x.message_text,
          image: x.image_url
        }
        data.push(obj)
      })
      setMessages(data);

    }
  }

  useEffect(() => {
    getMessages(room)
  }, [room])


  return (
    <div className={`w-full py-6 px-6 max-md:py-4 max-md:px-4 bg-popo rounded-lg flex flex-col justify-between max-w-[450px] ${props.is_confirm ? '' : 'd-none'}`}>
      <div className={props.scroll}>
        <div className='flex items-center gap-3 max-md:gap-3'>
          <div>
            <img className='min-w-10 max-w-10 min-h-10 max-h-10 border rounded-full max-md:min-w-10 max-md:max-w-10 max-md:min-h-10 max-md:max-h-10 object-cover'
              src={userImg || profileImg ? `${baseURLImg}/${userImg || profileImg}` : userIcon} alt='#'></img>
          </div>
          <div className='flex flex-col gap-0'>
            <h4 className='mb-0 lg:text-[20px] md:text-[20px] sm:text-[18px] text-[18px] font-semibold text-white'>
              {loggedInUserInfo?.full_name}</h4>
          </div>
        </div>
        <div className='my-4 border-b pb-4'>
          {/* <p className='w-full mb-0 text-[16px] font-normal text-white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
        </div>

        {messages.map((msg, index) => (
          <div key={index}>
            <div className='flex items-center gap-3 max-md:gap-3'>
              <div>
                <img className='min-w-10 max-w-10 min-h-10 max-h-10 border rounded-full max-md:min-w-10 max-md:max-w-10 max-md:min-h-10 max-md:max-h-10 object-cover'
                  src={msg?.image ? `${baseURLImg}/${msg?.image}` : userIcon} alt='#'></img>
              </div>
              <div className='flex flex-col gap-0'>
                <h4 className='mb-0 lg:text-[20px] md:text-[20px] sm:text-[18px] text-[18px] font-semibold text-white'>{msg.senderId === loggedInUserInfo?.user_id ? 'You' : msg.user}</h4>
                {/* <h4 className='mb-0 lg:text-[20px] md:text-[20px] sm:text-[18px] text-[18px] font-semibold text-white'>{msg.user}</h4> */}
                <p className='mb-0 text-[14px] font-normal text-white'>{formateDateShort(msg.sentAt)} {getFormattedTime(msg.sentAt)}</p>
                {/* <p className='mb-0 text-[14px] font-normal text-white'>Dec 10 ‘24 | 7:29 pm </p> */}
              </div>
            </div>
            <div className='my-4 border-b pb-4'>
              <p className='w-full mb-0 text-[16px] font-normal text-white'>{msg.message}</p>
            </div>
          </div>
        ))}

      </div>
      <div className='relative'>
        <form className='chat_send_btn'>
          {/* <h6>{socketId}</h6> */}
          {/* <input placeholder='enter room' value={room} onChange={(e) => setRoom(e.target.value)} /> */}
          <Textarea className="pr-10 rounded-md text-white" size="md" placeholder='Write a comment' value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={handleKeyPress} />
          <IconButton disabled={!message} className='absolute top-[20px] right-[10px] rounded-full rounded_icon bg-chichi hover:bg-chichi' icon={<ControlsChevronRight className="" onClick={handleSendMessage} />} />
        </form>
      </div>
    </div>
  );
};

export default Comments;

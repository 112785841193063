import React, { useEffect } from 'react'
// import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import vaadin_img from '../images/vaadin_office.svg'
import mdi_img from '../images/mdi_form.svg'
import octicon_img from '../images/octicon_law.svg'
import { ArrowsRight, ControlsChevronLeftSmall, ControlsChevronRightSmall, ControlsPlus, GenericLightningBolt } from '@heathmont/moon-icons-tw'
import { Accordion, Button, Carousel, Dropdown, MenuItem } from '@heathmont/moon-core-tw'
import footer_logo from '../images/footer.svg'
import Navbar from '../components/common/Navbar'
// import Navbar from '../components/user/Navbar'
import stepball from '../images/stepbox_img.svg'
import step_thumb from '../images/step_thumb.svg'

import shoffr from '../images/shoffr.svg'
import insurfin from '../images/insurfin.svg'
import himalaya from '../images/himalaya.svg'
import titan from '../images/titan.svg'
import fb from '../images/fb.svg'
import insta from '../images/insta.svg'
import linkdn from '../images/linkdln.svg'
import twitter from '../images/twitter.svg'
import vakeel_img from '../images/vakeel_img.png'
import bus_incp from '../images/business_incorp.svg'
import criminal from '../images/criminal.svg'
import legal_img from '../images/legal_form.svg'
import from_stmp from '../images/form_stmp.svg'



const About = () => {
    const navigate = useNavigate()
    const userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {}
    useEffect(() => {
        if (Object.keys(userInfo).length > 0) {
            if (userInfo.role === 'user') {
                navigate('/user')
            } else if (userInfo.role === 'vakeel') {
                navigate('/profile')
            }
        }
    }, [])

    return (
        <div className='relative'>

        <div className='landing_header absolute top-0 w-full z-5'>
           <div className='px-5 py-5 max-md:px-3 max=md:py-3 mx-auto max-md:bg-black'>
               <div className='flex items-center gap-20 w-full max-sm:justify-between max-sm:gap-5'>
                   <div>
                       <img className='header_logo max-w-[150px] min-w-[150px] h-auto max-h-auto min-h-auto object-contain' src={footer_logo} alt='#'></img>
                   </div>
                   <div className='flex justify-between items-center w-full header_nav'>
                       <div className='flex gap-5 items-center max-sm:hidden'>
                           <Link className='text-md font-normal text-goten ease-in-out duration-500 hover:text-yellow-400' to="/">Home</Link>
                           <Link className='text-md font-normal text-goten ease-in-out duration-500 hover:text-yellow-400 active' to="/about">About us</Link>
                       </div>
                       <div className='flex gap-5'>
                       <Dropdown className='header_drop'>
                            <Dropdown.Trigger>
                                <div className='flex items-center gap-3'>
                                    <Button className='py-5 px-10 border bg-transparent hover:border' >Login</Button>
                                
                                </div>

                            </Dropdown.Trigger>
                            <Dropdown.Options className='bg-popo border-0'>

                                <Dropdown.Option>
                                    <MenuItem onClick={() => {
                                        navigate('/user/login')
                                    }}>
                                        <MenuItem.Title className='text-white'>Login as a user</MenuItem.Title>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/vakeel/login')
                                    }}>
                                        <MenuItem.Title className='text-white'>Login as a lawyer</MenuItem.Title>
                                    </MenuItem>
                                </Dropdown.Option>
                            </Dropdown.Options>
                        </Dropdown>

                        {/* <Dropdown className='header_drop_responsive'>
                            <Dropdown.Trigger>
                                <div className='flex items-center gap-3'>
                                    <Button className='py-5 px-10 border bg-transparent hover:border' >Login</Button>
                                
                                </div>

                            </Dropdown.Trigger>
                            <Dropdown.Options className='bg-popo border-0'>

                                <Dropdown.Option>
                                    <MenuItem onClick={() => {
                                        navigate('/user/login')
                                    }}>
                                        <MenuItem.Title className='text-white'>Login as a user</MenuItem.Title>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/vakeel/login')
                                    }}>
                                        <MenuItem.Title className='text-white'>Login as a lawyer</MenuItem.Title>
                                    </MenuItem>
                                </Dropdown.Option>
                            </Dropdown.Options>
                        </Dropdown> */}

                       </div>
                   </div>
               </div>
           </div>
        </div>

        <div className='about_hero'>
            <div className='container mx-auto relative px-8'>
                <div className='bg-popo p-8 max-md:py-4 about_heading'>
                    <h5 className='text-[22px] max-md:text-[18px] text-white'><span className='text-[22px] max-md:text-[18px] text-white font-semibold'>Privacy Policy</span></h5>
                </div>
            </div>
        </div>

        <div className='py-20 max-md:py-10 about_page px-5'>
            <div className='container mx-auto'>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>If you do not agree to the terms of our Privacy Policy, we kindly request you to not use this site or associated mobile app.</strong></p>
                    <p className='text-white text-md'>The privacy policy of the Company is being issued in compliance with the Information Technology Act and Applicable Rules. Vakeel4you.com  takes your privacy seriously. We have made our policy as clear as possible so that you can rest assured that you are in good hands. If you have comments, concerns, or any questions about this Privacy Policy, please contact us at tech@vakeel.group</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>1. Introduction to Privacy Practices For Vakeel4you.com </strong></p>

                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Vakil Group. ("Vakeel4you.com ," "we," or "us") is committed to respecting the privacy rights of all users of Vakeel4you.com  services provided via Vakeel4you.com  (collectively, "site") and to protecting your personal information. This policy describes how information about you may be used, how it may be disclosed, and how you may access this information.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>This page will serve as a policy of our privacy practices and a summary of your privacy rights.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We created this Site Privacy Policy ("Privacy Policy") to give you confidence as you visit and use the Site, and to demonstrate our commitment to fair information practices and the protection of privacy.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>This Privacy Policy is only applicable to the Site, and not to any other websites that you may be able to access from the Site or any website of Vakeel4you.com 's partners, each of which may have data collection, storage, and use practices and policies that differ materially from this Privacy Policy. Your use of the Site is governed by this Privacy Policy and the Terms of Use. If you are registering an account or using the Site on behalf of an individual or entity other than yourself, you represent that you are authorized by such individual or entity to accept this Privacy Policy on such individual's or entity's behalf.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Be advised, in case your question or consultation contains matters of high-security risk or there is a high privacy concern (like matters of the Constitution, matters related to the judiciary, matters related to police or law enforcement) we suggest you use alternate services.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>It is advisable to include only relevant de-identifiable Personal Information in case history and files uploaded by you.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>If you do not agree to the terms of our Privacy Policy we kindly request you to not use this site.</p>
                </div>

                
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>2. Information that may be collected</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>To use Vakeel4you.com , you are asked to enter an e-mail address and password, which we refer to as your Vakeel4you.com  ID. This process will allow you to manage your profile and/or account and ask specific queries.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We automatically track and collect IP addresses, domain servers, types of web browsers, computers, and domain servers used to access the site, referring sources to the site and your interaction with the site and the browser, (Collectively "Traffic data").</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We also collect the information you provide voluntarily in free-form text boxes on the Site and through responses to surveys, questionnaires, and the like.</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>3. Cookies</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Cookies are text information files that your web browser places on your computer when you visit a website. Cookies assist in providing non-personal information from you as an online visitor. It can be used in the customization of your preferences when visiting our website.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>In addition, our business partners may use cookies to provide us with non-personally identifiable data and information regarding the use of our Site.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Most browsers accept cookies automatically but can be configured not to accept them or to indicate when a cookie is being sent. If you choose to have your browser refuse cookies, it is possible that some areas of our sites will not function properly when you view them.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We do not link the information stored in these cookies directly to any of the Personal Information you submit while on the Site, however.
                    We also automatically collect your Internet Protocol (IP) address (which allows us to understand how you use our site and enable us to make changes to our site to improve your user experience); information regarding your computer and your connection to our site (such as your browser type, operating system, and platform); and your user history to, through, and away from our site (such as session information, page loading times, page errors and broken links, the length of your visits to specific pages within our site, and other information related to your experience on our site).</p>
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>4. Web Beacons</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>In limited circumstances, we also may use Web Beacons to collect anonymous, non-personal information about your use of our Site and the sites of selected sponsors and advertisers, and your use of emails, special promotions, or newsletters we send to you. "Web Beacons" are tiny graphic image files embedded in a web page or email that provide a presence on the web page or email and send back to its home server information from the user's browser. The information collected by web beacons allows us to statistically monitor how many people are using the Site and selected sponsors' and advertisers' sites, or opening our emails, and for what purposes.</p>
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>5. Website Analytics</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We may use third-party website analytics services in connection with the Site. These website analytics services do not collect Personal Information that you do not voluntarily enter into the Site. These services do not track your browsing habits across websites, which do not use their services. We are using the information collected from these services to find usability problems to make the Site easier to use.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>The recordings will never identify you or your account.</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>6. Storage of Information</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We store all Traffic Data and review postings indefinitely, even after "deletion," and may archive such information elsewhere. We store all Personal Information until you request that we modify or delete it, in which case we may still end up retaining some of that information, as it may be impossible to remove your account without some residual information being retained by us.
Vakeel4you.com  has no obligation to delete any data following the termination of your account or the account of any professional to whom your Case History Form was submitted through the Site. Registered users who wish to close their account should write in at tech@vakeel.com</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>7. Children</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Our Website is not directed at children, and we do not seek to collect any information from children including those under the age of 18. If we learn that we have received any information from an individual under the age of 18, we will use that information only to respond directly to that child (or a parent or legal guardian) to inform him or her that he or she cannot use the Services and subsequently we will delete that information from our servers.</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>8. How information about you may be used by Vakeel4you.com</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Personal information like your case history (optional) is collected when you start a new consultation request. These are saved in your account for future reference and future consultation requests.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>These details in conjunction with your questions are shared with Consulting Lawyers to help them answer your questions to the best of their knowledge.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Once the consultation is complete, we may share your questions (without any personal information) and respective answers by Lawyers on site, to enable site visitors to learn from your case experiences.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We may also use your de-identified Personal Information to run (or authorize third parties to run) statistical research on individual or aggregate trends. Such research would only use your Personal Information in an anonymous manner that cannot be tied directly back to you.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Primarily your email will be used to:</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Provide you with the services offered by the Site.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Troubleshoot problems, Provide you with system or administrative messages, and as otherwise described to you at the time of collection.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Deliver information to you that is relevant to your interests, such as notifications and reminders related to consultation.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Recommend certain resources, and inform you about our product and services.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Customize and improve your experience.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Detect and protect us against error, fraud, and other criminal activity.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Enforce our Terms of Use.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- Enforce our Terms of Use.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>However, in other instances, non-personal information collected may be used for statistical analysis, improvement of services, and customization of web design, content layout, and services.</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>9. Sharing of information</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- To provide the service offered through this Web site, we will forward your informer agreeing to the Lawyers identified when you complete our form and request a consultation for them to contact you, evaluate your needs, and determine whether to represent you. When you submit your request, you consent to the release of your information to those Lawyers. As part of this process, the Lawyer is to keep your information confidential and agree not to share it with any third party without your express permission.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- We will disclose your information to proper Government authorities when we have a good-faith belief that such action is necessary to comply with applicable laws, valid legal process, a court order, a current judicial proceeding, or to protect our rights or property.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- To customize your advertising interactions, we may share Personal Information with advertisers and other third parties only on an aggregate (i.e., non-personally identifiable) basis.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- We may share your de-identified Personal Information with third parties to enable them to run statistical research on individual or aggregate trends.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>- We may transfer information about you to another company in connection with a merger, sale, or acquisition by or of Vakeel4you.com . In this event, we will use reasonable efforts to notify you before information about you is transferred and becomes subject to a different privacy policy.</p></p>
                    
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>10. User Choice and controlling Your Personal Information</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>As a registered user of the Site, you can modify/delete the Personal Information you have included in your profile. However, it may be impossible to remove your information without some residual information being retained by Vakeel4you.com .</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Please note Vakeel4you.com  has no obligation to delete any data or information following the termination of your account. We would try our best to remove/mask any unintentional disclosure of personal information by you during consultations but are not obligated to do so.</p></p>
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>11. Security</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>You choose to make your data available to consulting Lawyers. We follow generally accepted industry standards to protect Personal Information. For example, we use multiple layers of firewall security and different degree of encryption for your sensitive personal data.</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Our employees and consulting Lawyers may share case information for payment and operational purposes, only to provide efficient Professional services.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Your data may in some situations be seen by third parties, such as Lawyers or operations and maintenance contractors repairing and maintaining our technical systems. The respective contracts require businesses to protect the information we share with them. Vakeel4you.com  will use the information received to provide our services in the best interest of the company.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We will share your case information when required to comply with court orders or another legal process. We may use and share your case information to protect the rights, property, or safety of Vakeel4you.com  or another party, or to respond to claims that any posting or other content violates the rights of third parties.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Vakeel4you.com  website may contain links to other sites. Please observe that we are not responsible for the privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of these websites.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>We might send notifications, reminders, and health information to the email ids provided by you. Vakeel4you.com  will not be responsible for the information loss or privacy loss due to the incorrect email id provided by you.</p></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><p className='text-white mb-[20px] max-md:mb-[15px] text-md'>In the unlikely event that your Personal Information has been disclosed in a manner not by this Privacy Policy, we will use reasonable efforts to notify you of the nature and extent of the disclosure (to the extent we know that information) as soon as reasonably possible and as permitted by law.</p></p>
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>12. Lawyers</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Lawyers and their employees should be particularly aware of their obligations to client/user confidentiality, both in communicating with Vakeel4you.com  and in consulting users via the site. Vakeel4you.com  does not have, and will not accept, any obligations of confidentiality concerning any communications other than those expressly stated in this Privacy Policy and Vakeel4you.com 's Terms of Use.</p>
                    
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>13. Opt-Out Instructions</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>You may opt-out by changing the settings on your profile page. This opt-out does not apply to communications we have with you to complete your order or provide the service you requested. Understand, however, that, by electing this option, you may limit the functionality of this Web site and the amount of information and services we can provide to you. You may revoke your opt-out option at any time by contacting us using the information provided above.</p>
                    
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>14. Updates and Changes to this Privacy Policy</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>Protecting your privacy online is an evolving area, and Vakeel4you.com  is constantly evolving to meet these demands. We, therefore, reserve the right to modify this Privacy Policy at any time. We will post any Privacy Policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).</p>
                    
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>15. Contact</strong></p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'>If you have comments, concerns, or any questions about this Privacy Policy, please contact us at tech@vakeel.com</p>
                    <p className='text-white mb-[20px] max-md:mb-[15px] text-md'><strong>If you do not agree to the terms of our Privacy Policy we kindly request you to not use this site.</strong></p>
                    
                </div>
            </div>
        </div>


       <div className='footer bg-popo py-16 max-md:py-5'>
           <div className='container mx-auto px-5 max-md:px-3'>
               <div className="flex gap-64 max-lg:gap-32 w-full pb-12 max-md:flex-col max-md:gap-10 max-md:pb-6">
                   <img className='max-w-[150px] min-w-[150px] h-auto max-h-auto min-h-auto object-contain max-md:max-w-[100px] max-md:min-w-[100px]' src={footer_logo} alt='#'></img>
                   <div className='flex justify-between w-full max-w-[800px] footer_links'>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>Company</h4>
                           <ul className='flex flex-col gap-4'>
                               <li><Link to='/about' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>About us</Link></li>
                               <li><Link to='/privacy-policy' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Privacy policy</Link></li>
                               <li><Link to='/term-of-uses' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Terms of use</Link></li>
                               <li><Link to='/contact-us' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Contact us </Link></li>
                           </ul>
                       </div>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>For lawyers</h4>
                           <ul className='flex flex-col gap-4'>
                               <li><Link to='/signup' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Register as a lawyers</Link></li>
                               <li><Link to='/disclaimer' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Disclaimer</Link></li>
                           </ul>
                       </div>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>Social links</h4>
                           <ul className='flex gap-5'>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={twitter} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={fb} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={insta} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={linkdn} alt=''></img></Link></li>
                           </ul>
                       </div>
                   </div>
               </div>
               <p className='text-goten opacity-75 text-sm pt-10 max-md:pt-6 mb-0 border-t'>© Copyright 2024 Vakeel Group. All rights reserved.</p>
           </div>
       </div>

        </div>
    )
}

export default About;

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, IconButton, NativeSelect } from '@heathmont/moon-core-tw';
// import Navbar from '../../components/vakeel/Navbar';
import { dateFormatter, formatDate } from '../../utils/date-formatter';
import { capitalizeFirstLetters } from '../../utils/common-helper';
import { ControlsEye, GenericEdit } from '@heathmont/moon-icons-tw';
import { allCases } from '../../services/user/case-service';
import Navbar from '../../components/user/Navbar';

const AllCase = () => {
    const navigate = useNavigate()
    const [cases, setCases] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const getAllCases = async () => {
        const response = await allCases(userInfo?.user_id);
        if (response.status === 200) {
            setCases(response.data);
            console.log('user-cases', response.data)
        }
    };

    useEffect(() => {
        getAllCases();
    }, []);

    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-20'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='w-full py-7 px-7 bg-white mx-auto rounded-lg max-md:py-5 max-md:px-5'>
                        {/* <div className='flex items-center justify-between border-b pb-5 max-md:pb-3'>
                            <h2 className='text-[24px] max-md:text-[20px] font-bold text-gray-900 whitespace-nowrap'>All Cases</h2>
                            <NativeSelect className='flex min-w-fit max-w-[120px]'>
                                <option value="All">All</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </NativeSelect>
                        </div> */}
                        <div className="p-4 border-t-2 border-beerus flex justify-end">
                            <Button onClick={()=> {
                                navigate('/user/register-case')
                            }} size='lg' type='submit'>Add a case</Button>
                        </div>
                        <div className='w-full mx-auto mt-2'>
                            <div className="align-middle inline-block w-full overflow-x-auto">
                                <table className="min-w-full">
                                    <thead>
                                        <tr className="leading-4 tracking-wider whitespace-nowrap">
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > S.No. </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > Date </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > Customer Name </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > Case Name </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2' > Case Type </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2 min-w-[80px] max-w-[80px]' ></Link>
                                            </th>
                                            {/* <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2 min-w-[80px] max-w-[80px]' ></Link>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {cases.length === 0 ? (
                                            <tr>
                                                <td colSpan="6" className="text-gray-600 text-center py-4">No data available</td>
                                            </tr>
                                        ) : (
                                            cases.map((value, index) => (
                                                <tr key={index}>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{index + 1}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{formatDate(value?.incident_date)}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{capitalizeFirstLetters(value?.first_name)}{" "}{capitalizeFirstLetters(value?.last_name)}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{value?.case_name}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap">
                                                        <span className='font-medium'>{value?.incident_type}</span>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap">
                                                        <IconButton onClick={() => {
                                                            console.log('value.appointment_id', value.appointment_id)
                                                            localStorage.setItem('currentCaseId', value?.case_detail_id)
                                                            navigate(`/user/cases-details/${value?.case_detail_id}`)
                                                            // if (value.appointment_id !== '' && value.appointment_id != null) {
                                                            // } else {
                                                            //     navigate(`/user/view-case/${value?.case_detail_id}`)
                                                            // }
                                                            // navigate(`/user/view-case/${value?.case_detail_id}`)

                                                        }} icon={<ControlsEye />} title='View case details'/>
                                                    </td>
                                                    {/* <td className="px-5 py-3 whitespace-no-wrap">
                                                        <IconButton icon={<GenericEdit />} />
                                                    </td> */}
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllCase

import * as Yup from 'yup';
const emailRegExp = /^[^<>()[\]\\,;:%#^\s@"$&!]+@(?:(?:\d{1,3}\.){3}\d{1,3}|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/;
// const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
export default Yup.object().shape({
    email: Yup.string().required('Email is required.').matches(emailRegExp, 'Email must be a valid email.'),
    full_name: Yup.string().required('Name is required.'),
    // lastName: Yup.string().required('Last Name is required.'),
    mobile_number: Yup.string().matches(/^[6-9]\d{9}$/, 'Invalid mobile number.').required('Mobile number is required.'),
    sanadNumber: Yup.string().required('Sanad number is required.'),
    aadharNumber: Yup.string().required('Aadhar number is required.').min(12, 'Aadhar number should 12 digits only.').max(12, 'Aadhar number should 12 digits only.'),
    areaOfExpertise: Yup.string().required('Area of expertise is required.'),
    experience: Yup.string().required('Experience is required.'),
    briefIntro: Yup.string().required('Briefe intro is required.'),
});

import React, { useState, useEffect } from 'react';
import { v4 as uuid } from "uuid";
import './App.css';
import axios from 'axios';
import TextBox from './component/textbox';
import MessageBox from './component/messagebox';
import ndjsonStream from 'can-ndjson-stream';
import {
    API_TIMEOUT,
    VOICE_STYLE,
    VOICE_TYPE,
    SUPPORTED_LANGUAGE_LIST,
    USE_STREAMING,
    USE_AUDIO
} from './config';
import {
    checkString,
    getTokenOrRefresh,
    languageDetector,
    languageTranslator
} from './utils';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk');


const ChatBot = ({ setDisplayResponse }) => {
    // State declarations to manage various aspects of the chat interface
    const [messages, setMessages] = useState([]);
    const [id] = useState(uuid().toString());  // Generating a unique ID for the chat session
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);  // Flag to indicate ongoing requests
    const [responseReceived, setResponseReceived] = useState(false);  // Flag to indicate if response has been received
    const [language] = useState('en-IN');  // Setting default language
    const [languageList, setLanguageList] = useState(SUPPORTED_LANGUAGE_LIST);

    // State variables for managing mic use, speech synthesis, and API source cancellation
    const [overlapAudioUseFlag, setOverlapAudioUseFlag] = useState(true);
    const [speechStrings, setSpeechStrings] = useState([]);
    // const [source] = useState(axios.CancelToken.source());
    const [audioFlag, setAudioFlag] = useState(USE_AUDIO);
    const [streaming, setStreaming] = useState(USE_STREAMING);
    const [player, updatePlayer] = useState({ p: undefined, muted: false });
    // console.log(source.token, "source")
    useEffect(() => {
        const source = axios.CancelToken.source(); // Function to initialize the chat session when the component mounts
        initializeChatSession();
        return () => {
            source.cancel('Component unmounted'); // Cancel ongoing API requests when unmounting the component
        };
    }, []); // Empty dependency array to run once on mount

    const initializeChatSession = async () => {
        const source = axios.CancelToken.source();
        try {
            // API call to initialize the chat session
            // Handling the response and potential errors
            const resp = await axios.post('https://vakeel4you.com/vakeel-backend/init_chat/', {
                chat_id: id,
                question: "init chat",
                language: language,
            }, {
                cancelToken: source.token,
            });

            console.log('Initialization response:', resp.data);
            const { response, status } = resp.data;

            const welcomeMessage = response || 'Welcome to Vakil Group! How may I help you today?';

            // Update the UI with the welcome message
            setMessages([{
                type: 1,
                text: welcomeMessage,
            }]);

        } catch (error) {
            console.error('Error initializing chat session:', error);
            let errorMessage = 'An error occurred while initializing the chat session. Please try again later.';

            if (axios.isCancel(error)) {
                console.log('Initialization request canceled:', error.message);
            } else if (error.message.includes('Network Error')) {
                errorMessage = 'Unable to connect to the server. Please check your network connection.';
            }

            // Update the UI with the error message
            setMessages([{
                text: errorMessage,
                type: 1,
            }]);
        }
    };

    // Function to synthesize speech from text using Microsoft Cognitive Services Speech SDK
    async function speechSyn(textToSpeak, textLanguage) {
        // Input: textToSpeak (String), textLanguage (String)
        // Output: None
        // Functionality: Converts text to speech based on the provided language.
        try {
            var newPlayer = new speechsdk.SpeakerAudioDestination();
            updatePlayer(p => { p.p = newPlayer; return p; });

            player.p.onAudioEnd = function (_) {
                setOverlapAudioUseFlag(true);
            };
            const tokenObj = await getTokenOrRefresh();
            var audioConfig = speechsdk.AudioConfig.fromSpeakerOutput(player.p);
            const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
            const voiceType = VOICE_TYPE;

            speechConfig.speechSynthesisVoiceName = VOICE_STYLE[textLanguage][voiceType];

            let synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);

            synthesizer.speakTextAsync(
                textToSpeak,
                (result) => {
                    let text;
                    if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
                        text = ` "${textToSpeak}".\n`;
                        console.log(text);
                    } else if (result.reason === speechsdk.ResultReason.Canceled) {
                        text = `Synthesis failed. Error detail: ${result.errorDetails}.\n`;
                        console.log(text);
                    }
                    synthesizer.close();
                    synthesizer = undefined;
                },
                function (err) {
                    setDisplayResponse(`Error: ${err}.\n`);
                    synthesizer.close();
                    synthesizer = undefined;
                }
            );
        } catch (error) {
            console.log('Error in text to speech: ' + error.message);
        }
    }

    // Function to handle new messages from the user
    // Handling user input, initiating requests, timeouts, and response handling
    const handleNewUserMessage = async (text, micFlag) => {
        const source = axios.CancelToken.source();
        if (!text[0] || !text[0].text.trim()) {
            console.log('No input text found.');
            return;
        }

        if (isRequestInProgress) {
            console.log('A request is already in progress. Please wait for the response.');
            return;
        }

        setIsRequestInProgress(true);
        setResponseReceived(false);

        setMessages((prevMessages) => [...prevMessages, { text: text[0].text, type: 0 }]);

        const sendingMessage = {
            text: 'Your query is being processed.........',
            type: 1,
        };

        setMessages((prevMessages) => [...prevMessages, sendingMessage]);

        let timeout;
        let hasValidResponse = false;
        let input;

        try {
            const currentLanguage = await languageDetector(text[0].text);
            var textToSend;
            if (currentLanguage !== 'en') {
                textToSend = await languageTranslator(text[0].text, currentLanguage, 'en');
            } else {
                textToSend = text[0].text;
            }

            input = {
                question: textToSend,
                chat_id: id,
                language: language,
            };
            console.log('Query Request: ', input);

            const timeoutPromise = new Promise((_, reject) => {
                timeout = setTimeout(() => {
                    reject(new Error('Request timed out. Please try again later.'));
                }, API_TIMEOUT);
            });

            const resp = await Promise.race([
                axios.post('https://vakeel4you.com/vakeel-backend/chat/', {
                    question: input.question,
                    chat_id: input.chat_id,
                    language: input.language,
                }, {
                    cancelToken: source.token,
                }),
                timeoutPromise,
            ]);

            if (!resp || !resp.data) {
                console.log('Invalid response received.');
                return;
            }

            const { response, status } = resp.data;

            var displayText;
            if (currentLanguage != 'en') {
                displayText = await languageTranslator(response, 'en', currentLanguage);
            }
            else {
                displayText = response;
            }
            if (micFlag) {
                speechStrings.push([displayText, currentLanguage]);
            }
            setMessages((prevMessages) => [
                ...prevMessages.filter((message) => message !== sendingMessage),
                {
                    text: displayText,
                    type: 1,
                }
            ]);

            hasValidResponse = true;
        } catch (error) {
            console.error('Error:', error);

            if (micFlag) {
                speechStrings.push(['An error occurred. Please try again later.', 'en']);
            }
            const errorMessage = {
                text: 'An error occurred. Please try again later.',
                type: 1,
            };

            setMessages((prevMessages) => [
                ...prevMessages.filter((message) => message !== sendingMessage),
                errorMessage,
            ]);
        } finally {
            clearTimeout(timeout);
            setIsRequestInProgress(false);

            if (!responseReceived && hasValidResponse) {
                setResponseReceived(true);
            }
        }
    };

    // Function to handle continuous message streaming
    // Initiating requests, processing the received stream, language detection, translation, and message updates
    const handleUserMessageStream = async (text, micFlag) => {
        if (!text[0] || !text[0].text.trim()) {
            console.log('No input text found.');
            return;
        }
        if (isRequestInProgress) {
            console.log('A request is already in progress. Please wait for the response.');
            return;
        }

        setIsRequestInProgress(true);
        setResponseReceived(false);

        setMessages((prevMessages) => [...prevMessages, { text: text[0].text, type: 0 }]);

        const sendingMessage = {
            text: 'Your query is being processed.........',
            type: 1,
        };
        setMessages((prevMessages) => [...prevMessages, sendingMessage]);

        let timeoutId;
        let hasValidResponse = false;
        let isTimeout = false;
        let input;

        try {
            var currentLanguage = await languageDetector(text[0].text);
            var textToSend;
            if (currentLanguage !== 'en') {
                textToSend = await languageTranslator(text[0].text, currentLanguage, 'en');
            } else {
                textToSend = text[0].text;
            }

            input = {
                question: textToSend,
                chat_id: id,
                language: language,
            };
            console.log('Request initiated:', input);

            const response = await fetch('https://vakeel4you.com/vakeel-backend/chat/stream/', {
                method: 'POST',
                headers: {
                    Accept: "text/event-stream",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    question: input.question,
                    chat_id: input.chat_id,
                    language: input.language,
                }),
            });
            console.log(response, "response")
            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            let streamVal = "";
            let origText = "";
            const reader = ndjsonStream(response?.body).getReader();
            console.log(response?.body, "response?.body")
            console.log(reader, "reader")

            timeoutId = setTimeout(() => {
                isTimeout = true; // Set the timeout flag
            }, API_TIMEOUT);

            while (!isTimeout) {
                console.log(" in while loop");
                try {
                    console.log(" try in while loop");
                    console.log(reader, " reader in while loop");
                    const { value, done } = await reader.read();
                    console.log(done, 'done', isTimeout)
                    if (done || isTimeout) break;

                    const json_value = JSON.parse(value);
                    origText += json_value['response'];

                    if (checkString(origText)) {
                        let displayText = currentLanguage !== 'en' ?
                            await languageTranslator(origText, 'en', currentLanguage) : origText;

                        if (micFlag) speechStrings.push([displayText, currentLanguage]);

                        streamVal += displayText;
                        setMessages((prevMessages) => [
                            ...prevMessages.slice(0, -1),
                            { text: streamVal, type: 1 }
                        ]);
                        origText = "";
                    }
                } catch (streamError) {
                    // Handle errors during stream reading
                    console.error('Stream reading error:', streamError);
                    throw new Error('An error occurred while processing the response.');
                }
            }

            if (isTimeout) {
                throw new Error('Request timed out. Please try again later.');
            }
            hasValidResponse = true;

        } catch (error) {
            console.error('Error:', error, 'Request:', input);

            const errorMessage = {
                text: 'An error occurred. Please try again later.',
                type: 1,
            };

            setMessages((prevMessages) => [
                ...prevMessages.filter((message) => message !== sendingMessage),
                errorMessage,
            ]);

            if (micFlag) speechStrings.push([errorMessage.text, 'en']);
        } finally {
            clearTimeout(timeoutId);
            setIsRequestInProgress(false);

            if (!responseReceived && hasValidResponse) {
                setResponseReceived(true);
            }
        }
    };

    async function handleMute() {
        updatePlayer(p => {
            if (!p.muted) {
                p.p.pause();
                return { p: p.p, muted: true };
            } else {
                p.p.resume();
                return { p: p.p, muted: false };
            }
        });
    }

    useEffect(() => {
        // useEffect for managing speech synthesis and mic usage flag
        const interval = setInterval(() => {
            if (overlapAudioUseFlag === true && speechStrings.length !== 0 && audioFlag === true) {
                setOverlapAudioUseFlag(false);
                const speechLanguage = speechStrings[0][1];
                const textToSpeech = speechStrings[0][0];
                speechSyn(textToSpeech, speechLanguage);
                speechStrings.shift();
            } else {
                return;
            }
        }, 10);

        return () => clearInterval(interval);
    }, [overlapAudioUseFlag, speechStrings]);

    return (
        <div className="chatbox">
            <div className='chatbox_header'>
                <img src={require('./img/chatbot.png')} width='40px' align='right' alt='' />
                <h3 style={{ whiteSpace: 'nowrap' }}><b>Vakil Bot</b></h3>
            </div>
            <div className="messageBox">
                <MessageBox messageContent={messages} />
            </div>
            <div className='textBox'>
                <TextBox handlerFromParent={streaming ? handleUserMessageStream : handleNewUserMessage} languages={languageList} audioFlag={audioFlag} micUseFlag={overlapAudioUseFlag} handleMute={handleMute} getTokenOrRefresh={getTokenOrRefresh} speechLength={speechStrings.length} setMessages={setMessages} />
            </div>
        </div>
    );
};

export default ChatBot;

import React, { useEffect, useState } from 'react'
import Navbar from '../../components/user/Navbar'
import { Button, Input, Label, NativeSelect, Textarea } from '@heathmont/moon-core-tw'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { casesDetails, submitCase } from '../../services/user/case-service'
import { toast } from 'react-toastify'

const ViewCase = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();
    const params = useParams();
    const [caseInfo, setCaseInfo] = useState({})

    const getCaseDetails = async () => {
        const response = await casesDetails(userInfo?.user_id, params.id);
        if(response.status === 200){
            setCaseInfo(response.data);
            console.log('caseDetails', response.data)
        }
    }

    useEffect(() => {
        getCaseDetails()
    }, [])

    const formik = useFormik({
        initialValues: {
            phone_number: userInfo.mobile,
            email: userInfo.email,
            case_status: caseInfo.case_status,
            consultation_type: caseInfo.consultation_type,
            incident_type: caseInfo.incident_type,
            incident_date: caseInfo.incident_date,
            incident_location: caseInfo.incident_location,
            suspect_details: caseInfo.suspect_details,
            witness_details: caseInfo.witness_details,
            damages_desc: caseInfo.damages_desc,
        },
        // validationSchema: loginSchema,
        onSubmit: async (values) => {
            console.log(values)
            const reqBody = { ...values, user_id: userInfo.user_id }
            const response = await submitCase(reqBody);
            if (response.status === 201) {
                toast.success('Your case has been registered successfully.')
                navigate('/user/lawyers')
            }

        },
    });
    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-22'>
                <div className='container mx-auto px-5 max-md:px-3'>

                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-white rounded-lg max-md:py-5 max-md:px-5 max-w-[900px] mx-auto'>
                        <div className='text-center mb-6 max-w-[700px] mx-auto'>
                            <h2 class="lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-3">View case details</h2>
                            {/* <p class="text-md text-center font-medium mb-0">Help us with some information to create a initial consultation breif.
                                this service is free and your information is kept completely confidential</p> */}
                        </div>
                        {/* <form onSubmit={formik.handleSubmit}> */}
                        <div className='grid grid-cols-2 gap-4 mb-5'>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">First name</Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Your name" name='first_name' value={userInfo?.first_name} readOnly/>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Last name</Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Your name" name='last_name' value={userInfo?.last_name} readOnly/>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Email id </Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Your email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} readOnly/>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Phone number </Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Your Phone number" name='phone_number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone_number} readOnly/>
                                </div>

                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Consultation type</Label>
                                    <NativeSelect size="lg" name='consultation_type' onChange={formik.handleChange} onBlur={formik.handleBlur} defaultValue={caseInfo?.consultation_type} readOnly>
                                        <option value={caseInfo?.consultation_type}>{caseInfo?.consultation_type}</option>
                                        <option value="Legal consultation">Legal consultation</option>
                                    </NativeSelect>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Type of incident</Label>
                                    <NativeSelect size="lg" name='incident_type' onChange={formik.handleChange} onBlur={formik.handleBlur} value={caseInfo?.incident_type} readOnly>
                                        <option value="">{caseInfo?.incident_type}</option>
                                        <option value="Accident">Accident</option>
                                        <option value="Dispute">Dispute</option>
                                    </NativeSelect>
                                </div>
                                <div className="max-sm:col-span-2">
                                <Label size="md" className='font-medium' for="">Case name </Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please enter case name" name='case_name' defaultValue={caseInfo?.case_name} readOnly/>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Incident Occurance date </Label>
                                    <div className='flex gap-4'>
                                        <Input size="lg" type="date" className="rounded-md min-w-[200px]" id="exampleFormControlInput1" placeholder="Select the approximate date and time of the incident" name='incident_date' onChange={formik.handleChange} onBlur={formik.handleBlur} value={'2024-01-15'} readOnly/>
                                        {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Set time" /> */}
                                    </div>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Incident location </Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please select the location where the incident is happened" name='incident_location' onChange={formik.handleChange} onBlur={formik.handleBlur} value={caseInfo.incident_location} readOnly/>
                                </div>
                                <div className="max-sm:col-span-2">
                                    <Label size="md" className='font-medium' for="">Evidence URL </Label>
                                    <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please provide proper evidence URL" name='incident_location' onChange={formik.handleChange} onBlur={formik.handleBlur} value={caseInfo.evidence_url} readOnly/>
                                </div>
                                <div className="col-span-2">
                                    <Label size="md" className='font-medium' for="">Suspect detail </Label>
                                    {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please enter name and detail of the suspect if any" name='suspect_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.suspect_details} /> */}
                                    <Textarea className="rounded-md" size="lg" name='damages_desc' onChange={formik.handleChange} onBlur={formik.handleBlur} value={caseInfo.suspect_details} readOnly/>
                                </div>
                                <div className="col-span-2">
                                    <Label size="md" className='font-medium' for="">Witness detail </Label>
                                    {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please enter name and detail of the witness if any" name='witness_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.witness_details} /> */}
                                    <Textarea className="rounded-md" size="lg" name='damages_desc' onChange={formik.handleChange} onBlur={formik.handleBlur} value={caseInfo.witness_details} readOnly/>
                                </div>
                                <div className="col-span-2">
                                    <Label size="md" className='font-medium' for="">Damages in the incident </Label>
                                    <Textarea className="rounded-md" size="lg" name='damages_desc' onChange={formik.handleChange} onBlur={formik.handleBlur} value={caseInfo.damages_desc} readOnly/>
                                </div>
                            </div>
                        {/* </form> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ViewCase

import React, { useEffect, useState } from 'react'
import { Input, Button } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import loginSchema from '../../validation-schemas/vakeel/login-schema';
import { checkUserExist, signin, signup, verifyOtp, sendLoginOtp } from '../../services/vakeel/auth-service';
import { getProfile } from '../../services/vakeel/profile-service';
import ButtonLoading from '../../components/loading/ButtonLoading';
import { useGoogleLogin } from '@react-oauth/google';
import { AuthCode } from '@heathmont/moon-core-tw';

import axios from 'axios';
import { ArrowsLeft } from '@heathmont/moon-icons-tw';
import { sendOTP, verifyOTP } from '../../services/user/auth-service';


const Login = () => {
    const navigate = useNavigate();
    const userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {}
    useEffect(() => {
        if (Object.keys(userInfo).length > 0) {
            if (userInfo.role === 'user') {
                navigate('/user')
            } else if (userInfo.role === 'vakeel') {
                navigate('/profile')
            }
        }
    }, [])
    const [loading, setLoading] = useState(false);
    const [loadingOtp, setLoadingOtp] = useState(false);


    const manageRedirect = (userProfile) => {
        if (Object.keys(userProfile.data).length > 0 && userProfile.data.is_profile_completed) {
            if (!userProfile.data.is_approved && !userProfile.data.is_rejected) {
                localStorage.setItem('profileApproved', 0);
                navigate('/profile-submitted');
            } else {
                navigate('/dashboard');
                localStorage.setItem('profileApproved', 1);
            }
        } else {
            navigate('/profile');
            localStorage.setItem('profileApproved', 0);
        }
    }

    const [isOtpSent, setIsOtpSent] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            phoneNumber: ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            const reqBody = {
                email: values.email,
                phoneNumber: values.phoneNumber,
                role_id : 2
            }
            setLoading(true)
            const response = await sendLoginOtp(reqBody);
            // const response = await signin(reqBody);
            if (response.status === 200) {
                toast.success("Otp send successfully.", { autoClose: 1000 });
                setLoading(false)
                setIsOtpSent(true)
                otpCountDown()
                // localStorage.setItem('userInfo', JSON.stringify(response.data));
                // const userProfile = await getProfile(response.data.user_id);
                // localStorage.setItem('profileImg', userProfile.data.profile_image_url);
                // setTimeout(() => {
                //     setLoading(false);
                //     toast.success("Login successfully.", { autoClose: 1000 });
                //     setTimeout(() => {
                //         manageRedirect(userProfile)
                //     }, 1000);
                // }, 2000)
            } else if (response.status === 401) {
                setTimeout(() => {
                    toast.error(response.data.message, { autoClose: 3000 });
                    setLoading(false)
                }, 2000);
            }
        },
    });
    const [otpValue, setOtpValue] = useState('');
    const handleOnChangeOtp = (res) => {
        setOtpValue(res);
    };
    const handleVerifyOtp = async () => {
        setLoading(true);
        const reqBody = {
            "email": formik.values.email,
            "phoneNumber": formik.values.phoneNumber,
            "otp": otpValue,
            "isSocialLogin": false,
            "role_name": "vakeel"
        }
        console.log(reqBody)
        const response = await verifyOtp(reqBody);
        // const response = await signin(reqBody);
        if (response.status === 200) {
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            const userProfile = await getProfile(response.data.user_id);
            localStorage.setItem('profileImg', userProfile.data.profile_image_url);
            setTimeout(() => {
                setLoading(false);
                toast.success("Login successfully.", { autoClose: 1000 });
                setTimeout(() => {
                    manageRedirect(userProfile)
                }, 1000);
            }, 2000)
        } else if (response.status === 401) {
            setTimeout(() => {
                toast.error(response.data.message, { autoClose: 3000 });
                setLoading(false)
            }, 2000);
        }
    }
    // useEffect(() => {
    //     return () => {
    //         formik.resetForm()
    //     }
    // }, [])

    // Google Login
    const [user, setUser] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user && user.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const data = res.data
                        // console.log('checkExistingUser', res.data);
                        const existingUser = await checkUserExist({ email: data.email });
                        if (existingUser.data === false) {
                            const reqBody = {
                                mobile: null,
                                full_name: data.name,
                                first_name: data.given_name,
                                last_name: data.family_name,
                                email: data.email,
                                password: '',
                                role_id: 2,
                                isSocialLogin: true
                            }
                            const signUpResult = await signup(reqBody);
                            if (signUpResult.status === 200) {
                                localStorage.setItem('userInfo', JSON.stringify(signUpResult.data));
                                const userProfile = await getProfile(signUpResult.data.user_id);
                                manageRedirect(userProfile)
                            }

                        } else {
                            const reqBody = {
                                email: data.email,
                                password: '',
                                isSocialLogin: true,
                                role_name: 'vakeel'
                            }
                            const response = await signin(reqBody);
                            if (response.status === 200) {
                                localStorage.setItem('userInfo', JSON.stringify(response.data));
                                const userProfile = await getProfile(response.data.user_id);
                                manageRedirect(userProfile)
                            }
                        }
                    })
                    .catch((err) => console.log(err));
            }
        },
        [user]
    );
    const handleFacebookLogin = async (res) => {
        const data = res;

        const existingUser = await checkUserExist({ email: data.email });
        if (existingUser.data === false) {
            const reqBody = {
                mobile: null,
                full_name: data.name,
                first_name: '',
                last_name: '',
                email: data.email,
                password: '',
                role_id: 2,
                isSocialLogin: true
            }
            const signUpResult = await signup(reqBody);
            if (signUpResult.status === 200) {
                localStorage.setItem('userInfo', JSON.stringify(signUpResult.data));
                const userProfile = await getProfile(signUpResult.data.user_id);
                manageRedirect(userProfile)
            }

        } else {
            const reqBody = {
                email: data.email,
                password: '',
                isSocialLogin: true,
                role_name: 'vakeel'
            }
            const response = await signin(reqBody);
            if (response.status === 200) {
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                const userProfile = await getProfile(response.data.user_id);
                manageRedirect(userProfile)
            }
        }

    }
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(false);
    const [resendTime, setResendTime] = useState('5:00');

    const sendOtp = async (resend = false) => {
        setLoadingOtp(true)
        const reqBody = { phoneNumber: phoneNumber }
        const response = await sendOTP(reqBody);
        if (response.status === 200) {
            if (resend) {
                toast.success("OTP resent successfully to your mobile number.", { autoClose: 1000 });
                setLoadingOtp(false)
                otpCountDown()
                return
            }
            setLoadingOtp(false);
            setIsOtpSent(true);
            otpCountDown()
            toast.success("OTP sent successfully to your mobile number.", { autoClose: 1000 });
        } else if (response.status === 401) {
            toast.error(response.data.message, { autoClose: 3000 });
            setIsOtpSent(false);
            setLoadingOtp(false)
        }
    };
    const VerifyOtp = async () => {
        const reqBody = { phoneNumber, otp, isSocialLogin: false, role_name: 'vakeel' }
        setLoading(true)
        const response = await verifyOTP(reqBody);
        if (response.status === 200) {
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            const userProfile = await getProfile(response.data.user_id);
            localStorage.setItem('profileImg', userProfile.data.profile_image_url);
            setTimeout(() => {
                setLoadingOtp(false);
                toast.success("Login successfully.", { autoClose: 1000 });
                setTimeout(() => {
                    manageRedirect(userProfile)
                }, 1000);
            }, 2000)
        } else if (response.status === 401) {
            setTimeout(() => {
                toast.error(response.data.message, { autoClose: 3000 });
                setLoadingOtp(false)
            }, 2000);
        }

    }

    const otpCountDown = () => {
        setTimer(true);
        let time = 299;
        const t = setInterval(() => {
            const minutes = Math.floor(time / 60);
            let seconds = time % 60;
            seconds = seconds < 10 ? '0' + seconds : seconds;
            const result = `${minutes}:${seconds}`;
            setResendTime(result);
            time--;
            if (time <= 0) {
                clearInterval(t);
                setTimer(false);
                setResendTime('5:00');
            }
        }, 1000);
    };
    return (
        <div className='size-full flex min-h-screen'>
            <div className="min-h-screen min-h-ful w-2/5 max-lg:hidden max-xl:block">
                <div className="wrap_bg bg-cover bg-center w-full h-full bg-no-repeat"></div>
            </div>
            <div className='min-h-screen size-full flex items-center justify-center w-3/5 flex-col gap-6 px-4 max-lg:w-full py-4 relative'>
                <Link to="/" className='text-lg visited:text-chichi font-medium text-chichi opacity-70 hover:text-chichi hover:opacity-100 focus:outline-none mx-auto w-fit flex items-center gap-2
        absolute left-0 top-0 max-w-fit pt-[15px] ps-5'>
                    <ArrowsLeft className='w-[25px] h-[25px]' />  Back </Link>
                <img className='' src={header_logo} alt=''></img>
                <div className='w-full max-w-xl py-8 px-10 flex flex-col justify-center bg-popo rounded-lg max-lg:py-4 max-lg:px-5'>
                    <h2 className='lg:text-2xl md:text-xl sm:text-xl text-xl font-bold mb-7 max-lg:mb-4 text-goten'>Login in to your account</h2>
                    {/*//! login email */}
                    {!isOtpSent ?
                        <form onSubmit={(e) => {
                            formik.handleSubmit(e);
                            e.preventDefault()
                        }}>
                            <div className="w-full mt-1">
                                <div className='mb-3 relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="email" size="lg" readOnly={loading ? true : false} placeholder="Enter Email Address" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <small className="text-danger validationError">{formik.errors.email}</small>
                                    ) : ''}
                                </div>
                                <div className="divide_line my-6 relative border-t-2"><span className='absolute -top-3.5 bg-popo left-1/2 px-2.5 -ml-3.5 text-goten'>Or</span></div>
                                <div className='mb-3 relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="text" size="lg" readOnly={loading ? true : false} placeholder="Enter phone number" name="phoneNumber" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.phoneNumber} />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <small className="text-danger validationError">{formik.errors.phoneNumber}</small>
                                    ) : ''}
                                </div>
                                <Button type='submit' className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg"
                                    disabled={!formik.isValid || loading}
                                >
                                    {loading &&
                                        <ButtonLoading />
                                    }
                                    Login
                                </Button>
                            </div>
                        </form>
                        :
                        (<>
                            <div className='mx-auto'>
                                <br />
                                <div className='mx-auto'>
                                    <AuthCode onChange={handleOnChangeOtp} />
                                </div>
                                <br />
                                <Button className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type="submit" size="lg"
                                    onClick={handleVerifyOtp}
                                    disabled={otpValue?.length <= 5 || loading}
                                >
                                    {/* {loading &&
                                    <ButtonLoading />
                                } */}
                                    Verify
                                </Button>
                                <br />
                                {timer ?
                                    <div className='otp-expiry'>
                                        <p className='text-white'>OTP expires in {resendTime}</p>
                                    </div> :
                                    <div className='otp-expiry'>
                                        <Link to="#" onClick={(e) => formik.handleSubmit(e)} className="font-medium text-chichi opacity-80 hover:opacity-100">
                                            Resend OTP
                                        </Link>
                                    </div>
                                }
                                <br />
                            </div>
                        </>)
                    }
                    {/* {!isOtpSent && (<>
                        <div className="divide_line my-6 relative border-t-2"><span className='absolute -top-3.5 bg-popo left-1/2 px-2.5 -ml-3.5 text-goten'>Or</span></div>
                        <div className='fb_login flex items-center gap-5 justify-between ' >
                            <div style={{ width: '100%' }}>
                                <Link
                                    to='#'
                                    className='flex items-center gap-2 bg-white p-2 rounded-lg w-full'
                                    onClick={login}
                                >
                                    <img src={google_icon} alt='' />
                                    Login with Google
                                </Link>
                            </div>
                            <div style={{ width: '100%' }}>
                                <FacebookLogin
                                    appId="884330170102979"
                                    autoLoad={false}
                                    fields="name, email, picture"
                                    scope="public_profile,email"
                                    cssClass='flex items-center gap-2 bg-white p-2 rounded-lg w-full'
                                    icon={<img src={facebook_icon} alt="facebook-logo" />}
                                    callback={(res) => handleFacebookLogin(res)}
                                />
                            </div>
                        </div>
                    </>)} */}

                    <div className="mt-4 text-center flex items-center justify-center flex-col gap-2 text-md">
                        {loading ? (
                            <span className="font-medium text-chichi opacity-80 hover:opacity-100" style={{ color: 'grey', pointerEvents: 'none', textDecoration: 'none' }}>
                                Forgot Password?
                            </span>
                        ) : (
                            <Link to="/forgot-password" className="font-medium text-chichi opacity-80 hover:opacity-100">
                                Forgot Password?
                            </Link>
                        )}
                        <p>
                            {loading ? (
                                <span className='text-goten'>
                                    Don't have an account? <span className="font-medium text-chichi opacity-80 hover:opacity-100" style={{ color: 'grey', pointerEvents: 'none', textDecoration: 'none' }}>Signup</span>
                                </span>
                            ) : (
                                <span className='text-goten'>
                                    Don't have an account? <Link to="/signup" className="font-medium text-chichi opacity-80 hover:opacity-100">Signup</Link>
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' />
        </div>
    )
}
export default Login;
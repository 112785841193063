// import axios from 'axios';
// import { baseURLApi } from '../config';

// export default axios.create({
//     baseURL: baseURLApi
// });
import axios from 'axios';
import { baseURLApi } from '../config';

const instance = axios.create({
    baseURL: baseURLApi
});

// Add a request interceptor
instance.interceptors.request.use(
    function (config) {
        // Get the token from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo?.token

        // Set the authorization header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default instance;

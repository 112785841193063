import React, { useEffect, useState } from 'react'
import { Input, Button } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import signupSchema from '../../validation-schemas/vakeel/signup-schema';
import { signup, verifyOtp } from '../../services/vakeel/auth-service';
import ButtonLoading from '../../components/loading/ButtonLoading';
// import { latestCase } from '../../services/user/case-service';
import { AuthCode } from '@heathmont/moon-core-tw';

const Signup = () => {
    const navigate = useNavigate();
    // const userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {}
    // useEffect(()=>{
    //     if(Object.keys(userInfo).length>0){
    //         if(userInfo.role === 'user'){
    //             navigate('/user')
    //         } else if(userInfo.role === 'vakeel') {
    //             navigate('/profile')
    //         }
    //     }
    // }, [])

    const [loading, setLoading] = useState(false);
    // const getLatestCase = async (user_id) => {
    //     const response = await latestCase(user_id)
    //     if (response.status === 200) {
    //         return response.data.case_detail_id
    //     } else if(response.status === 404) {
    //         return 0
    //     } else {
    //         console.log('err')
    //     }
    // }
    const [isOtp, setIsOpt] = React.useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            full_name: '',
            mobileNo: ''
        },
        validationSchema: signupSchema,
        onSubmit: async (values, { resetForm }) => {
            const reqBody = {
                mobile: values.mobileNo,
                full_name: values.full_name,
                email: values.email,
                role_id: 3,
                isSocialLogin: false
            }
            setLoading(true);
            const response = await signup(reqBody);
            if (response.status === 200) {
                toast.success("Otp send successfully.", { autoClose: 3000 });
                setIsOpt(true);
                setLoading(false);
            } else if (response.status === 400 || 401) {
                setTimeout(() => {
                    setLoading(false);
                    toast.error(response.data.message, { autoClose: 3000 });
                }, 2000)
            }
        },
    });

    useEffect(() => {
        return () => {
            formik.resetForm()
        }
    }, []);
    const [otp, setOtp] = useState('');
    const handleOnChange = (res) => {
        setOtp(res);
    };
    const handleVerifyOtp = async () => {
        setLoading(true);
        const reqBody = {
            "email": formik.values.email,
            "phoneNumber": formik.values.mobileNo,
            "otp": otp,
            "isSocialLogin": false,
            "role_name": "user"
        }
        const response = await verifyOtp(reqBody);
        // console.log(reqBody);
        if (response?.status === 200) {
            toast.success("Otp verified successfully.", { autoClose: 3000 });
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            localStorage.setItem('currentCaseId', '')
            localStorage.setItem('caseType', '');
            setTimeout(() => {
                setLoading(false);
                // toast.success("Otp send successfully.", { autoClose: 3000 });
                // alert(1)
                toast.success("Registered successfully.", { autoClose: 3000 });
                navigate('/user/update-profile');
            }, 2000)
        } else if (response.status === 401) {
            setLoading(false);
            toast.error(response.data.message, { autoClose: 3000 });
        }

    }
    return (
        <div className='size-full flex min-h-screen'>
            <div className="min-h-screen min-h-ful w-2/5 max-lg:hidden max-xl:block">
                <div className='wrap_bg bg-cover bg-center w-full h-full bg-no-repeat'></div>
            </div>
            <div className='min-h-screen size-full flex items-center justify-center w-3/5 flex-col gap-6 px-4 max-lg:w-full py-4'>
                <img className='' src={header_logo} alt=''></img>
                <div className='w-full max-w-xl py-8 px-10 flex flex-col justify-center bg-popo rounded-lg max-lg:py-4 max-lg:px-5'>
                    <h2 className='lg:text-2xl md:text-xl sm:text-xl text-xl font-bold mb-7 max-lg:mb-4 text-goten'>Sign up to your account</h2>
                    {!isOtp ?
                        <form onSubmit={formik.handleSubmit}>
                            <div className="w-full grid grid-cols-1 gap-4 mb-4 max-sm:grid-cols-1">
                                <div className='relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="text" size="lg" readOnly={loading ? true : false} placeholder="Enter Full Name" name="full_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.full_name} />
                                    {formik.touched.full_name && formik.errors.full_name ? (
                                        <small className="text-danger validationError">{formik.errors.full_name}</small>
                                    ) : ''}
                                </div>
                                <div className='relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="email" size="lg" readOnly={loading ? true : false} placeholder="Email Address" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <small className="text-danger validationError">{formik.errors.email}</small>
                                    ) : ''}
                                </div>
                                <div className='relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="number" size="lg" readOnly={loading ? true : false} placeholder="Mobile Number" name="mobileNo" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mobileNo} />
                                    {formik.touched.mobileNo && formik.errors.mobileNo ? (
                                        <small className="text-danger validationError">{formik.errors.mobileNo}</small>
                                    ) : ''}
                                </div>
                            </div>
                            <Button className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type="submit" size="lg" disabled={!formik.isValid || loading}>   {loading &&
                                <ButtonLoading />
                            } Sign up</Button>
                        </form>
                        : (<>
                            <div className='mx-auto'>
                                <br />
                                <div className='mx-auto'>
                                    <AuthCode onChange={handleOnChange} />
                                </div>
                                <br />
                                <Button className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type="submit" size="lg"
                                    onClick={handleVerifyOtp}
                                    disabled={otp?.length <= 5 || loading}
                                >
                                    {/* {loading &&
                                    <ButtonLoading />
                                } */}
                                    Verify
                                </Button>
                                <br />
                            </div>
                        </>)}
                    <div className="mt-4 text-center flex items-center justify-center flex-col gap-2 text-md">
                        <p>
                            {loading ? (
                                <span className='text-goten'>
                                    Already have an account? <span className="text-chichi" style={{ color: 'grey', pointerEvents: 'none', textDecoration: 'none' }}>Login</span>
                                </span>
                            ) : (
                                <span className='text-goten'>
                                    Already have an account? <Link to="/user/login" className="text-chichi">Login</Link>
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' />
        </div>
    )
}
export default Signup;
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IconButton, NativeSelect } from '@heathmont/moon-core-tw';
import Navbar from '../../components/vakeel/Navbar';
import { allCase, allVakeelCase } from '../../services/vakeel/dashboard-service';
import { dateFormatter, formatDate } from '../../utils/date-formatter';
import { capitalizeFirstLetters } from '../../utils/common-helper';
import { ControlsEye } from '@heathmont/moon-icons-tw';

const AllCase = () => {
    const navigate = useNavigate()
    const [allCaseDetails, setAllCaseDetails] = useState([]);
    const [filterOption, setFilterOption] = useState('All');
    const eventDetails = JSON.parse(localStorage.getItem('userInfo'));

    // const getAllCase = async () => {
    //     const response = await allCase(eventDetails?.user_id);
        // if (response.status === 200) {
        //     setAllCaseDetails(response.data);
        // }
    // };
    const getAllVakeelCases = async () => {
        const response = await allVakeelCase(eventDetails?.user_id);
        console.log('all-cases', response);
        if (response.status === 200) {
            setAllCaseDetails(response.data);
        }
    };

    useEffect(() => {
        getAllVakeelCases()
        // getAllCase();
    }, []);

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    // const filteredCases = allCaseDetails.filter((value) => {
    //     if (filterOption === 'All') {
    //         return true;
    //     } else {
    //         return value.status === (filterOption === 'Active');
    //     }
    // });

    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-20'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='w-full py-7 px-7 bg-white mx-auto rounded-lg max-md:py-5 max-md:px-5'>
                        <div className='flex items-center justify-between border-b pb-5 max-md:pb-3'>
                            <h2 className='text-[24px] max-md:text-[20px] font-bold text-gray-900 whitespace-nowrap'>All Cases</h2>
                            {/* <NativeSelect className='flex min-w-fit max-w-[120px]' onChange={handleFilterChange} value={filterOption}>
                                <option value="All">All</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </NativeSelect> */}
                        </div>
                        <div className='w-full mx-auto mt-2'>
                            <div className="align-middle inline-block w-full overflow-x-auto">
                                <table className="min-w-full">
                                    <thead>
                                        <tr className="leading-4 tracking-wider whitespace-nowrap">
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > S.No. </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > Date </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600">
                                                <Link className='flex align-middle gap-2' > Customer Name </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2' > Case Type </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2 max-w-[500px] min-w-[300px]' > Case Name </Link>
                                            </th>
                                            {/* <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2 min-w-[80px] max-w-[80px]' > Status  </Link>
                                            </th> */}
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
                                                <Link className='flex align-middle gap-2 min-w-[80px] max-w-[80px]' >Action</Link>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {allCaseDetails.length === 0 ? (
                                            <tr>
                                                <td colSpan="6" className="text-gray-600 text-center py-4">No data available</td>
                                            </tr>
                                        ) : (
                                            allCaseDetails.map((value, index) => (
                                                // console.log('allCaseDetails', allCaseDetails)
                                                <tr key={index}>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{index + 1}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{formatDate(value?.appointment_date)}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="font-medium">{value?.client_first_name}{" "}{value?.client_last_name}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap">
                                                        <span className='font-medium'>{value?.incident_type}</span>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap">
                                                        <div className='font-medium max-w-[500px] min-w-[300px]'>{value?.case_name}</div>
                                                    </td>
                                                    {/* <td className="px-5 py-3 whitespace-nowrap">
                                                        <div className={`text-md font-medium ${value?.status === true ? 'text-teal-600' : 'text-rose-600'} min-w-[80px] max-w-[80px]`}>
                                                            {value?.status === true ? 'Active' : 'In-Active'}
                                                        </div>
                                                    </td> */}
                                                    <td className="px-5 py-3 whitespace-no-wrap">
                                                        <IconButton title='View case details' onClick={() => {
                                                            let data = {
                                                                "appointment_id": value?.appointment_id,
                                                                "client_user_id": value?.client_id,
                                                                "appointment_date": value?.appointment_date,
                                                                "start_time": value?.start_time,
                                                                "end_time": value?.end_time,
                                                                "case_name": "",
                                                                "case_type": "",
                                                                "status": true,
                                                                "client_first_name": value?.client_first_name,
                                                                "client_last_name": value?.client_last_name,
                                                                "lawyer_first_name": '',
                                                                "lawyer_last_name": '',
                                                                "image_url": value?.image_url,
                                                            }
                                                            // console.log('values', value)
                                                            // let data = value
                                                            // data = { ...data, appointment_date: dateFormatter(data.appointment_date) }
                                                            localStorage.setItem('eventDetails', JSON.stringify(data));
                                                            navigate(`/case-detail/${value?.appointment_id}`)
                                                        }} icon={<ControlsEye />} />
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllCase

import React from 'react'
import { Button, IconButton, Modal } from '@heathmont/moon-core-tw';
import { ControlsPlus } from '@heathmont/moon-icons-tw'
import { Link } from 'react-router-dom'
import { formateDate, getFormattedTime } from '../../utils/date-formatter'

const EventModal = ({ isOpen, closeModal, dateEvents, eventDate, viewDetails }) => {
	return (
		<div>
			<Modal open={isOpen} onClose={closeModal}>
				<Modal.Backdrop />
				<Modal.Panel className='max-w-[700px]'>
					<div className="p-4 border-b-2 border-beerus flex justify-between items-center">
						<h3 className="text-moon-18 text-bulma font-medium">
							{`${formateDate(eventDate)}, ${new Date(eventDate).getFullYear()}`}
						</h3>
						{/* <div className="">
							<IconButton icon={<ControlsPlus />} />
						</div> */}
					</div>
					<div className="p-4">
						<div className='modaltable_scroller'>
							<div className='w-full mx-auto'>
								<div className="align-middle inline-block w-full overflow-x-auto">
									<table className="min-w-full">
										<thead>
											<tr className="leading-4 tracking-wider whitespace-nowrap">
												<th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
													<Link className='flex align-middle gap-2 whitespace-nowrap' > Time </Link>
												</th>
												<th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
													<Link className='flex align-middle gap-2 whitespace-nowrap' > Case Name </Link>
												</th>
												<th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
													<Link className='flex align-middle gap-2 whitespace-nowrap' > Client Name </Link>
												</th>
												<th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
													<Link className='flex align-middle gap-2 whitespace-nowrap' > Case Type </Link>
												</th>
												<th className="px-5 py-3 text-left text-[15px] font-medium text-teal-600 whitespace-nowrap">
													<Link className='flex align-middle gap-2 whitespace-nowrap' >  </Link>
												</th>
											</tr>
										</thead>
										<tbody>
											{dateEvents.length > 0 && dateEvents.map((x, i) => {
												return (
													<tr key={i}>
														<td className="px-5 py-3">
															<div className="text-md leading-5 font-normal text-gray-900">{getFormattedTime(x.start_time)}</div>
														</td>
														<td className="px-5 py-3">
															<div className="text-md leading-5 font-normal text-gray-900">{x.case_name}</div>
														</td>
														<td className="px-5 py-3">
															<div className="text-md leading-5 font-normal text-gray-900">{x.client_first_name} {x.client_last_name}</div>
														</td>
														<td className="px-5 py-3">
															<div className="text-md leading-5 font-normal text-gray-900">{x.case_type}</div>
														</td>
														<td className="px-5 py-3 whitespace-nowrap">
															<Button className='bg-beerus text-dark' onClick={() => viewDetails(x)}>View Detail</Button>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</Modal.Panel>
			</Modal>
		</div>
	)
}

export default EventModal

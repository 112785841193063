import React, { Component } from 'react';
import { Button, FormControl, FormGroup, Glyphicon, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVolumeUp, faVolumeMute, faUpload } from '@fortawesome/free-solid-svg-icons';

import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
const speechsdk = require('microsoft-cognitiveservices-speech-sdk');

class Textbox extends Component {

    constructor() {
        super();
        this.speechToText = this.speechToText.bind(this);
        this.handleClickMute = this.handleClickMute.bind(this);
        this.state = {
            messages: [
                {
                    type: 0,
                    text: ""
                }
            ],
            muted: false,
            micFlag: false,
            micUse: false
        };
    }

    handleChange(e) {
        this.setState({
            messages: [
                {
                    type: 0,
                    text: e.target.value
                }
            ]
        })
    };

    handleClick(evt) {
        evt.preventDefault();
        if (!this.state.micUse) {
            this.props.handlerFromParent(this.state.messages, this.state.micFlag);
            this.setState({
                messages: [
                    {
                        type: 0,
                        text: ""
                    }
                ]
            });
            this.setState((prevState) => ({
                micFlag: false
            }));
        }
    };

    handleClickMute(evt) {
        evt.preventDefault();
        this.props.handleMute();
        this.setState((prevState) => ({
            muted: !prevState.muted
        }));

    };

    keyPress(e) {
        if (e.keyCode === 13 && !this.state.micUse) {
            e.preventDefault();
            this.props.handlerFromParent(this.state.messages, this.state.micFlag);
            this.setState({
                messages: [
                    {
                        type: 0,
                        text: ""
                    }
                ]
            });
            this.setState((prevState) => ({
                micFlag: false
            }));

        }
    }

    async speechToText() {
        try {
            this.setState((prevState) => ({
                micFlag: true,
                micUse: true
            }));
            const tokenObj = await this.props.getTokenOrRefresh();
            const autoDetectSourceLanguageConfig = speechsdk.AutoDetectSourceLanguageConfig.fromLanguages(this.props.languages);
            const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
            const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

            speechConfig.setProperty(speechsdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "6000");
            speechConfig.setProperty(speechsdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "4000");
            speechConfig.setProperty(speechsdk.PropertyId.Speech_SegmentationSilenceTimeoutMs, "2000");

            const recognizer = speechsdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
            this.setState({
                messages: [
                    {
                        type: 0,
                        text: "Say Something!"
                    }
                ]
            });

            recognizer.recognizeOnceAsync((result) => {
                if (result.reason === ResultReason.RecognizedSpeech) {
                    this.setState({
                        messages: [
                            {
                                type: 0,
                                text: `${result.text}`
                            }
                        ],
                        micUse: false
                    });
                } else {
                    this.props.setMessages((prevMessages) => [
                        ...prevMessages,
                        { text: 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.', type: 1 }
                    ]);
                    this.setState({
                        messages: [
                            {
                                type: 0,
                                text: ""
                            }
                        ],
                        micUse: false
                    });
                }
            });
        } catch (error) {
            console.log(error.message);
        }
    }

    render() {
        return (
            <FormGroup >
                <InputGroup style={{ display: 'flex' }}>
                    <FormControl value={this.state.messages[0].text} onChange={this.handleChange.bind(this)}
                        onKeyDown={this.keyPress.bind(this)} type="text" placeholder="Type here...." id='text_box' />
                    <InputGroup.Button
                    //  style={{ display: (this.props.audioFlag && this.props.micUseFlag && this.props.speechLength === 0) ? "" : "none" }}
                    >
                        <Button style={{ padding: '10px 15px' }} onClick={this.speechToText} bsStyle="primary" id='mic_button' ><FontAwesomeIcon icon={faMicrophone} id='theme_color_button' /></Button>
                    </InputGroup.Button>
                    {/* <InputGroup.Button style={{ display: (this.props.audioFlag && (!this.props.micUseFlag || this.props.speechLength > 0)) ? "" : "none" }}>
                        <Button style={{ padding: '10px 15px' }} onClick={this.handleClickMute} bsStyle="primary" id='mic_button' ><FontAwesomeIcon icon={this.state.muted ? faVolumeMute : faVolumeUp} id='theme_color_button' /></Button>
                    </InputGroup.Button> */}

                    <InputGroup.Button>
                        <Button onClick={this.handleClick.bind(this)} bsStyle="primary" id='send_button' style={{ padding: '10px 15px' }}>
                            <FontAwesomeIcon icon={faUpload} id='theme_color_button' />
                            {/* <Glyphicon glyph="glyphicon glyphicon-send" id='theme_color_button'> */}
                            {/* </Glyphicon> */}
                        </Button>
                    </InputGroup.Button>

                </InputGroup>
            </FormGroup>
        )

    }
}

export default Textbox;
import api from "../api"
const profileUpdate = (reqBody, id) => {
    return api.put(`vakeel/profile/${id}`, reqBody).catch((err) => err.response)
}
const getProfile = (id) => {
    return api.get(`vakeel/profile/${id}`).catch((err) => err.response)
}
const checkToken = (token) => {
    return api.post(`vakeel/verify-reset-token`, {token}).catch((err) => err)
}

export { profileUpdate , getProfile, checkToken };
import React from 'react'
import { Button, } from '@heathmont/moon-core-tw'
import { checkout, createAnonmousCase, verifyPayment } from '../../services/user/case-service';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const AnonmousPayment = () => {
    const navigate = useNavigate()
    // todo post anonymous case then create checkout with type anonyous then razorpay then verify
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(userInfo, "userInfo")
    const [loading, setLoading] = React.useState(false);
    const handleGetConsultation = async () => {
        setLoading(true);
        // const res = await createAnonmousCase()
        // if (res?.status === 200) {
        //     console.log(res, "case")
        setTimeout(async () => {
            const body = { "type": "anonymous" }
            const checkoutRes = await checkout(body)
            if (checkoutRes?.status === 200) {
                // console.log(checkoutRes, 'ceckoutRes')
                const order_id = checkoutRes?.data?.data?.order?.id;
                const amount = checkoutRes?.data?.data?.order?.amount;
                const anonymous_id = checkoutRes?.data?.data?.anonymous_id;
                setTimeout(() => {
                    setLoading(true)
                    consultNow(order_id, anonymous_id, amount) //orderid- sosId
                }, 500);
            } else {
                toast.error(checkoutRes?.data?.message, { autoClose: 3000 });
            }
        }, 500);
        // }
    }
    const consultNow = async (order_id, anonymous_id, amount) => {
        // console.log(amount, "amount")
        // console.log(order_id, "order_id")
        // console.log(anonymous_id, "sos_id")
        const options = {
            key: "rzp_test_eDawdhRtZBJkL4",
            // key: "rzp_test_YD1PceY5BUC7kj",
            amount: amount * 100,
            currency: "INR",
            name: "Vakeel",
            description: "Test Transaction",
            image: "",
            order_id: order_id,
            handler: async (response) => {
                const body = {
                    "user_id": userInfo?.user_id,
                    "amount": amount,
                    "payment_data": response,
                    "type": "anonymous",
                    "anonymous_id": anonymous_id
                }
                const res = await verifyPayment(body)
                if (res?.status === 200) {
                    toast.success('Thank you for Registrating on Vakeel ,Our suppor team will call you back')
                    setTimeout(() => {
                        setLoading(false)
                        navigate("/user/payment-history")
                    }, 1000);
                    // ! redirect to payment history
                }
            },
            modal: { ondismiss: function () { setLoading(false) } },
            // "callback_url": "http://localhost:5800/api/user/paymentVerification",
            prefill: {
                name: userInfo?.full_name,
                email: userInfo?.email,
                contact: userInfo?.mobile
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#3399cc"
            }
        };

        const razor = new window.Razorpay(options);
        razor.open();
    }
    return (
        <>
            <div className='h-screen flex items-center max-w-[600px] m-auto'>
                <div className='container mx-auto px-5 max-w-screen-xl max-md:px-3'>
                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo mx-auto rounded-lg max-md:py-5 max-md:px-5 mt-5 '>
                        <b className='text-lg text-center mb-3 text-white'>Anonymous user </b>
                        <p className='text-md text-center font-medium mb-0 text-white'>
                            Make payment to get a call back and get answers to your query while keeping your identity a secret
                        </p>
                        <div className='block'>
                            <br />
                            <Button onClick={handleGetConsultation} disabled={loading} className='w-full block rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg">
                                Make payment
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnonmousPayment
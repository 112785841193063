import React, { useEffect } from 'react'
import Navbar from './Navbar'
import { getProfile } from '../../services/vakeel/profile-service';
import { useNavigate } from 'react-router-dom';

// const manageRedirect = async (userInfo, navigate) => {
//     const userProfile = await getProfile(userInfo.user_id);
//     if (Object.keys(userProfile.data).length > 0 && userProfile.data.is_profile_completed) {
//         if (!userProfile.data.is_approved && !userProfile.data.is_rejected) {
//             localStorage.setItem('profileApproved', 0);
//             navigate('/profile-submitted');
//         } else {
//             navigate('/dashboard');
//             localStorage.setItem('profileApproved', 1);
//         }
//     }
// }

const Confirmation = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();
    const manageRedirect = async () => {
        const userProfile = await getProfile(userInfo.user_id);
        console.log('userProfile', userProfile.data)
        if (Object.keys(userProfile.data).length > 0 && userProfile.data.is_profile_completed) {
            if (!userProfile.data.is_approved && !userProfile.data.is_rejected) {
                localStorage.setItem('profileApproved', 0);
                navigate('/profile-submitted');
            } else {
                navigate('/dashboard');
                localStorage.setItem('profileApproved', 1);
            }
        }
    }
    useEffect(() => {
        manageRedirect();
    }, []);

    return (
        <>
            <Navbar />
            <div className='h-screen flex items-center max-w-[600px] m-auto'>
                <div className='container mx-auto px-5 max-w-screen-xl max-md:px-3'>
                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo mx-auto rounded-lg max-md:py-5 max-md:px-5 mt-5 '>
                        <b className='text-lg text-center mb-3 text-white'>Thank you for your submission! </b>
                        <p className='text-md text-center font-medium mb-0 text-white'>Your request has been successfully sent to the administrator for approval. We appreciate your patience during this process. You will be notified once the administrator has reviewed your details and made a decision.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Confirmation

import React from 'react'
// import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import vaadin_img from '../images/vaadin_office.svg'
import mdi_img from '../images/mdi_form.svg'
import octicon_img from '../images/octicon_law.svg'
import { ArrowsRight } from '@heathmont/moon-icons-tw'
import { Button } from '@heathmont/moon-core-tw'
import footer_logo from '../images/footer.svg'
import Navbar from '../components/user/Navbar'
import stepball from '../images/stepbox_img.svg'
import step_thumb from '../images/step_thumb.svg'

import shoffr from '../images/shoffr.svg'
import insurfin from '../images/insurfin.png'
import himalaya from '../images/himalaya.svg'
import titan from '../images/titan.svg'
import fb from '../images/fb.svg'
import insta from '../images/insta.svg'
import linkdn from '../images/linkdln.svg'
import twitter from '../images/twitter.svg'
import vakeel_img from '../images/vakeel_img.png'
import bus_incp from '../images/business_incorp.svg'
import criminal from '../images/criminal.svg'
import legal_img from '../images/legal_form.svg'
import from_stmp from '../images/form_stmp.svg'
import { useState } from 'react';
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import ChatBot from '../bot-testing/chatbot'

import { ControlsChevronLeftSmall, ControlsChevronRightSmall, ControlsPlus, GenericLightningBolt } from '@heathmont/moon-icons-tw'
import { Accordion, Carousel, Dropdown, MenuItem } from '@heathmont/moon-core-tw'

const Home = () => {
    const navigate = useNavigate()
    const items = Array.from({ length: 5 }, (index) => index);
    let [showChat, setShowChat] = useState(false);
    const unique_id = uuid();
    const startChat = () => { setShowChat(true); }
    const hideChat = () => { setShowChat(false); }
    return (
        <div className='relative'>
            {/* chatbot>>>>>>>>>>>>>>>>>>> */}
            <div>
            <div className="top-right-button" 
                style={{
                    zIndex: 1
                }}
            >
                {!showChat ? (
                    <button className="circular" onClick={startChat}>
                        <FontAwesomeIcon icon={faComment} id="theme_color_button" style={{ fontSize: '30px' }} />
                    </button>
                ) : (
                    <button className="circular" onClick={hideChat}>
                        <FontAwesomeIcon icon={faCircleXmark} id="theme_color_button" style={{ fontSize: '40px' }} />
                    </button>
                )}
            </div>

            <div
            className='chatbot-wrapper'
                style={{
                    display: showChat ? '' : 'none',
                    zIndex: 1
                }}
            >
                <ChatBot />
            </div>
        </div>

            {/* chatbot>>>>>>>>>>>>>>>>>>> */}

            <Navbar />

            <div className='landing_img landing_img_home bg-cover bg-no-repeat h-[800px] max-lg:h-[600px] w-full relative flex items-center justify-center'>
                <div className='landing_img_responsive'></div>
                <div className="container mx-auto px-5 max-md:px-3">
                    <div className='max-w-[500px] relative z-5'>
                        <h2 class=" lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] leading-tight font-bold mb-4 max-md:mb-4
                         text-[#F6DA96] lg:text-white">Get legal advice right at your fingertips.</h2>
                        <p class="xl:text-[25px] lg:text-[22pxl md:text-[22px] sm:text-[14px] text-[14px] font-normal text-white">India's Leading Legal Tech platform Any legal query?  Get a lawyer to solve it</p>
                        <div className='mt-8'><Button size='xl' className='px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => navigate('/user/register-case')}>Get Started</Button></div>
                    </div>
                </div>
            </div>

            <div className='bg-popo py-10 max-md:py-5'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <p className='xl:text-2xl lg:text-2xl sm:text-lg text-lg font-normal text-center mb-0 text-goten opacity-80'>Cases Managed by Vakeel: Over <span className='text-chichi font-bold'>21,589</span>  Cases Managed and Counting...</p>
                </div>
            </div>

            <div className='py-24 max-lg:py-16'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='text-center max-w-[1400px] mx-auto'>
                        <div className='max-w-[550px] m-auto'>
                            <h2 class="xl:text-3xl sm:text-2xl text-xl font-medium text-chichi opacity-80 mb-4">Legal advice made easy</h2>
                            <p class="lg:text-xl md:text-lg text-[16px] font-normal text-goten opacity-80 text-center mb-0">Use Vakeel to speak to thousands of lawyers in the country and recieve expert advice</p>
                        </div>
                        <div className='grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 sm:gap-5 gap-0 mt-6'>
                            <div className='legal_box max-md:max-w[300px] max-md' >
                                <div className='w-full sm:py-4 py-0 px-4 flex flex-col justify-between mx-auto rounded-lg h-full'>
                                    <div className='relative'>
                                        <div className='absolute rounded-full w-[150px] h-[150px] bg-popo top-0 right-0 left-0 m-auto -z-1'></div>
                                        <div className='max-w-[80px] max-h-[90px] mx-auto sm:mb-8 mb-2 z-1'>
                                            <img className='mx-auto object-cover w-[50px] h-[50px] sm:w-[80px] sm:h-[80px]' src={criminal} alt='' />
                                        </div>
                                        <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl font-medium text-goten sm:mb-3 mb-2 sm:text-[21px] text-[14px]'>Criminal and civil law</h4>
                                        <p className='text-md text-center font-normal text-goten opacity-80 mb-2 sm:text-[18px] text-[13px]'>Get expect consultation on Civil and criminal incident like thift, attack or forgery.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='legal_box max-md:max-w[300px] max-md:ml-auto legal_box_custom' >
                                <div className='w-full sm:py-4 py-0 px-4 flex flex-col justify-between mx-auto rounded-lg h-full'>
                                    <div className='relative'>
                                        <div className='absolute rounded-full w-[150px] h-[150px] bg-popo top-0 right-0 left-0 m-auto -z-1'></div>
                                        <div className='max-w-[80px] max-h-[90px] mx-auto sm:mb-8 mb-2 z-1'>
                                            <img className='mx-auto object-cover w-[50px] h-[50px] sm:w-[80px] sm:h-[80px]' src={legal_img} alt=''></img>
                                        </div>
                                        <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl font-medium text-goten sm:mb-3 mb-2 sm:text-[21px] text-[14px]'>Legal forms</h4>
                                        <p className='text-md text-center font-normal text-goten opacity-80 mb-2 sm:text-[18px] text-[13px]'>Get access to many legal forms for compliances.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='legal_box max-md:max-w[300px] max-md legal_box_custom'>
                                <div className='w-full sm:py-4 py-0 px-4 flex flex-col justify-between mx-auto rounded-lg h-full'>
                                    <div className='relative'>
                                        <div className='absolute rounded-full w-[150px] h-[150px] bg-popo top-0 right-0 left-0 m-auto -z-1'></div>
                                        <div className='max-w-[80px] max-h-[90px] mx-auto sm:mb-8 mb-2 z-1'>
                                            <img className='mx-auto object-cover w-[50px] h-[50px] sm:w-[80px] sm:h-[80px]' src={bus_incp} alt=''></img>
                                        </div>
                                        <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl font-medium text-goten sm:mb-3 mb-2 sm:text-[21px] text-[14px]'>Business incorporation</h4>
                                        <p className='text-md text-center font-normal text-goten opacity-80 mb-2 sm:text-[18px] text-[13px]'>Starting a business? We make it easy! Use Vakeel's guided incorporation process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='legal_box max-md:max-w[300px] max-md:ml-auto legal_box_custom'>
                                <div className='w-full sm:py-4 py-0 px-4 flex flex-col justify-between mx-auto rounded-lg h-full'>
                                    <div className='relative'>
                                        <div className='absolute rounded-full w-[150px] h-[150px] bg-popo top-0 right-0 left-0 m-auto -z-1'></div>
                                        <div className='max-w-[80px] max-h-[90px] mx-auto sm:mb-8 mb-2 z-1'>
                                            <img className='mx-auto object-cover w-[50px] h-[50px] sm:w-[80px] sm:h-[80px]' src={from_stmp} alt=''></img>
                                        </div>
                                        <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl font-medium text-goten sm:mb-3 mb-2 sm:text-[21px] text-[14px]'>Forms & Stamp papers</h4>
                                        <p className='text-md text-center font-normal text-goten opacity-80 mb-2 sm:text-[18px] text-[13px]'>We make it easy! Vakeel's help you in stamp papers and forms process</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //!roadm map */}
            <div className='text-center bg-popo sm:mb-20 mb-0' style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                <div className='max-w-[850px] m-auto p-5'>
                    <h2 class="xl:text-3xl sm:text-2xl text-xl font-medium text-chichi opacity-80 mb-4">Here’s how we do it </h2>
                    <p class="lg:text-xl md:text-lg text-[16px] font-normal text-goten opacity-80 text-center mb-0">Start your legal journey on Vakeel: Share case details, choose from top lawyers, and get streamlined, efficient legal support</p>
                </div>

                {/* //!roadm map mobil*/}
                <div className='p-10 mx-auto custom-roadmap-mobile'>
                    <div style={{ borderTop: '8.78px solid #D5AD08', borderLeft: '0', marginLeft: "-50%", marginBottom: '-8.5px', width: '300px' }}></div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', boxSizing: 'border-box', width: '100%', border: '9px solid #D5AD08', borderLeft: '0', borderRadius: '0 20px 20px 0', height: 'auto', }}>
                        <div className='' style={{ boxSizing: 'border-box', width: '80%', padding: '20px 5px' }}>
                            <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt="" style={{ marginTop: '-45px' }}></img>
                            <br />
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 1</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Signup and Share Case Details</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Provide case specifics during sign-up. This sets the foundation for tailored legal support.</p>
                            <br />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'flex-end',
                        boxSizing: 'border-box', width: '100%', height: 'auto', border: '9px solid #D5AD08', borderRight: '0',
                        borderRadius: '20px 0 0 20px', marginTop: '-9px', marginLeft: '-15px'
                    }}>
                        <div className='' style={{ boxSizing: 'border-box', width: '80%', padding: '20px 5px' }}>
                            <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt="" style={{ marginTop: '-45px' }}></img>
                            <br />
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 2</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Complete KYC</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Ensuring transparency and security through the KYC process.. This step helps build a trustworthy connection between you and our legal experts.</p>
                            <br />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'flex-start',
                        boxSizing: 'border-box', width: '100%', border: '9px solid #D5AD08', borderLeft: '0',
                        borderRadius: '0 20px 20px 0', height: 'auto', marginTop: '-9px'
                    }}>
                        <div className='' style={{ boxSizing: 'border-box', width: '80%', padding: '20px 5px' }}>
                            <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt="" style={{ marginTop: '-45px' }}></img>
                            <br />
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 3</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Choose Top Lawyer</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Pick from our recommended legal experts. We curate a personalized selection to meet your specific needs.</p>
                            <br />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'flex-end',
                        boxSizing: 'border-box', width: '100%', height: 'auto', border: '9px solid #D5AD08',
                        borderRight: '0', borderBottom: '0',
                        borderRadius: '20px 0 0 0', marginTop: '-9px', marginLeft: '-15px'
                    }}>
                        <div className='' style={{ boxSizing: 'border-box', width: '80%', padding: '20px 5px 50px 5px' }}>
                            <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt="" style={{ marginTop: '-45px' }}></img>
                            <br />
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 4</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Schedule Consultation and Pay</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Set consultation time, make the payment. Your chosen time slot is reserved for dedicated legal counsel.</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-35px', width: "70%" }}>
                        <div style={{ width: '100%', border: '9px solid #D5AD08', borderTop: '0', borderRight: '0', borderRadius: '0 0 0 20px', marginLeft: '-15px', marginTop: '-5px' }}>
                            <br />
                        </div>
                        <img className='min-w-[100px] max-w-[100px] max-h-[100px] mx-auto object-contain  step-thumb-custom' src={step_thumb} alt=""
                            style={{ marginLeft: '-50px' }}></img>
                    </div>
                    <div className='w-full px-5 flex flex-col justify-between mx-auto rounded-lg h-full'>
                        <div className=''>
                            <span className='text-md mb-2 block  text-[#CB8178]' style={{ visibility: 'hidden' }}>Step 5</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Consultation Booked</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>You now have access all case information on your dedicated case details page. </p>
                        </div>
                    </div>
                </div>
                {/* //!roadm map web*/}
                <div className='custom-roadmap-web' style={{ paddingRight: '100px' }}>
                    <div className='flex flex-wrap mt-6 justify-end '>
                        <div className='' style={{ borderTop: '9px solid #D5AD08', width: '33.2%', boxSizing: 'border-box', padding: '30px 30px 100px 30px' }}>
                            <div className='min-h-[100px] max-h-[100px] flex items-center justify-center' style={{ marginTop: "-85px" }}>
                                <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt=""></img>
                            </div>
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 1</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Signup and Share Case Details</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Provide case specifics during sign-up. This sets the foundation for tailored legal support.</p>
                        </div>
                        <div className='' style={{ borderTop: '9px solid #D5AD08', width: '33.2%', boxSizing: 'border-box', padding: '30px 30px 100px 30px' }}>
                            <div className='min-h-[100px] max-h-[100px] flex items-center justify-center' style={{ marginTop: "-85px" }}>
                                <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt=""></img></div>
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 2</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Complete KYC</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Ensuring transparency and security through the KYC process.. This step helps build a trustworthy connection between you and our legal experts.</p>
                        </div>
                        <div className='' style={{
                            borderTop: '9px solid #D5AD08', borderRight: '9px solid #D5AD08', borderBottom: '9px solid #D5AD08',
                            borderRadius: '0 150px 150px 0', width: '33.2%', boxSizing: 'border-box', padding: '30px 30px 100px 30px'
                        }}>
                            <div className='min-h-[100px] max-h-[100px] flex items-center justify-center' style={{ marginTop: "-85px" }}>
                                <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt=""></img></div>
                            <span className='text-md mb-2 block text-[#CB8178]'>Step 3</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Choose Top Lawyer</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Pick from our recommended legal experts. We curate a personalized selection to meet your specific needs.</p>
                        </div>
                        <div className='' style={{ borderTop: '9px solid #D5AD08', width: '33.2%', boxSizing: 'border-box', padding: '30px', marginTop: '-8.5px', }}>
                            <div style={{ marginLeft: "-110%" }}>
                                <div className='min-h-[100px] max-h-[100px] flex items-center justify-center' style={{ marginTop: "-90px" }}>
                                    <img className='min-w-[100px] max-w-[80px] max-h-[80px] mx-auto object-contain  step-thumb-custom'
                                        src={step_thumb} alt=""></img>
                                </div>
                                <div>
                                    <span className='text-md mb-2 block  text-[#CB8178]' style={{ visibility: 'hidden' }}>Step 5</span>
                                    <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Consultation Booked</h4>
                                    <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>You now have access all case information on your dedicated case details page. </p>
                                </div>
                            </div>
                        </div>
                        <div className='' style={{ width: '33.2%', boxSizing: 'border-box', padding: '30px', marginTop: '-9px' }}>
                            <div className='min-h-[100px] max-h-[100px] flex items-center justify-center' style={{ marginTop: "-75px" }}>
                                <img className='max-w-[50px] max-h-[50px] mx-auto object-contain' src={stepball} alt=""></img></div>
                            <span className='text-md mb-2 block  text-[#CB8178]'>Step 4</span>
                            <h4 className='lg:text-xl md:text-xl sm:text-xl text-xl text-chichi opacity-80 font-medium mb-2'>Schedule Consultation and Pay</h4>
                            <p className='text-md text-center font-medium mb-2 text-goten opacity-80'>Set consultation time, make the payment. Your chosen time slot is reserved for dedicated legal counsel.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sm:bg-[#34352E] bg-[trasnparent]  py-8' >
                <div className='container mx-auto '>
                    <div style={{ borderRadius: "16px", width: '90%' }} className='mx-auto sm:p-10 p-5 bg-[#34352E]'>
                        <div className='text-center mb-6 max-w-[1400px] mx-auto'>
                            <h2 class="sm:text-[24px] text-[15px] font-medium text-white opacity-80 mb-4">Trusted and Loved by Great Companies ....</h2>
                        </div>
                        <div className='flex justify-center sm:gap-16 gap-8 items-center sm:mt-12 mt-2 max-md:flex-wrap'>
                            <div className='max-w-[160px] max-md:max-w-[120px]'><img className='object-contain' src={shoffr} alt=''></img></div>
                            <div className='max-w-[160px] max-md:max-w-[120px]'><img className='object-contain' src={insurfin} alt=''></img></div>
                            <div className='max-w-[160px] max-md:max-w-[120px]'><img className='object-contain' src={himalaya} alt=''></img></div>
                            <div className='max-w-[160px] max-md:max-w-[120px]'><img className='object-contain' src={titan} alt=''></img></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='pb-10 sm:pt-10 pt-0' >
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='text-center mb-14 max-w-[1400px] mx-auto'>
                        <h2 class="xl:text-3xl sm:text-2xl text-xl font-medium text-chichi opacity-80 mb-4">Customers saying about Vakeel</h2>
                    </div>

                    <Carousel step={1} selectedIndex={1} className="w-full h-full">


                        <Carousel.Reel className="gap-5">
                            {items.map((_, index) => (
                                <Carousel.Item key={index} className="">
                                    <div className='bg-popo py-10 px-5 rounded-lg text-center max-w-[500px] max-md:w-[300px] max-sm:py-5 max-sm:px-2'>
                                        <p className='text-md mb-5 text-goten opacity-80'>The legal expertise provided by vakeel was instrumental in navigating complex corporate governance issues.
                                            The nuanced advice and strategic guidance offered by their legal professionals significantly contributed to our company's decision-making processes.
                                            I highly recommend their services for corporate legal matters.</p>
                                        <h4 className='text-chichi opacity-80 text-md font-semibold'>Parul Sengar</h4>
                                        <span className='text-goten opacity-80 text-sm'>Home maker</span>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel.Reel>
                        <div className='carousel_btn_grp'>
                            <Carousel.LeftArrow className='bg-goten opacity-50 rounded-full carousel_arrow'>
                                {/* <ControlsChevronLeftSmall className='text-black' /> */}
                                <img src={require("../images/arrowleft.png")} alt="" style={{ width: "25px" }} />
                            </Carousel.LeftArrow>
                            <Carousel.RightArrow className='bg-goten opacity-50 rounded-full carousel_arrow'>
                                {/* <ArrowsRight className='text-black' /> */}
                                <img src={require("../images/arrowright.png")} alt="" style={{ width: "25px" }} />
                            </Carousel.RightArrow>
                        </div>
                    </Carousel>

                </div>
            </div>

            <div className='py-16'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='flex gap-10 justify-around max-md:flex-col max-md:max-w-[500px] max-md:mx-auto max-md:text-center'>
                        <div className='max-w-[300px] max-md:max-w-full'>
                            <h2 class="xl:text-3xl sm:text-2xl text-xl font-medium text-chichi opacity-80 mb-4">Frequently Asked Questions</h2>
                            <p class="xl:text-xl font-normal mb-0 text-goten opacity-80">Find quick answers to our most commonly asked questions.</p>
                        </div>
                        <div className='min-w-[600px] max-w-[600px] max-lg:min-w-[300px] max-lg:max-w-[300px] max-md:max-w-full'>
                            <Accordion itemSize="xl" className='mb-5'>
                                <Accordion.Item value="item-1" className='bg-popo rounded-md'>
                                    <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-popo">
                                        <Accordion.Button className='text-goten'>
                                            <span className=' sm:text-[18px] text-[14px]'>How exactly does Vakeel help?</span>
                                            <ControlsPlus className="text-chichi text-moon-24 transition duration-200 moon-open:text-chichi" />
                                        </Accordion.Button>
                                    </Accordion.Header>
                                    <Accordion.Content className='bg-popo text-goten'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    </Accordion.Content>
                                </Accordion.Item>
                                <Accordion.Item value="item-2" className='bg-popo rounded-md'>
                                    <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-popo">
                                        <Accordion.Button className='text-goten'>
                                            <span className=' sm:text-[18px] text-[14px]'>How do I get assisted on Vakeel platform?</span>
                                            <ControlsPlus className="text-chichi text-moon-24 transition duration-200 moon-open:text-chichi" />
                                        </Accordion.Button>
                                    </Accordion.Header>
                                    <Accordion.Content className='bg-popo text-goten'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                                    </Accordion.Content>
                                </Accordion.Item>
                                <Accordion.Item value="item-3" className='bg-popo rounded-md'>
                                    <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-popo">
                                        <Accordion.Button className='text-goten'>
                                            <span className=' sm:text-[18px] text-[14px]'>What are the services offered by Vakeel?</span>
                                            <ControlsPlus className="text-chichi text-moon-24 transition duration-200 moon-open:text-chichi" />
                                        </Accordion.Button>
                                    </Accordion.Header>
                                    <Accordion.Content className='bg-popo text-goten'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    </Accordion.Content>
                                </Accordion.Item>
                                <Accordion.Item value="item-4" className='bg-popo rounded-md'>
                                    <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-popo">
                                        <Accordion.Button className='text-goten'>
                                            <span className=' sm:text-[18px] text-[14px]'>How do I use Vakeel in case of SOS?</span>
                                            <ControlsPlus className="text-chichi text-moon-24 transition duration-200 moon-open:text-chichi" />
                                        </Accordion.Button>
                                    </Accordion.Header>
                                    <Accordion.Content className='bg-popo text-goten'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    </Accordion.Content>
                                </Accordion.Item>
                                <Accordion.Item value="item-5" className='bg-popo rounded-md'>
                                    <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-popo">
                                        <Accordion.Button className='text-goten'>
                                            <span className=' sm:text-[18px] text-[14px]'>Which locations are Vakeel live in?</span>
                                            <ControlsPlus className="text-chichi text-moon-24 transition duration-200 moon-open:text-chichi" />
                                        </Accordion.Button>
                                    </Accordion.Header>
                                    <Accordion.Content className='bg-popo text-goten'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    </Accordion.Content>
                                </Accordion.Item>
                            </Accordion>
                            <p className='text-goten opacity-80 text-sm mb-0'>Still have questions? Email us at <Link className='font-bold'>info@vakeelgroup.in</Link></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-20'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='bg-popo ps-10 pt-0 pb-0 pr-0 rounded-xl max-md:p-[20px]'>
                        <div className='flex justify-between max-md:flex-col-reverse overflow-x-clip max-lg:items-end max-md:items-start'>
                            <div className='flex flex-col justify-center max-w-[600px] min-w-[300px] p-4 '>
                                <h2 class="xl:text-3xl sm:text-2xl text-xl font-medium text-chichi opacity-80 mb-4">Get in touch with us</h2>
                                <p class="lg:text-xl md:text-lg text-[16px] font-normal text-goten opacity-80 mb-5">From civil & criminal law to business contracts, our experts cover it all. Reach out today for convenient consultations</p>
                                <Button onClick={() => navigate('/user/update-profile')}
                                    size='xl' className='px-8 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500 w-fit'>Request a callback <GenericLightningBolt /></Button>
                            </div>
                            <div className='-mt-16 max-md:m-auto -me-6 max-md:-mt-[70px] max-md:border-b-2 max-md:w-full'>
                                <img className='max-w-[500px] min-w-[500px] max-lg:max-w-[400px] max-lg:min-w-[400px] max-md:max-w-[300px] max-md:min-w-[300px] max-md:mx-auto object-cover' src={vakeel_img}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='footer bg-popo py-16 max-md:py-5'>
           <div className='container mx-auto px-5 max-md:px-3'>
               <div className="flex gap-64 max-lg:gap-32 w-full pb-12 max-md:flex-col max-md:gap-10 max-md:pb-6">
                   <img className='max-w-[150px] min-w-[150px] h-auto max-h-auto min-h-auto object-contain max-md:max-w-[100px] max-md:min-w-[100px]' src={footer_logo} alt='#'></img>
                   <div className='flex justify-between w-full max-w-[800px] footer_links'>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>Company</h4>
                           <ul className='flex flex-col gap-4'>
                               <li><Link to='' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>About us</Link></li>
                               <li><Link to='' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Privacy policy</Link></li>
                               <li><Link to='' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Terms of use</Link></li>
                               <li><Link to='' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Contact us </Link></li>
                           </ul>
                       </div>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>For lawyers</h4>
                           <ul className='flex flex-col gap-4'>
                               <li><Link to='' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Register as a lawyers</Link></li>
                               <li><Link to='/disclaimer' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Disclaimer</Link></li>
                           </ul>
                       </div>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>Social links</h4>
                           <ul className='flex gap-5'>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={twitter} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={fb} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={insta} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={linkdn} alt=''></img></Link></li>
                           </ul>
                       </div>
                   </div>
               </div>
               <p className='text-goten opacity-75 text-sm pt-10 max-md:pt-6 mb-0 border-t'>© Copyright 2024 Vakeel Group. All rights reserved.</p>
           </div>
       </div> */}
        </div>
    )
}

export default Home;

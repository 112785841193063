import React from 'react'
import '@heathmont/moon-themes-tw/lib/moon.css';
// import VakeelRouting from './VakeelRouting';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import UserRouting from './UserRouting';
import LandingPage from './views/LandingPage';
import ResetPassword from './views/vakeel/ResetPassword';
import AppsmithAdmin from './views/admin/AppsmithAdmin';
import About from './views/About';
import Privacypolicy from './views/Privacy_policy';
import ContactUs from './views/ContactUs';
import TermOfUses from './views/TermOfUses';
import Disclaimer from './views/Disclaimer';
import FastrackAppointment from './views/FastrackAppointment';
import DelayAppointment from './views/DelayAppointment';
// user
import UserLogin from './views/user/Login';
import UserSignup from './views/user/Signup';
import UserSubmitCase from './views/user/SubmitCase';
import UserLawyers from './views/user/Lawyers';
import UserHome from './views/Home';
import UserAllCase from './views/user/AllCase';
import UserViewCase from './views/user/ViewCase';
import UserProfile from './views/user/Profile';
import UserCaseDetail from './views/user/CaseDetail';
import UserPrivateRoute from './components/PrivateRoute';
import UserPaymentHistory from './views/user/PaymentHistory';
import UserUpdateCase from './views/user/UpdateCase';
import UserUpdateProfile from './views/user/UpdateProfile';
import UserRegisterAnonymous from './views/user/RegisterAnonymous';
import UserAnonmousPayment from './views/user/AnonmousPayment';
// vakeel
import VakeelLogin from './views/vakeel/Login'
import VakeelSignup from './views/vakeel/Signup'
import VakeelForgotPassword from './views/vakeel/ForgotPassword'
import VakeelResetPassword from './views/vakeel/ResetPassword'
import VakeelProfile from './views/vakeel/Profile'
import VakeelDashboard from './views/vakeel/Dashboard'
import VakeelPrivateRoute from './components/PrivateRoute'
import VakeelConfirmation from './components/vakeel/Confirmation'
import VakeelCaseDetail from './views/vakeel/CaseDetail'
import VakeelPassbook from './views/vakeel/Passbook'
import VakeelAllCase from './views/vakeel/AllCase'
import BotTesting from './bot-testing/BotTesting';


const App = () => {
  // const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  // const is_anonymous = userInfo.is_anonymous
  // const payment_status = userInfo.payment_status
  // console.log(is_anonymous, "is_anonymous")
  // console.log(payment_status, "payment_status")
  return (
    <>
      {/* <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </BrowserRouter> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/testing-bot" element={<BotTesting />} />
          <Route path="reset" element={<ResetPassword />} />
          <Route path="about" element={<About />} />
          <Route path="privacy-policy" element={<Privacypolicy />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="term-of-uses" element={<TermOfUses />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="fast-track-appointment" element={<FastrackAppointment />} />
          <Route path="delay-appointment" element={<DelayAppointment />} />
          <Route path="admin" element={<AppsmithAdmin />} />
          {/* user routing */}
          <Route exact path="/user/login" element={<UserLogin />} />

          <Route path="user/signup" element={<UserSignup />} />
          <Route path="user/anonymous/signup" element={<UserRegisterAnonymous />} />


          <Route path="/user" element={
            <UserPrivateRoute role='user'>
              <UserHome />
            </UserPrivateRoute >
          } />
          <Route path="/user/profile" element={
            <UserPrivateRoute role='user'>
              <UserProfile />
            </UserPrivateRoute >
          } />
          <Route path="/user/payment/anonymous" element={
            <UserPrivateRoute role='user'>
              <UserAnonmousPayment />
            </UserPrivateRoute >
          } />
          <Route path="/user/update-profile" element={
            <UserPrivateRoute role='user'>
              <UserUpdateProfile />
            </UserPrivateRoute >
          } />
          <Route path="/user/register-case" element={
            <UserPrivateRoute role='user'>
              <UserSubmitCase />
            </UserPrivateRoute >
          } />
          <Route path="/user/all-cases" element={
            <UserPrivateRoute role='user'>
              <UserAllCase />
            </UserPrivateRoute >
          } />
          <Route path="/user/cases-details/:id" element={
            <UserPrivateRoute role='user'>
              <UserCaseDetail />
            </UserPrivateRoute >
          } />
          <Route path="/user/view-case/:id" element={
            <UserPrivateRoute role='user'>
              <UserViewCase />
            </UserPrivateRoute >
          } />
          <Route path="/user/update-case/:id" element={
            <UserPrivateRoute role='user'>
              <UserUpdateCase />
            </UserPrivateRoute >
          } />
          <Route path="/user/lawyers" element={
            <UserPrivateRoute role='user'>
              <UserLawyers />
            </UserPrivateRoute >
          } />
          <Route path="/user/payment-history" element={
            <UserPrivateRoute role='user'>
              <UserPaymentHistory />
            </UserPrivateRoute >
          } />
          {/* vakeel */}
          <Route exact path="/vakeel/login" element={<VakeelLogin />} />
          <Route path="/signup" element={<VakeelSignup />} />
          <Route path="/forgot-password" element={<VakeelForgotPassword />} />
          <Route path="/reset-password/:id" element={<VakeelResetPassword />} />
          <Route path="/profile" element={
            <VakeelPrivateRoute role='vakeel'>
              <VakeelProfile />
            </VakeelPrivateRoute>
          } />
          <Route path="/profile-submitted" element={
            <VakeelPrivateRoute role='vakeel'>
              <VakeelConfirmation />
            </VakeelPrivateRoute>
          } />
          <Route path="/dashboard" element={
            <VakeelPrivateRoute role='vakeel'>
              <VakeelDashboard />
            </VakeelPrivateRoute>
          } />
          <Route path="/case-detail/:id" element={
            <VakeelPrivateRoute role='vakeel'>
              <VakeelCaseDetail />
            </VakeelPrivateRoute>
          } />
          <Route path="/all-case" element={
            <VakeelPrivateRoute role='vakeel'>
              <VakeelAllCase />
            </VakeelPrivateRoute>
          } />
          <Route path="/passbook" element={
            <VakeelPrivateRoute role='vakeel'>
              <VakeelPassbook />
            </VakeelPrivateRoute>
          } />
        </Routes>
        {/* <UserRouting /> */}
        {/* <VakeelRouting /> */}

      </BrowserRouter>
      {/* <Route path="*" element={(<><h1 className='text-white text-center' style={{ marginTop: '100px' }}>404 page.</h1></>)} /> */}
      <ToastContainer hideProgressBar="true" position='bottom-right' />
    </>
  )
}

export default App


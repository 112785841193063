import React, { useCallback, useEffect, useState } from 'react'
import { Input, Button } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import loginSchema from '../../validation-schemas/vakeel/login-schema';
import { sendLoginOtp, verifyOtp } from '../../services/vakeel/auth-service';
import ButtonLoading from '../../components/loading/ButtonLoading';
import { AuthCode } from '@heathmont/moon-core-tw';

import { ArrowsLeft } from '@heathmont/moon-icons-tw';
import { userProfile, } from '../../services/user/auth-service';
import { latestCase } from '../../services/user/case-service';


const Login = () => {
    const navigate = useNavigate();
    const userInfo = useCallback(() => localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {}, [])
    useEffect(() => {
        if (Object.keys(userInfo).length > 0) {
            if (userInfo.role === 'user') {
                navigate('/user')
            } else if (userInfo.role === 'vakeel') {
                navigate('/profile')
            }
        }
    }, [navigate, userInfo])

    const [loading, setLoading] = useState(false);

    const getLatestCase = async (user_id) => {
        const response = await latestCase(user_id)
        if (response.status === 200) {
            localStorage.setItem('currentCaseId', response.data.case_detail_id)
            localStorage.setItem('caseType', response.data.incident_type);
            return response.data.case_detail_id
        } else if (response.status === 404) {
            return 0
        } else {
            console.log('err')
        }
    }

    const [isOtpSent, setIsOtpSent] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            phoneNumber: ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            const reqBody = {
                email: values.email,
                phoneNumber: values.phoneNumber,
                role_id: 3
                // isSocialLogin: false,
                // role_name: 'user'
            }
            setLoading(true)
            // console.log(reqBody)
            const response = await sendLoginOtp(reqBody);
            if (response.status === 200) {
                toast.success("Otp send successfully.", { autoClose: 1000 });
                setLoading(false)
                setIsOtpSent(true)
                otpCountDown()
                // localStorage.setItem('userInfo', JSON.stringify(response.data));
                // const userProfiles = await userProfile(response.data.user_id);
                // localStorage.setItem('userProfileImg', userProfiles.data.image_url || '');
                // const caseId = await getLatestCase(response.data.user_id);
                // setTimeout(() => {
                //     setLoading(false);
                //     toast.success("Login successfully.", { autoClose: 1000 });
                //     console.log('caseId', caseId)
                //     setTimeout(() => {
                //         if (caseId === 0) {
                //             navigate('/user/register-case')
                //         } else {
                //             navigate(`/user/cases-details/${caseId}`)
                //         }
                //     }, 1000);
                // }, 2000)
            } else if (response.status === 401) {
                setTimeout(() => {
                    toast.error(response.data.message, { autoClose: 3000 });
                    setLoading(false)
                }, 2000);
            }
        },
    });
    const [otpValue, setOtpValue] = useState('');
    const handleOnChangeOtp = (res) => {
        setOtpValue(res);
    };
    const handleVerifyOtp = async () => {
        setLoading(true);
        const reqBody = {
            "email": formik.values.email,
            "phoneNumber": formik.values.phoneNumber,
            "otp": otpValue,
            "isSocialLogin": false,
            "role_name": "user"
        }
        // console.log(reqBody)
        const response = await verifyOtp(reqBody);
        // console.log(response?.data, "login response")
        // console.log(response?.data?.is_anonymous, "login response")
        if (response?.status === 200) {
            const isAnonymous = response?.data?.is_anonymous;
            const paymentStatus = response?.data?.payment_status;
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            const userProfiles = await userProfile(response.data.user_id);
            localStorage.setItem('userProfileImg', userProfiles.data.image_url || '');
            const caseId = await getLatestCase(response.data.user_id);
            setTimeout(() => {
                toast.success("Otp verified successfully.", { autoClose: 3000 });
                setLoading(false);
                if (isAnonymous) {
                    // console.log("iam anonymois", paymentStatus)
                    setTimeout(() => {
                        if (paymentStatus) {
                            navigate('/user/payment-history')
                        } else {
                            navigate("/user/payment/anonymous", { replace: true })
                            // navigate('/user/payment/anonymous');
                        }
                    }, 500);
                } else {
                    setTimeout(() => {
                        if (caseId === 0) {
                            navigate('/user/update-profile')
                        } else {
                            navigate(`/user/cases-details/${caseId}`)
                        }
                    }, 500);
                }
            }, 1000)
        } else if (response.status === 401) {
            setLoading(false);
            toast.error(response.data.message, { autoClose: 3000 });
        }
    }

    // !
    const [timer, setTimer] = useState(false);
    const [resendTime, setResendTime] = useState('5:00');

    const otpCountDown = () => {
        setTimer(true);
        let time = 299;
        const t = setInterval(() => {
            const minutes = Math.floor(time / 60);
            let seconds = time % 60;
            seconds = seconds < 10 ? '0' + seconds : seconds;
            const result = `${minutes}:${seconds}`;
            setResendTime(result);
            time--;
            if (time <= 0) {    
                clearInterval(t);
                setTimer(false);
                setResendTime('5:00');
            }
        }, 1000);
    };
    return (
        <div className='size-full flex min-h-screen'>
            <div className="min-h-screen min-h-ful w-2/5 max-lg:hidden max-xl:block">
                <div className="wrap_bg bg-cover bg-center w-full h-full bg-no-repeat"></div>
            </div>
            <div className='min-h-screen size-full flex items-center justify-center w-3/5 flex-col gap-6 px-4 max-lg:w-full py-4 relative'>
                <Link to="/" className='text-lg visited:text-chichi font-medium text-chichi opacity-70 hover:text-chichi hover:opacity-100 focus:outline-none mx-auto w-fit flex items-center gap-2
        absolute left-0 top-0 max-w-fit pt-[15px] ps-5'>
                    <ArrowsLeft className='w-[25px] h-[25px]' />  Back </Link>
                <img className='' src={header_logo} alt=''></img>
                <div className='w-full max-w-xl py-8 px-10 flex flex-col justify-center bg-popo rounded-lg max-lg:py-4 max-lg:px-5'>
                    <h2 className='lg:text-2xl md:text-xl sm:text-xl text-xl font-bold mb-7 max-lg:mb-4 text-goten'>Login in to your account</h2>
                    {/*//! login email */}
                    {!isOtpSent ?
                        <form onSubmit={(e) => {
                            formik.handleSubmit(e);
                            e.preventDefault()
                        }}>
                            <div className="w-full mt-1">
                                <div className='mb-3 relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="email" size="lg" readOnly={loading ? true : false} placeholder="Enter Email Address" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <small className="text-danger validationError">{formik.errors.email}</small>
                                    ) : ''}
                                </div>
                                <div className="divide_line my-6 relative border-t-2"><span className='absolute -top-3.5 bg-popo left-1/2 px-2.5 -ml-3.5 text-goten'>Or</span></div>
                                <div className='mb-3 relative pb-3'>
                                    <Input className='rounded-md h-14 leading-[3.5rem] text-goten' type="text" size="lg" readOnly={loading ? true : false} placeholder="Enter phone number" name="phoneNumber" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.phoneNumber} />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <small className="text-danger validationError">{formik.errors.phoneNumber}</small>
                                    ) : ''}
                                </div>
                                <Button type='submit' className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg"
                                    disabled={!formik.isValid || loading}
                                >
                                    {loading &&
                                        <ButtonLoading />
                                    }
                                    Login
                                </Button>
                            </div>
                        </form>
                        :
                        (<>
                            <div className='mx-auto'>
                                <br />
                                <div className='mx-auto'>
                                    <AuthCode onChange={handleOnChangeOtp} />
                                </div>
                                <br />
                                <Button className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' type="submit" size="lg"
                                    onClick={handleVerifyOtp}
                                    disabled={otpValue?.length <= 5 || loading}
                                >
                                    {/* {loading &&
                                    <ButtonLoading />
                                } */}
                                    Verify
                                </Button>
                                <br />
                                {timer ?
                                    <div className='otp-expiry'>
                                        <p className='text-white'>OTP expires in {resendTime}</p>
                                    </div> :
                                    <div className='otp-expiry'>
                                        <Link to="#" onClick={(e) => formik.handleSubmit(e)} className="font-medium text-chichi opacity-80 hover:opacity-100">
                                            Resend OTP
                                        </Link>
                                    </div>
                                }
                                <br />
                            </div>
                        </>)
                    }
                    <br />
                    {!isOtpSent && <div className="w-full mt-1">
                        <div className="divide_line my-6 relative border-t-2"><span className='absolute -top-3.5 bg-popo left-1/2 px-2.5 -ml-3.5 text-goten'>Or</span></div>
                        <div className='mb-3 relative pb-3'>
                            <Button onClick={() => navigate("/user/anonymous/signup")} type='submit' className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg">
                                Register As Anonymous User
                            </Button>
                        </div>
                    </div>}
                    <div className="mt-4 text-center flex items-center justify-center flex-col gap-2 text-md">
                        {loading ? (
                            <span className="font-medium text-chichi opacity-80 hover:opacity-100" style={{ color: 'grey', pointerEvents: 'none', textDecoration: 'none' }}>
                                Forgot Password?
                            </span>
                        ) : (
                            <Link to="/forgot-password" className="font-medium text-chichi opacity-80 hover:opacity-100">
                                Forgot Password?
                            </Link>
                        )}
                        <p>
                            {loading ? (
                                <span className='text-goten'>
                                    Don't have an account? <span className="font-medium text-chichi opacity-80 hover:opacity-100" style={{ color: 'grey', pointerEvents: 'none', textDecoration: 'none' }}>Signup</span>
                                </span>
                            ) : (
                                <span className='text-goten'>
                                    Don't have an account? <Link to="/user/signup" className="font-medium text-chichi opacity-80 hover:opacity-100">Signup</Link>
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' />
        </div>
    )
}
export default Login;
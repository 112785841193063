/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react'
// import Navbar from './Navbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ControlsChevronLeft, ControlsChevronRight, ControlsClose, ControlsPlus, DevicesPhone, FilesFolderOpen, GenericCheckAlternative, GenericDownload, MailLink, TimeClock } from '@heathmont/moon-icons-tw'
import userimg from '../../images/pp_img.png'
import { Button, Drawer, IconButton, Modal, Textarea } from '@heathmont/moon-core-tw'
import { Input } from '@heathmont/moon-core-tw';
import vakeel_img from '../../images/vakeel_img.jpg'
import userIcon from '../../images/usericon_l.svg'
import Navbar from '../../components/user/Navbar'
import { allCases, casesDetails, getEvidenceFile, reqReschedule ,uplodaFile} from '../../services/user/case-service'
import { getAssignedLawyer, getLawyers } from '../../services/user/lawyers-service'
import { baseURLApi, baseURLImg } from '../../config'
import { dateFormatter, formateDate, getFormattedTime } from '../../utils/date-formatter'
import Comments from '../comments/Comments'
import { toast } from 'react-toastify'
import axios from 'axios'
import ButtonLoading from '../../components/loading/ButtonLoading'
// import { io } from 'socket.io-client'

const CaseDetail = () => {
    // const socket = useMemo(() => io('http://localhost:5800'), []);
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const params = useParams();
    const [caseInfo, setCaseInfo] = useState({})
    const [evidence, setEvidence] = useState([]);
    const [urls, setUrls] = useState([]);
    const [showSupport, setShowSupport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assignedLawyerInfo, setAssignedLawyerInfo] = useState({})

    const getCaseDetails = async () => {
        setEvidence([])
        const response = await casesDetails(userInfo?.user_id, params.id);
        if (response.status === 200) {
            setCaseInfo(response.data);
            console.log('caseInfo', response.data);
            if (response.data.evidence_url != null) {
                // setEvidence([...response.data.evidence_url])
                const arr = [];
                response.data.evidence_url.forEach((x) => {
                    arr.push(...x)
                })
                setEvidence(arr)
                console.log(arr)
            }
        }
    }

    useEffect(() => {
        getCaseDetails()
    }, [params.id])

    const [lawyer, setLawyers] = useState([]);
    const getAllLawyers = async () => {
        const response = await getLawyers();
        if (response.status === 200) {
            setLawyers(response.data[0])
        }
    }

    useEffect(() => {
        getAllLawyers()
    }, []);

    const assignedLawyer = async () => {
        const response = await getAssignedLawyer(userInfo?.user_id, params.id);
        if (response.status === 200) {
            console.log('assignedLawyer', response.data)
            setAssignedLawyerInfo(response.data)
        } else if (response.status === 404) {
            setAssignedLawyerInfo({})
        }
    }

    useEffect(() => {
        assignedLawyer()
    }, [params.id]);

    const [isStartOpen, setIsStartOpen] = useState(true);
    const handleStartClick = () => setIsStartOpen(true);


    const [cases, setCases] = useState([]);

    const getAllCases = async () => {
        const response = await allCases(userInfo?.user_id);
        if (response.status === 200) {
            const data = response.data
            // setCases(data);
            setCases(data.filter((x) => x.case_detail_id != params.id));
            // console.log('cases', response.data)
        }
    };

    useEffect(() => {
        getAllCases();
    }, [params.id]);

    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    const [isOpenFiles, setIsOpenFiles] = useState(false);
    const closeModalFiles = () => {
        setIsOpenFiles(false);
        // setEvidence([])
    };
    const openModalFiles = () => setIsOpenFiles(true);

    const [isOpenAddFiles, setIsOpenAddFiles] = useState(false);
    const closeModalAddFiles = () => {
        setIsOpenAddFiles(false);
        // setEvidence([])
    };
    const openModalAddFiles = () => setIsOpenAddFiles(true);

    // const [isOpenRemark, setIsOpenRemark] = useState(false);
    // const closeModalRemark = () => setIsOpenRemark(false);
    // const openModalRemark = () => setIsOpenRemark(true);


    const checkVakeelAssigned = () => {
        if (caseInfo?.appointment_id !== '' && caseInfo?.appointment_id != null) {
            return true
        } else {
            return false
        }
    }
    const getFile = async (case_id, files) => {
        try {
            //   const formData = new FormData();
            //   formData.append('image', file);
            const reqBody = {
                file_name: files,
                caseId: case_id,
                isFetchUrl: true

            }
            // Make a POST request to your Node.js server for file upload
            const response = await getEvidenceFile(reqBody)//await axios.post(`${baseURLApi}user/get-file`, reqBody);
            if (response.status == 200) {
                setUrls(response.data)
                openModalFiles();
            }
            console.log('File fetched successfully', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };






    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        // setFile(e.target.files);
        setFiles([...e.target.files]);
        console.log('file', e.target.files);
        // let data = 
    };

    const handleUpload = async () => {
        try {
            if (files.length > 0) {
                const formData = new FormData();
                files.forEach((file, index) => {
                    formData.append('image', file);
                });
                setLoading(true)
                // Make a POST request to your Node.js server for file upload
                const response = await uplodaFile(caseInfo?.case_detail_id, formData)//axios.post(`${baseURLApi}user/case-data/${caseInfo?.case_detail_id}`, formData);
                if (response.status === 200) {
                    setLoading(false)
                    console.log('filename', response.data.data)
                    const arr = [];

                    response.data.data.forEach(async (x) => {
                        arr.push(x);
                        // console.log('x', x)
                    })
                    await getFiles(arr)
                    console.log('arr', arr);
                    closeModalAddFiles()
                }
            }

            console.log('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getFiles = async (files) => {
        try {
            //   const formData = new FormData();
            //   formData.append('image', file);
            const reqBody = {
                file_name: files,
                caseId: caseInfo?.case_detail_id,
                isFetchUrl: false

            }
            // Make a POST request to your Node.js server for file upload
            const response = await getEvidenceFile(reqBody)//await axios.post(`${baseURLApi}user/get-file`, reqBody);
            getCaseDetails()
            console.log('File fetched successfully', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const ReqReschedule = async () => {
        const reqBody = {
            user: `${userInfo.first_name} ${userInfo.last_name}`,
            user_id: assignedLawyerInfo.vakeel_user_id
        }

        const response = await reqReschedule(reqBody);
        if (response.status == 200) {
            toast.success('Your request has been sent to lawyer please wait for his approval.')
        }
    }
    // checkVakeelAssigned()
    return (
        <div >
            <Navbar />
            <div className='mt-24 max-md:mt-20' >
                <div className='mx-auto px-5 max-md:px-3'>

                    <Link onClick={handleStartClick} type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none">
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </Link>


                    <Drawer open={isStartOpen} setOpen={setIsStartOpen} className='w-64 z-10 md:hidden'>
                        <Drawer.Panel className='w-64 z-10' position="start">
                            <div className='flex flex-col  h-full fixed top-0 left-0 z-10 pt-24 px-6 mx-auto  w-64' aria-label="Sidebar" style={{ background: '#34352E' }}>
                                {/* <div className=' border-b pb-4 mb-4 flex items-center justify-between'> */}
                                {/* <Link to='/user/all-cases' className='text-[18px] font-normal text-black gap-2 flex items-center focus:outline-none'> <ControlsChevronLeft className='text-[25px]' />All Cases</Link> */}
                                {/* <IconButton icon={<ControlsPlus />} title='Add new case' onClick={() => navigate('/user/register-case')} /> */}
                                {/* <Button size='lg' onClick={() => navigate('/user/register-case')}>Add New Case</Button> */}
                                {/* </div> */}
                                {
                                    cases.length >= 1 ? '' :
                                        <h4 className='mb-0 text-[16px] text-zinc-500'>Cases not found</h4>

                                }
                                <ul className='my-4' style={{flexGrow:1}}>
                                    {cases && cases.map((x, i) => {
                                        return (
                                            <li className='mb-4 text-white' onClick={() => {
                                                localStorage.setItem('currentCaseId', x?.case_detail_id)
                                                localStorage.setItem('caseType', x?.incident_type);
                                                navigate(`/user/cases-details/${x?.case_detail_id}`)
                                            }}>
                                                <Link className='flex gap-3'>#Case-{x?.case_detail_id} {x?.case_name}</Link>
                                                {/* <Link className='flex gap-3'><span className='max-w-[26px] min-w-[26px] max-h-[25px] min-h-[25px] rounded-[5px] flex align-middle justify-center border capitalize' >{x?.case_name == null && x?.case_name == '' ? '' : x?.case_name.charAt(0)}</span>{x?.case_name}</Link> */}
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className='border-t pt-4 mb-4 flex flex-col gap-3 items-center justify-between'>
                                    <Button size='lg' className='w-full rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => navigate('/user/register-case')}>Add New Case</Button>
                                    <Button onClick={() =>
                                        Tawk_API.toggle()
                                    } size='lg' className='w-full py-5 px-10 border bg-transparent hover:border'>Support</Button>
                                </div>
                            </div>
                        </Drawer.Panel>
                    </Drawer>
                    <div className='flex gap-5'>
                        <div className='h-full fixed top-0 left-0 z-10 pt-24 px-6 bg-popo mx-auto rounded-lg w-64 max-md:hidden' aria-label="Sidebar">

                            <h4 className='mb-0 text-[16px] text-white opacity-75'>Other cases</h4>
                            {
                                cases.length >= 1 ? '' :
                                    <h4 className='mb-0 text-[16px] text-white opacity-75'>Cases not found</h4>

                            }
                            <ul className='my-4 sidebar_scroll'>
                                {cases && cases.map((x, i) => {
                                    return (
                                        <li className='mb-4' onClick={() => {
                                            localStorage.setItem('currentCaseId', x?.case_detail_id)
                                            localStorage.setItem('caseType', x?.incident_type);
                                            navigate(`/user/cases-details/${x?.case_detail_id}`)
                                        }}>
                                            <Link className='flex gap-3 text-white opacity-75 hover:opacity-100'>#Case-{x?.case_detail_id} {x?.case_name}</Link>
                                            {/* <Link className='flex gap-3'><span className='max-w-[26px] min-w-[26px] max-h-[25px] min-h-[25px] rounded-[5px] flex align-middle justify-center border capitalize' >{x.case_name.charAt(0)}</span>{x.case_name}</Link> */}
                                        </li>
                                    )
                                })
                                }
                            </ul>
                            <div className='border-t pt-4 mb-4 flex flex-col gap-3 items-center justify-between'>
                                {/* <Link to='/user/all-cases' className='text-[18px] font-normal text-black gap-2 flex items-center focus:outline-none'> <ControlsChevronLeft className='text-[25px]' />All Cases</Link> */}
                                {/* <IconButton icon={<ControlsPlus />} title='Add new case' onClick={() => navigate('/user/register-case')} /> */}
                                <Button size='lg' className='w-full rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => navigate('/user/register-case')}>Add New Case</Button>
                                {/* <a href="javascript:void(Tawk_API.toggle())"> */}
                                <Button onClick={() =>
                                    Tawk_API.toggle()
                                } size='lg' className='w-full py-5 px-10 border bg-transparent hover:border'>Support</Button>
                                {/* </a> */}
                            </div>
                        </div>
                        {Object.keys(caseInfo).length > 0 &&
                            <div className='flex gap-5 max-xl:flex-col ml-64 max-md:ml-0 w-full'  >
                                <div className=' w-full flex flex-col mx-auto relative min-w-[570px] max-xl:min-w-full user_middle_scroller'>
                                    {/* <div className={`py-6 px-6 max-md:py-4 max-md:px-4 mb-5 bg-white rounded-lg`}> */}
                                    <div className={`py-6 px-6 max-md:py-4 max-md:px-4 mb-5 bg-popo rounded-lg ${checkVakeelAssigned() ? '' : 'd-none'}`}>
                                        <h4 class="mb-0 lg:text-[25px] md:text-[22px] sm:text-[20px] text-[18px] font-medium text-white">{userInfo?.first_name} {userInfo?.last_name}</h4>

                                        <div className='flex gap-5 border-t pt-5 mt-4 items-center'>
                                            <div class="min-w-[90px] min-h-[90px]">
                                                <img class="w-full h-full max-w-[90px] max-h-[90px] rounded-lg object-cover" src={assignedLawyerInfo?.profile_image_url ? `${baseURLImg}/${assignedLawyerInfo?.profile_image_url}` : userIcon} alt='#' />
                                            </div>
                                            <div>
                                                <h2 className='lg:text-[22px] md:text-xl sm:text-2xl text-xl font-semibold text-white mb-1'>Adv. {assignedLawyerInfo?.vakeel_first_name} {assignedLawyerInfo?.vakeel_last_name}</h2>
                                                {/* <span className='w-full text-[16px] font-normal text-black block mb-1'>4.7 (289 reviews)</span> */}
                                                <div className='w-fit'>
                                                    <ul className='flex gap-8 max-md:gap-4 items-center'>
                                                        <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>{assignedLawyerInfo?.experience_years} Years </li>
                                                        <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>English & Hindi</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className=' bg-popo rounded-lg'>
                                        <div className='py-6 px-6 max-md:py-4 max-md:px-4'>
                                            <div className='pb-2'>
                                                <p className='text-[20px] font-medium text-white capitalize'>#Case-{caseInfo?.case_detail_id} {caseInfo?.case_name}</p>
                                                <h4 className='mb-0 lg:text-[25px] md:text-[22px] sm:text-[20px] text-[20px] font-medium text-white capitalize'>Case Brief {` (${caseInfo?.case_name})`}</h4>
                                                <ul className='my-4'>
                                                    {/* <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                        <span className='min-w-[220px] mb-0 text-[16px] text-zinc-500'>Status :</span>
                                        <p className='w-full mb-0 text-[16px] font-normal text-black'>{caseInfo.status}</p>
                                    </li> */}
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Consultation type :</span>
                                                        <p className='w-full mb-0 text-[16px] font-normal text-white'>{caseInfo?.consultation_type} </p>
                                                    </li>
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Type of Incident :</span>
                                                        <p className='w-full mb-0 text-[16px] font-normal text-white'>{caseInfo?.incident_type} </p>
                                                    </li>
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Incident occurrence date :</span>
                                                        <p className='w-full mb-0 text-[16px] font-normal text-white'> {dateFormatter(caseInfo?.incident_date)} </p>
                                                        {/* <p className='w-full mb-0 text-[16px] font-normal text-black'> 31st November 2023 </p> */}
                                                    </li>
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Incident Location :</span>
                                                        <p className='w-full t-lg text-[16px] text-white'>{caseInfo?.incident_location}</p>
                                                    </li>
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Witness details :</span>
                                                        <p className='w-full mb-0 text-[16px] font-normal text-white'>{caseInfo?.witness_details}</p>
                                                    </li>
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Suspect detail :</span>
                                                        <p className='w-full mb-0 text-[16px] font-normal text-white'> {caseInfo?.suspect_details} </p>
                                                    </li>
                                                    <li className='flex items-baseline gap-1 mb-4 max-lg:flex-col'>
                                                        <span className='min-w-[220px] mb-0 text-[16px] text-white opacity-75'>Damages in the incident :</span>
                                                        <p className='w-full mb-0 text-[16px] font-normal text-white'> {caseInfo?.damages_desc}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full py-5 px-5 bg-popo mx-auto rounded-lg max-md:py-4 max-md:px-4 shadow-[0px_-5.338px_21.352px_0px_rgba(0,0,0,0.16)] max-lg:py-3 max-lg:px-3'>
                                        <div className='flex justify-between gap-3 max-sm:gap-1 max-sm:flex-col'>
                                            <div className='flex gap-2'>
                                                {/* <a href={caseInfo?.evidence_url} target="_blank"> */}
                                                <Button onClick={() => {
                                                    // openModalFiles()
                                                    let arr = []
                                                    evidence.forEach((x) => {
                                                        arr.push(x.name);
                                                    })
                                                    // console.log('arr', arr)
                                                    getFile(caseInfo?.case_detail_id, arr)
                                                }} disabled={evidence.length == 0} size="lg" className='py-5 px-10 border bg-transparent hover:border text-white' iconLeft={<FilesFolderOpen />} variant="outline">Shared Folder</Button>
                                                {/* <Button size="lg" className='py-5 px-10 border bg-transparent hover:border text-white' onClick={openModalAddFiles}>Add more files</Button> */}
                                                {/* <Button onClick={openModalFiles} disabled={evidence.length == 0} size="lg" className='py-5 px-10 border bg-transparent hover:border text-white' iconLeft={<FilesFolderOpen />} variant="outline">Shared Folder</Button> */}
                                                {/* </a> */}
                                                {/* <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={()=>navigate(`/user/update-case/${caseInfo?.case_detail_id}`)}>Edit Case</Button> */}
                                            </div>
                                            <div className='flex gap-2 max-sm:justify-between'>
                                                {checkVakeelAssigned() ?
                                                    ''
                                                    :   
                                                    <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => {
                                                        navigate('/user/lawyers')
                                                    }}>Select a lawyer</Button>
                                                }
                                                {/* {checkVakeelAssigned() ?
                                                    <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' disabled={!caseInfo?.is_confirm} onClick={() => {
                                                        if (caseInfo?.is_confirm) {
                                                            ReqReschedule()
                                                            // toast.success('Your request has been sent to lawyer please wait for his approval.')

                                                        }
                                                    }}>Request reschedule</Button> 
                                                    :
                                                    <Button size="lg" className='rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => {
                                                        navigate('/user/lawyers')
                                                    }}>Select a lawyer</Button>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='w-full max-w-[450px] max-xl:max-w-full'> */}
                                <div className={` w-full max-w-[450px] max-xl:max-w-full user_middle_scroller ${checkVakeelAssigned() ? '' : 'd-none'}`}>
                                    <div className='w-full py-6 px-6 max-md:py-4 max-md:px-4 bg-popo rounded-lg mb-5'>
                                        <h4 className='mb-1 lg:text-[20px] md:text-[20px] sm:text-[18px] text-[18px] font-semibold text-white'>Booking details</h4>
                                        <div className='flex gap-3 items-center'>
                                            {caseInfo?.is_confirm ?
                                                <span className="rounded_icon min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] flex items-center justify-center"><GenericCheckAlternative className='w-[22px] h-[22px]' /></span> : ''}
                                            <h5 className='w-full mb-0 text-[16px] font-medium text-white'>Consultation {caseInfo?.is_confirm ? 'confirmed' : 'pending'}</h5>
                                        </div>
                                        <div className='border-t border-b py-3 my-2 border-dashed'>
                                            <span className='w-full mb-0 text-[16px] font-medium text-white'>Amount Paid ₹{caseInfo.amount}/-</span>
                                        </div>
                                        <ul className={`flex flex-col gap-2 ${caseInfo?.is_confirm ? '' : 'd-none'}`}>
                                            <li className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white'><TimeClock className='max-w-[20px] min-w-[20px] text-[20px]' /> {getFormattedTime(caseInfo?.start_time)}-{getFormattedTime(caseInfo?.end_time)} | {formateDate(caseInfo?.appointment_date)}, {new Date(caseInfo?.appointment_date).getFullYear()}</li>
                                            <li><Link to={caseInfo?.meet_link} target="_blank" className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-chichi opacity-75 underline underline-offset-1 hover:opacity-100'><MailLink className='max-w-[20px] min-w-[20px] text-[20px]' />{caseInfo?.meet_link}</Link> </li>
                                            {/* <li><Link className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white hover:text-indigo-900'><DevicesPhone className='max-w-[20px] min-w-[20px] text-[20px]' /> +91-9356624532</Link> </li> */}
                                        </ul>
                                    </div>
                                    {

                                        (assignedLawyerInfo?.vakeel_user_id !== undefined) &&
                                        <Comments scroll='' receiverId={assignedLawyerInfo?.vakeel_user_id} room={`userId${userInfo?.user_id}-lawyerId${assignedLawyerInfo?.vakeel_user_id}`} is_confirm={caseInfo?.is_confirm} img_url={''}  case_detail_id={caseInfo?.case_detail_id}/>
                                    }
                                </div>

                            </div>
                        }

                    </div>

                </div>
            </div>

            <Modal open={isOpen} onClose={closeModal} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            Reschedule
                        </h3>
                        <div className="">
                            <IconButton onClick={closeModal} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className='mb-3'>
                                <label className='block mb-2 text-white text-[16px] font-normal '>Appointment Date</label>
                                <Input className='rounded-md' type="date" size="md" placeholder="Appointment Date" />
                            </div>
                            <div className='flex gap-3 w-full'>
                                <div className='mb-3 w-full'>
                                    <label className='block mb-2 text-white text-[16px] font-normal '>Start Time</label>
                                    <Input className='rounded-md' type="time" size="md" placeholder="Start" />
                                </div>
                                <div className='mb-3 w-full'>
                                    <label className='block mb-2 text-white text-[16px] font-normal '>End Time</label>
                                    <Input className='rounded-md' type="time" size="md" placeholder="Start" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 border-t-2 border-beerus flex justify-end">
                        <Button onClick={closeModal} size='lg' className='text-md visited:text-chichi font-medium text-chichi opacity-70 hover:text-chichi hover:opacity-100 focus:outline-none w-fit
                    flex items-center gap-2'>Submit</Button>
                    </div>
                </Modal.Panel>
            </Modal>

            {/* files */}
            <Modal open={isOpenFiles} onClose={closeModalFiles} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            Uploaded Files
                        </h3>
                        {/* <Button size='lg' onClick={()=>{
                            setIsOpenFiles(false)
                            setIsOpenAddFiles(true)
                        }}>+ Add more</Button> */}
                        <div className="">
                            {/* onClick={closeModal} */}
                            <IconButton onClick={closeModalFiles} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <ul className="p-4 modal_scroll">
                        {caseInfo && urls?.length > 0 ? urls.map((x, index) => {
                            return (
                                <li className='mb-2' key={index}>
                                    <a href={x.url} target='_blank' className='text-md visited:text-white font-medium text-white opacity-70 hover:text-white hover:opacity-100 focus:outline-none w-fit
                    flex items-center gap-2'># {x.name}</a>
                                </li>

                            )
                        }) : ''
                        }
                    </ul>
                    <div className="p-4 border-t-2 border-beerus flex justify-end">
                        <Button onClick={() => {
                            setIsOpenFiles(false)
                            setIsOpenAddFiles(true)
                        }} className='bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size='lg'>+ Add more</Button>
                    </div>
                </Modal.Panel>
            </Modal>

            {/* more files */}
            <Modal open={isOpenAddFiles} onClose={closeModalAddFiles} className="bg-popo">
                <Modal.Backdrop />
                <Modal.Panel className='max-w-[700px] bg-popo'>
                    <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                        <h3 className="text-moon-18 text-white font-medium">
                            Upload Files
                        </h3>
                        <div className="">
                            {/* onClick={closeModal} */}
                            <IconButton onClick={closeModalAddFiles} className='bg-black' icon={<ControlsClose />} />
                        </div>
                    </div>
                    <div className="p-4 modal_scroll">
                        <form>
                            <input type='file' className='w-full p-4' multiple onChange={handleFileChange}></input>
                        </form>
                    </div>
                    <div className="p-4 border-t-2 border-beerus flex justify-end">
                        <Button onClick={handleUpload} className='bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size='lg'>{loading &&
                            <ButtonLoading />
                        }Submit</Button>
                    </div>
                </Modal.Panel>
            </Modal>
        </div>
    )
}

export default CaseDetail

import React, { useEffect, useState } from 'react'
// import Navbar from './Navbar'
import vakeel_img from '../../images/vakeel_img.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowsLeft, ControlsChevronRight, ControlsClose, DevicesPhone, GenericCheckAlternative, MailLink, TimeClock } from '@heathmont/moon-icons-tw'
import Navbar from '../../components/user/Navbar'
import { latestCase, passbook } from '../../services/user/case-service'
import { formateDate, getFormattedTime } from '../../utils/date-formatter'
import { baseURLImg } from '../../config'

const PaymentHistory = () => {
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(userInfo, "userInfo")
    const [bookingData, setBookingData] = useState([])

    const getPassbookData = async () => {
        const response = await passbook(userInfo.user_id);
        if (response.status === 200) {
            setBookingData(response.data);
            // console.log('bookingData', response.data)
        }
    }
    // console.log(bookingData, "bookingData")

    const [caseId, setCaseId] = useState(0)
    const getLatestCase = async (user_id) => {
        const response = await latestCase(user_id)
        if (response.status === 200) {
            // console.log('caseData123',  response.data);
            localStorage.setItem('caseType', response.data.incident_type)
            setCaseId(response.data?.case_detail_id)
        } else if (response.status === 404) {
            setCaseId(0)
        } else {
            console.log('err')
        }
    }

    useEffect(() => {
        getLatestCase(userInfo?.user_id)
        getPassbookData()
    }, []);

    const ConsultationStatus = (apDate) => {
        // Get the current date and time
        const currentDate = new Date();

        // Check if the target date has passed
        const hasDatePassed = apDate < currentDate;

        return (
            <div>
                {hasDatePassed ? (
                    <h4 className='w-full mb-0 text-[16px] font-medium text-white'>Consultation done</h4>
                ) : (
                    <h4 className='w-full mb-0 text-[16px] font-medium text-white'>Consultation pending</h4>
                )}
            </div>
        );
    };
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);

        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };

        return date.toLocaleString('en-US', options);
    }
    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-22'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='flex items-center gap-2 mb-6 text-[16px] font-normal text-chichi opacity-75 hover:opacity-100 w-fit'>
                        <ArrowsLeft className='w-[25px] h-[25px]' onClick={() => {
                            if (caseId != 0) {
                                localStorage.setItem('currentCaseId', caseId)
                                navigate(`/user/cases-details/${caseId}`)
                            } else {
                                navigate(`/user`)
                            }
                        }} />
                        {/* <Link><ArrowsLeft className='w-[25px] h-[25px]' /></Link> */}
                        <h2 className='lg:text-2xl md:text-2xl sm:text-xl text-xl font-bold mb-0'>Booking & Payment history</h2>
                    </div>
                    <div className='max-w-[1150px] mx-auto'>
                        {bookingData.length > 0 ? bookingData.map((item, i) => {
                            const type = item?.type
                            if (type == "consultation") {
                                return (
                                    <div className='w-full py-8 px-8 flex flex-col justify-center bg-popo mx-auto rounded-lg max-lg:py-4 max-lg:px-4 mb-5 max-md:mb-3'>
                                        <h2 class="lg:text-xl md:text-xl sm:text-xl text-xl font-bold mb-0 text-white">{item?.client_full_name}{item?.client_full_name && "|"} {item?.case_consultation_type}</h2>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex border-t border-b border-dashed py-4 mt-4 max-md:flex-col'>
                                                <div className='flex gap-5 items-cente pe-7 max-lg:pe-3 max-lg:gap-3'>
                                                    <div class="min-w-[90px] min-h-[90px]">
                                                        <img class="w-full h-full max-w-[90px] max-h-[90px] rounded-lg object-cover" src={item?.profile_image_url ? `${baseURLImg}/${item?.profile_image_url}` : vakeel_img} alt='#' />
                                                        {/* <img class="w-full h-full max-w-[90px] max-h-[90px] rounded-lg object-cover" src={vakeel_img} alt='#' /> */}
                                                    </div>
                                                    <div>
                                                        <h2 className='lg:text-[22px] md:text-xl sm:text-xl text-xl font-semibold mb-1 text-white'>Adv. {item?.vakeel_first_name} {item?.vakeel_last_name}</h2>
                                                        {/* <span className='w-full text-[16px] font-normal text-black block mb-1'>4.7 (289 reviews)</span> */}
                                                        <div className='w-fit'>
                                                            <ul className='flex gap-8 max-md:gap-4 items-center max-lg:gap-4'>
                                                                <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>{item?.experience_years} years </li>
                                                                <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>English & Hindi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className='flex flex-col gap-2 border-l ps-7 max-lg:ps-3 max-md:ps-0 max-md:pt-4 max-md:border-0'>
                                                    <li><Link to={item?.meet_link} target="_blank"  className='flex items-center gap-2 mb-0 text-[16px] font-normal text-chichi opacity-75 hover:opacity-100 w-fit'><MailLink className='max-w-[20px] min-w-[20px] text-[20px]' />{item?.meet_link}</Link> </li>
                                                    {/* <li><Link className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white hover:text-indigo-900'><DevicesPhone className='max-w-[20px] min-w-[20px] text-[20px]' /> +91-9356624532</Link> </li> */}
                                                    <li className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white'><TimeClock className='max-w-[20px] min-w-[20px] text-[20px]' />{getFormattedTime(item?.start_time)}-{getFormattedTime(item?.end_time)} | {formateDate(item?.appointment_date)}, {new Date(item?.appointment_date).getFullYear()}</li>
                                                </ul>
                                            </div>
                                            <div className='max-w-[50px]'>
                                                <Link to={`/user/cases-details/${item?.case_detail_id}`}><ControlsChevronRight className='w-[30px] h-[30px] hover:text-indigo-900' /></Link>
                                            </div>
                                        </div>

                                        <div className='flex gap-5 items-center pt-4'>
                                            <div className='flex gap-3 items-center'>
                                                <span className="rounded_icon min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] flex items-center justify-center"><GenericCheckAlternative className='w-[22px] h-[22px]' /></span>
                                                {ConsultationStatus(item?.end_time)}
                                            </div>
                                            <div className='flex gap-3 items-center'>
                                                <span className="rounded_icon min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] flex items-center justify-center"><GenericCheckAlternative className='w-[22px] h-[22px]' /></span>
                                                <h4 className='w-full mb-0 text-[16px] font-medium text-white'>Amount Paid ₹{item?.amount}/-</h4>
                                            </div>
                                        </div>

                                    </div>
                                )
                            } else {
                                return (
                                    <div key={i} className='w-full py-8 px-8 flex flex-col justify-center bg-popo mx-auto rounded-lg max-lg:py-4 max-lg:px-4 mb-5 max-md:mb-3'>
                                        <h2 class="lg:text-xl md:text-xl sm:text-xl text-xl font-bold mb-0 text-white">
                                            {/* Requested a callback | {type} */}
                                            Requested a callback {type == "anonymous" && "( Anonymous User)"}
                                        </h2>
                                        <div className='flex justify-between items-center'>
                                            <div className='w-full flex border-t border-b border-dashed py-4 mt-4 max-md:flex-col'>
                                                <div className='flex gap-5 items-center pe-7 max-lg:pe-3 max-lg:gap-3'>
                                                    {/* <div class="min-w-[90px] min-h-[90px]">
                                <img class="w-full h-full max-w-[90px] max-h-[90px] rounded-lg object-cover" src={vakeel_img} alt='#' />
                            </div> */}
                                                    <div>
                                                        <h2 className='lg:text-[22px] md:text-xl sm:text-xl text-xl font-semibold mb-1 text-white'>
                                                            {item?.full_name}
                                                        </h2>
                                                        <li className='flex items-center gap-2 w-full mb-0 text-[16px] font-normal text-white'><TimeClock className='max-w-[20px] min-w-[20px] text-[20px]' />
                                                            {formatTimestamp(item?.transaction_date ? item?.transaction_date : Date.now())}
                                                        </li>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-5 items-center pt-4'>
                                            <div className='flex gap-3 items-center'>
                                                <span className="rounded_icon min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] flex items-center justify-center"><GenericCheckAlternative className='w-[22px] h-[22px]' /></span>
                                                {/* {ConsultationStatus()} */}
                                                <h4 className='w-full mb-0 text-[16px] font-medium text-white'>
                                                    Consultation    {item?.consultation_status}
                                                </h4>
                                            </div>
                                            <div className='flex gap-3 items-center'>
                                                <span className="rounded_icon min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] flex items-center justify-center"><GenericCheckAlternative className='w-[22px] h-[22px]' /></span>
                                                <h4 className='w-full mb-0 text-[16px] font-medium text-white'>Amount Paid {item.amount}/-</h4>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        })
                            : ""
                        }

                    </div>

                    {bookingData.length === 0 &&
                        (<>
                            <div className='w-full py-8 px-8 flex flex-col justify-center bg-popo mx-auto rounded-lg max-lg:py-4 max-lg:px-4 mb-5 max-md:mb-3'>
                                <div className='flex justify-center items-center'>
                                    <div className='flex gap-5 items-cente pe-7 max-lg:pe-3 max-lg:gap-3 text-white'>
                                        <h1><b>Bookings not found.</b></h1>
                                    </div>
                                </div>
                            </div>
                        </>)
                    }
                </div>
            </div>
        </div >
    )
}

export default PaymentHistory

import {
    LANGUAGE_DETECTOR_KEY,
    LANGUAGE_DETECTOR_ENDPOINT,
    LANGUAGE_TRANSLATION_KEY,
    LANGUAGE_TRANSLATION_ENDPOINT,
    AZURE_REGION,
    SUPPORTED_LANGUAGE_DETECTION_LIST
} from './config' 
import axios from 'axios';
import Cookie from 'universal-cookie';

const { TextAnalysisClient, AzureKeyCredential } = require("@azure/ai-language-text");
const TextTranslationClient = require("@azure-rest/ai-translation-text").default,
{ isUnexpected } = require("@azure-rest/ai-translation-text");



// Function: Detects the language of the given text
// Inputs: Text to be analyzed for language detection
// Outputs: Detected language code (ISO 639-1)
export async function languageDetector(textToSpeech) {
    try {
        const key = LANGUAGE_DETECTOR_KEY;
        const endpoint = LANGUAGE_DETECTOR_ENDPOINT;

        if (!textToSpeech) {
            throw new Error('Invalid input for language detection');
        }

        const document = [textToSpeech];
        const client = new TextAnalysisClient(endpoint, new AzureKeyCredential(key));
        const result = await client.analyze("LanguageDetection", document);

        const language = result[0].primaryLanguage.iso6391Name;
        const confidenceScore = result[0].primaryLanguage.confidenceScore;
        const languageName = result[0].primaryLanguage.name;
        console.log('Language Detected: ', language, ' Confidence Score: ', confidenceScore, ' Name: ', languageName)
        if (!SUPPORTED_LANGUAGE_DETECTION_LIST.includes(language)) {
            console.log('Unsupported language, switching to default English')
            return 'en';
        }

        return language;
    } catch (error) {
        // Handle error in language detection
        console.error('Error in language detection:', error);
        throw error;
    }
};


// Function: Translates the text from one language to another
// Inputs: Text to be translated, Source language code, Target language code
// Outputs: Translated text
export async function languageTranslator(translationText, sourceLanguage, targetLanguage){
    try {
        // Input validation
        if (!translationText ||
            !sourceLanguage || typeof sourceLanguage !== 'string' ||
            !targetLanguage || typeof targetLanguage !== 'string') {
            throw new Error('Invalid input for language translation');
        }
        console.log('Text: ', translationText)
        console.log('Source: ', sourceLanguage, ' Target: ', targetLanguage)
        const endpoint = LANGUAGE_TRANSLATION_ENDPOINT;
        const apiKey =  LANGUAGE_TRANSLATION_KEY;
        const region = AZURE_REGION;

        const translateCedential = {
            key: apiKey,
            region,
        };
        const inputBody = [{text: translationText}];
        const translationClient = TextTranslationClient(endpoint, translateCedential);
        const translateResponse = await translationClient.path("/translate").post({
            body: inputBody,
            queryParameters: {
              to: targetLanguage,
              from: sourceLanguage,
            },
        });

        // if (isUnexpected(translateResponse)) {
        //     throw translateResponse.body;
        // }

        const translations = translateResponse.body;
        return translations[0].translations[0].text;
    } catch (error) {
        // Handle error in language translation
        console.error('Error in language translation:', error);
        throw error;
    }
};


const COOKIE_NAME = 'speech-token';
const MAX_AGE_SECONDS = 540;
const API_ENDPOINT = 'https://vakeel4you.com/vakeel-backend/api/speech_token';

export async function getTokenOrRefresh() {
    const cookie = new Cookie();

    try {
        const speechToken = cookie.get(COOKIE_NAME);

        if (speechToken === undefined) {
            const res = await axios.get(API_ENDPOINT);
            const { token, region } = res.data;

            cookie.set(COOKIE_NAME, `${region}:${token}`, { maxAge: MAX_AGE_SECONDS, path: '/' });

            return { authToken: token, region: region };
        } else {
            const [region, authToken] = speechToken.split(':');
            return { authToken, region };
        }
    } catch (error) {
        console.error('Error in getTokenOrRefresh:', error);
        throw new Error('Failed to retrieve or refresh speech token.');
    }
}


// Function: Checks if a string ends with specific punctuation marks (., !, ?)
//           and if the preceding character is not a number followed by a period
// Input: string - The string to be checked
// Output: Boolean value - true if the string ends with specific punctuation marks, false otherwise

export function checkString(string) {
    // Trim the string and split into sentences
    const sentences = string.trim().match(/[^.!?]+[.!?]+(?:\s|$)/g);
    if (!sentences || sentences.length === 0) {
        return false;
    }
    const lastSentence = sentences[sentences.length - 1];

    // Check for decimal numbers or itemized lists in the last sentence
    const decimalOrListItemRegex = /\d+\.\d+|\d+\.\s+[^.!?]+$/;
    if (decimalOrListItemRegex.test(lastSentence)) {
        return false;
    }

    // Check for common abbreviations
    const commonAbbreviationsRegex = /\b(e\.g\.|i\.e\.|Mr\.|Mrs\.|Shri\.|Smt\.|Ku\.|Dr\.|vs\.|etc\.|Jan\.|Feb\.|Mar\.|Apr\.|Jun\.|Jul\.|Aug\.|Sep\.|Oct\.|Nov\.|Dec\.)$/;
    if (commonAbbreviationsRegex.test(lastSentence)) {
        return false;
    }

    // Check for sentence-ending punctuation
    return /[.!?]$/.test(lastSentence);
}


// Function: Checks if a string is a number
// Input: string - The string to be checked
// Output: Boolean value - true if the string is a number, false otherwise
// function checkNumber(string) {
//     if (typeof string === "string") {
//         return !isNaN(string);
//     }
// }

// export function checkString(string) {
//     if (string.slice(-1) === "." && checkNumber(string.slice(-2))) {
//         return false; // Returns false if the string ends with a number followed by a period
//     } 
//     else if (string.slice(-1) === "." || string.slice(-1) === "!" || string.slice(-1) === "?") {
//         return true; // Returns true if the string ends with ., !, or ?
//     } else {
//         return false; // Returns false for other cases
//     }
// }

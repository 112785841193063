import React from 'react';
import { useState } from 'react';
import { v4 as uuid } from "uuid";
import ChatBot from './chatbot'
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const BotTesting = () => {
    let [showChat, setShowChat] = useState(false);
    const unique_id = uuid();
    const startChat = () => { setShowChat(true); }
    const hideChat = () => { setShowChat(false); }
    return (
        <>
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/background.png'})`,
                backgroundSize: 'cover'
            }} className='background'>
                <div style={{ display: showChat ? "" : "none" }}>
                    <ChatBot></ChatBot>
                </div>
                {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
                <div>
                    {!showChat
                        ? <button className='circular' onClick={() => startChat()}>
                            <FontAwesomeIcon icon={faComment} id='theme_color_button' style={{ fontSize: '30px' }} />
                        </button>
                        : <button className='circular' onClick={() => hideChat()}>
                            <FontAwesomeIcon icon={faCircleXmark} id='theme_color_button' style={{ fontSize: '40px' }} />
                        </button>}
                </div>

            </div>
        </>
    )
}

export default BotTesting
export const API_TIMEOUT = 90000; // 90 seconds in milliseconds
export const USE_STREAMING = true; // Enable streaming response from LLM
export const USE_AUDIO = true;  // Enable/disable Audio i.e Microphone & speaker

export const AZURE_REGION = 'centralindia';
export const LANGUAGE_DETECTOR_KEY = '8d0e00332a824d6684459d5520066a81';
export const LANGUAGE_DETECTOR_ENDPOINT = 'https://vakilbot-azureai.cognitiveservices.azure.com/';
export const LANGUAGE_TRANSLATION_KEY = '8d0e00332a824d6684459d5520066a81';
export const LANGUAGE_TRANSLATION_ENDPOINT = 'https://vakilbot-azureai.cognitiveservices.azure.com/';

export const SUPPORTED_LANGUAGE_LIST = ['hi-IN', 'kn-IN', 'en-IN'];
export const SUPPORTED_LANGUAGE_DETECTION_LIST = ['hi', 'kn', 'en'];

export const VOICE_TYPE = "MALE";
export const VOICE_STYLE = {
    'en': { "MALE": "en-US-GuyNeural", "FEMALE": "en-IN-NeerjaNeural" },
    'hi': { "MALE": "hi-IN-MadhurNeural", "FEMALE": "hi-IN-SwaraNeural" },
    'kn': { "MALE": "kn-IN-GaganNeural", "FEMALE": "kn-IN-SapnaNeural" }
};



import moment from 'moment-timezone';


export const monthNames = [
    "January", "February", "March",
    "April", "May", "June",
    "July", "August", "September",
    "October", "November", "December"
];

export const dateFormatter = (originalDateString) => {
    // const originalDateString = "2024-01-06T18:30:00.000Z";
    if(!originalDateString) return ''
    const originalDate = new Date(originalDateString);

    // Get the individual components of the date
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(originalDate.getDate()).padStart(2, '0');

    // Create the new date string in the desired format
    const newDateString = `${year}-${month}-${day}`;
    return newDateString;
}

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.toLocaleString('en-US', { day: '2-digit' });
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
}

export const formateTimeZone = (date) => {
    const convertedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    return convertedDate
}

export const getFormattedTime = (dateTime) => {
    const dateObject =moment(dateTime);
    // Get the time in 12-hour format with AM/PM
    const formattedTime =dateObject.format('h:mm A')
    return formattedTime
}

const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
};

export const formateDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', options)
}

const optionsShort = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
};

export const formateDateShort = (date) => {
    return new Date(date).toLocaleDateString('en-US', optionsShort)
}

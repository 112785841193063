import React, { useEffect, useState } from 'react'
import { ControlsChevronDown } from '@heathmont/moon-icons-tw'
import { Link, useNavigate } from 'react-router-dom'
import { Breadcrumb } from '@heathmont/moon-core-tw'
import Navbar from '../../components/vakeel/Navbar'
import { passbook } from '../../services/vakeel/dashboard-service'
import { formatDate, getFormattedTime, monthNames } from '../../utils/date-formatter'

const Passbook = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [totalEarning, setTotalEarning] = useState();
    const [dateEarning, setDateEarning] = useState();

    const breadcrumbs = [
        <Link className='text-white' to='/dashboard'>Vakeel Home</Link>,
        <Link className='text-white' href="">Passboook</Link>,
    ];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const getPassbookData = async () => {
        const response = await passbook(userInfo.user_id);
        if (response.status === 200) {
            setTransactionDetails(response.data);
            console.log('transaction', response.data)
            // const earnings = response.data.reduce((acc, c) => acc + Number(c.amount), 0);
            // setTotalEarning(earnings);
            let data = [...response.data];
            data.forEach(item => {
                item.created_at = new Date(item.created_at);
                item.amount = Number(item.amount) - Number(item.amount)*Number(item.commission_rate)/100
              });

              const earnings = data.reduce((acc, c) => acc + Number(c.amount), 0);
              setTotalEarning(earnings);
              
              const filteredData = data.filter(item => {
                const itemYear = item.created_at.getFullYear();
                const itemMonth = item.created_at.getMonth();
              
                return itemYear === currentYear && itemMonth === currentMonth;
              });
              const dateEarnings = filteredData.reduce((acc, c) => acc + Number(c.amount), 0);
              setDateEarning(dateEarnings)
        }
    }
    useEffect(() => {
        getPassbookData()
    }, [])

    const navigate = useNavigate();
    const profileApproved = localStorage.getItem('profileApproved');
    useEffect(() => {
        if (profileApproved === 0) {
            navigate('/profile-submitted')
        }
    })
    return (
        <div>
            <Navbar />
            <div className='mt-24'>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                    <div className='w-full py-10 px-7 bg-popo mx-auto rounded-lg mt-8 max-md:mt-4 max-md:py-5 max-md:px-5'>
                        <div className='flex items-center justify-between border-b'>
                            <div className='px-6 max-md:px-3'>
                                <h6 className='text-lg max-md:text-[16px] leading-6 font-medium text-white whitespace-nowrap'>{monthNames[currentMonth]} Earning</h6>
                                <h2 className='text-[24px] max-md:text-[20px] font-bold text-white whitespace-nowrap pb-5 max-md:pb-3'>₹{dateEarning}</h2>
                            </div>
                            <div className='px-6 max-md:px-3 border-l'>
                                <h6 className='text-lg max-md:text-[16px] leading-6 font-medium text-white whitespace-nowrap'>Total Earning</h6>
                                <h2 className='text-[24px] max-md:text-[20px] font-bold text-white whitespace-nowrap pb-5 max-md:pb-2'>₹{totalEarning}</h2>
                            </div>
                        </div>

                        <div className='w-full mx-auto mt-2'>
                            <div className="align-middle inline-block w-full overflow-x-auto">
                                <table className="min-w-full">
                                    <thead>
                                        <tr className="leading-4 tracking-wider whitespace-nowrap">
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-chichi">
                                                <Link className='flex align-middle gap-2' > Customer name </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-chichi whitespace-nowrap">
                                                <Link className='flex align-middle gap-2' > Order no. </Link>
                                            </th>
                                            <th className="px-5 py-3 text-left text-[15px] font-medium text-chichi whitespace-nowrap flex justify-end">
                                                <Link className='flex align-middle gap-2 min-w-[170px] max-w-[170px]' > Amount earned  </Link>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-popo">

                                        {transactionDetails.length > 0 && transactionDetails.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap">
                                                        <div className="text-md leading-5 font-normal text-white capitalize">{x.client_first_name} {x.client_last_name}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap">
                                                        <span className='font-medium text-white'>{x?.payment_data?.gateway_response?.razorpay_order_id}</span>
                                                        <div className="text-sm leading-5 font-normal text-white opacity-80 whitespace-nowrap">{formatDate(x.created_at)} {getFormattedTime(x.created_at)}</div>
                                                    </td>
                                                    <td className="px-5 py-3 whitespace-no-wrap whitespace-nowrap flex justify-end">
                                                        <div className="text-md font-normal min-w-[170px] max-w-[170px] text-white">₹{x.amount}/-</div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    )
}

export default Passbook

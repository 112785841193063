import React, { useEffect } from 'react'
import Navbar from '../../components/vakeel/Navbar'
import FullCalendar from '../../components/vakeel/FullCaledar'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const navigate = useNavigate();
    const profileApproved = localStorage.getItem('profileApproved');
    useEffect(()=>{
      if(profileApproved === 0) {
        navigate('/profile-submitted')
      }
    })
    return (
        <div>
            <Navbar />
            <section className='mt-24'>
                <FullCalendar />
            </section>
        </div>
    )
}

export default Dashboard;

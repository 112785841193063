import React, { useEffect } from 'react'
// import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import vaadin_img from '../images/vaadin_office.svg'
import mdi_img from '../images/mdi_form.svg'
import octicon_img from '../images/octicon_law.svg'
import { ArrowsRight, ControlsChevronLeftSmall, ControlsChevronRightSmall, ControlsPlus, GenericLightningBolt } from '@heathmont/moon-icons-tw'
import { Accordion, Button, Carousel, Dropdown, MenuItem } from '@heathmont/moon-core-tw'
import footer_logo from '../images/footer.svg'
import Navbar from '../components/common/Navbar'
// import Navbar from '../components/user/Navbar'
import stepball from '../images/stepbox_img.svg'
import step_thumb from '../images/step_thumb.svg'

import shoffr from '../images/shoffr.svg'
import insurfin from '../images/insurfin.svg'
import himalaya from '../images/himalaya.svg'
import titan from '../images/titan.svg'
import fb from '../images/fb.svg'
import insta from '../images/insta.svg'
import linkdn from '../images/linkdln.svg'
import twitter from '../images/twitter.svg'
import vakeel_img from '../images/vakeel_img.png'
import bus_incp from '../images/business_incorp.svg'
import criminal from '../images/criminal.svg'
import legal_img from '../images/legal_form.svg'
import from_stmp from '../images/form_stmp.svg'



const About = () => {
    const navigate = useNavigate()
    const userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {}
    useEffect(() => {
        if (Object.keys(userInfo).length > 0) {
            if (userInfo.role === 'user') {
                navigate('/user')
            } else if (userInfo.role === 'vakeel') {
                navigate('/profile')
            }
        }
    }, [])

    return (
        <div className='relative'>

        <div className='landing_header absolute top-0 w-full z-5'>
           <div className='px-5 py-5 max-md:px-3 max=md:py-3 mx-auto max-md:bg-black'>
               <div className='flex items-center gap-20 w-full max-sm:justify-between max-sm:gap-5'>
                   <div>
                       <img className='header_logo max-w-[150px] min-w-[150px] h-auto max-h-auto min-h-auto object-contain' src={footer_logo} alt='#'></img>
                   </div>
                   <div className='flex justify-between items-center w-full header_nav'>
                       <div className='flex gap-5 items-center max-sm:hidden'>
                           <Link className='text-md font-normal text-goten ease-in-out duration-500 hover:text-yellow-400' to="/">Home</Link>
                           <Link className='text-md font-normal text-goten ease-in-out duration-500 hover:text-yellow-400 active' to="/about">About us</Link>
                       </div>
                       <div className='flex gap-5'>
                       <Dropdown className='header_drop'>
                            <Dropdown.Trigger>
                                <div className='flex items-center gap-3'>
                                    <Button className='py-5 px-10 border bg-transparent hover:border' >Login</Button>
                                
                                </div>

                            </Dropdown.Trigger>
                            <Dropdown.Options className='bg-popo border-0'>

                                <Dropdown.Option>
                                    <MenuItem onClick={() => {
                                        navigate('/user/login')
                                    }}>
                                        <MenuItem.Title className='text-white'>Login as a user</MenuItem.Title>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/vakeel/login')
                                    }}>
                                        <MenuItem.Title className='text-white'>Login as a lawyer</MenuItem.Title>
                                    </MenuItem>
                                </Dropdown.Option>
                            </Dropdown.Options>
                        </Dropdown>

                       </div>
                   </div>
               </div>
           </div>
        </div>

        <div className='about_hero'>
            <div className='container mx-auto relative px-8'>
                <div className='bg-popo p-8 max-md:py-4 about_heading'>
                    <h5 className='text-[22px] max-md:text-[18px] text-white'><span className='text-[22px] max-md:text-[18px] text-white font-semibold'>Contact Us</span></h5>
                </div>
            </div>
        </div>

        <div className='py-20 max-md:py-10 about_page px-5'>
            <div className='container mx-auto'>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p style={{fontSize: '1.5em', fontWeight: 'bold', margin: 0 , color:'white' , textAlign :'center'}}>Call Us</p>
                    <br></br>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>+91 78800 59977</p>
                </div>
                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p style={{fontSize: '1.5em', fontWeight: 'bold', margin: 0 , color:'white' , textAlign :'center'}}>Email</p>
                    <br></br>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>tech@vakeel.group</p>
                </div>

                <div className='bg-popo px-16 py-12 max-lg:py-10 max-lg:px-10 max-md:py-5 max-md:px-5 rounded-2xl mb-[40px]'>
                    <p style={{fontSize: '1.5em', fontWeight: 'bold', margin: 0 , color:'white' , textAlign :'center'}}>Head Office</p>
                    <br></br>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>48/1, Nehru Ward,</p>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>North Civil Lines, Jabalpur,</p>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>Madhya Pradesh, India</p>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>PIN 482001</p>

                    <br></br>
                    <p style={{fontSize: '1.5em', fontWeight: 'bold', margin: 0 , color:'white' , textAlign :'center'}}>Delhi Office</p>
                    <br></br>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>Vakeel Group, D-77, Dron marg,</p>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>Block-d, Defence colony,</p>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>New Delhi, India</p>
                    <p style={{fontSize: '1.2em', margin: 0, color:'white' , textAlign :'center'}}>PIN 482001</p>
                </div>

            </div>
        </div>


       <div className='footer bg-popo py-16 max-md:py-5'>
           <div className='container mx-auto px-5 max-md:px-3'>
               <div className="flex gap-64 max-lg:gap-32 w-full pb-12 max-md:flex-col max-md:gap-10 max-md:pb-6">
                   <img className='max-w-[150px] min-w-[150px] h-auto max-h-auto min-h-auto object-contain max-md:max-w-[100px] max-md:min-w-[100px]' src={footer_logo} alt='#'></img>
                   <div className='flex justify-between w-full max-w-[800px] footer_links'>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>Company</h4>
                           <ul className='flex flex-col gap-4'>
                               <li><Link to='/about' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>About us</Link></li>
                               <li><Link to='/privacy-policy' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Privacy policy</Link></li>
                               <li><Link to='/term-of-uses' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Terms of use</Link></li>
                               <li><Link to='/contact-us' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Contact us </Link></li>
                           </ul>
                       </div>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>For lawyers</h4>
                           <ul className='flex flex-col gap-4'>
                               <li><Link to='/signup' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Register as a lawyers</Link></li>
                               <li><Link to='/disclaimer' className='text-goten text-md hover:text-yellow-400 ease-in-out duration-500'>Disclaimer</Link></li>
                           </ul>
                       </div>
                       <div className='footer_list'>
                           <h4 className='text-chichi opacity-70 text-lg font-medium mb-4'>Social links</h4>
                           <ul className='flex gap-5'>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={twitter} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={fb} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={insta} alt=''></img></Link></li>
                               <li><Link to='' className='text-goten text-md'><img className='max-w-[20px]' src={linkdn} alt=''></img></Link></li>
                           </ul>
                       </div>
                   </div>
               </div>
               <p className='text-goten opacity-75 text-sm pt-10 max-md:pt-6 mb-0 border-t'>© Copyright 2024 Vakeel Group. All rights reserved.</p>
           </div>
       </div>

        </div>
    )
}

export default About;

import api from "../api"

const signup = (reqBody) => {
    return api.post('vakeel/register', reqBody).catch((err) => err.response)
}
const sendLoginOtp = (reqBody) => {
    return api.post('otp/send-otp', reqBody).catch((err) => err.response)
}
const verifyOtp = (reqBody) => {
    return api.post('otp/verify-otp', reqBody).catch((err) => err.response)
}
const signin = (reqBody) => {
    return api.post('vakeel/login', reqBody).catch((err) => err.response)
}
const forgotPassword = (reqBody) => {
    return api.post('vakeel/forgot-password', reqBody).catch((err) => err.response)
}
const resetPassword = (reqBody) => {
    return api.post('vakeel/reset-password', reqBody).catch((err) => err.response)
}
const checkUserExist = (reqBody) => {
    return api.post('vakeel/checkExistingUser', reqBody).catch((err) => err.response)
}
// const googleLogin = (user) => {
//    api.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`).catch((err)=> err)
// }

export { signup, signin, forgotPassword, resetPassword, checkUserExist, verifyOtp, sendLoginOtp }
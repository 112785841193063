
import React, { useEffect, useState } from 'react'
import { Input, Button, Textarea, Label } from '@heathmont/moon-core-tw';
// import Navbar from '../../components/vakeel/Navbar';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { baseURLImg } from '../../config';
import { getProfile, profileUpdate } from '../../services/vakeel/profile-service';
import { toast } from 'react-toastify';
import profileSchema from '../../validation-schemas/vakeel/profile-schema';
import ButtonLoading from '../../components/loading/ButtonLoading';
import userIcon from '../../images/usericon_l.svg'
import Navbar from '../../components/user/Navbar';
import { updateProfile, userProfile } from '../../services/user/auth-service';
import userProfileSchema from '../../validation-schemas/user/user-profile-schema';
import { updateUserProfile } from '../../services/user/case-service';

const Profile = () => {
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [previewUrl, setPreviewUrl] = useState(null);
    const [userData, setUserData] = useState({})
    // console.log(userInfo, "userInfo")
    // console.log(userData, "userData")
    // const getUserProfile = async () => {
    //     const userProfiles = await userProfile(userInfo?.user_id);
    //     setUserData(userProfiles?.data)
    // }
    // React.useEffect(() => {
    //     getUserProfile()
    // }, [])
    const [profileImage, setProfileImage] = useState(null);
    // const [profileData, setProfileData] = useState(null);
    // const [isSubmitted, setIsubmitted] = useState(false);

    const getProfileDetails = async (user_id) => {
        const response = await userProfile(user_id);
        if (response.status === 200) {
            setUserData(response?.data)
            if (response.data.image_url != null && response.data.image_url !== '') {
                setPreviewUrl(`${baseURLImg}/${response.data.image_url}`)
            }
        }
    }

    useEffect(() => {
        getProfileDetails(userInfo?.user_id)
    }, [])

    // console.log(userData)
    const formik = useFormik({
        initialValues: {
            mobile: userData?.mobile,
            email: userData?.email,
            full_name: userData?.full_name,
            city: userData?.city,
        },
        enableReinitialize: true,
        validationSchema: userProfileSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append('full_name', values.full_name);
            formData.append('email', values.email);
            formData.append('city', values.city);
            formData.append('mobile_number', values.mobile);
            formData.append('image', profileImage);
            console.log(values, "values")
            // return
            setLoading(true);
            const data = {
                reqBody: values,
                user_id: userInfo?.user_id
            }
            const response = await updateProfile(userInfo?.user_id, formData);
            // const response = await updateUserProfile(data);
            if (response.status === 200) {
                localStorage.setItem('userProfileImg', response.data.image_url);
                setTimeout(() => {
                    let user_info = {
                        ...userInfo,
                        full_name: values.full_name,
                        city: values.city,
                        mobile: values.mobile,
                        email: values.email
                    }
                    // console.clear()
                    // console.log(user_info)
                    localStorage.setItem('userInfo', JSON.stringify(user_info));
                }, 3000)
                setLoading(false);
                toast.success('Profile updated successfully', { autoClose: 3000 });
            }
            else{
                toast.error(response.data.message, { autoClose: 3000 });
                setLoading(false);
            }
        },
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Read the selected file and set the preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setProfileImage(file);
            console.log('file', file)
        } else {
            setPreviewUrl(null);
            setProfileImage(null);
        }
    };

    const uploadFile = () => {
        document.getElementById("profile_img").click()
    }
    return (
        <div>
            <Navbar />
            <div className='mt-24'>
                <div className='container mx-auto px-5 max-w-screen-xl max-md:px-3'>
                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo max-w-screen-xl mx-auto rounded-lg max-md:py-5 max-md:px-5'>
                        <h2 className='lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-7 text-white'>My Profile</h2>
                        <div className='flex max-lg:flex-col'>
                            <div className='w-2/5 max-lg:w-full max-lg:mb-4'>
                                <div className='flex flex-col items-center gap-4'>
                                    {/* <img className='w-full max-w-40 h-full max-h-40 rounded-full border-2 border-chichi'
                                        src={`${baseURLImg}/294981.png`} alt='#'></img> */}
                                    <div className='w-full max-w-40 min-w-40 h-full max-h-40 min-h-40 rounded-full border-2 border-chichi flex items-center justify-center'>
                                        <img className='w-full max-w-40 min-w-40 h-full max-h-40 min-h-40 rounded-full object-cover border-2 border-chichi'
                                            src={previewUrl == null ? userIcon : previewUrl} alt='#'></img>
                                    </div>
                                    {/* <img className='w-full max-w-40 h-full max-h-40 rounded-full border-2 border-chichi'
                                        src='https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250' alt='#'></img> */}
                                    <Link to="#" onClick={(e) => {
                                        if (loading) {
                                            e.preventDefault();
                                        }
                                        else {
                                            uploadFile()
                                        }
                                    }} className="tmb-0 text-[16px] font-normal text-chichi opacity-75 hover:opacity-100">Upload a photo</Link>
                                    <input type='file' id='profile_img' style={{ visibility: 'hidden' }} onChange={handleFileChange} />
                                </div>
                            </div>
                            <form onSubmit={formik.handleSubmit} className='w-3/5 max-lg:w-full'>
                                <div className='flex flex-col gap-4'>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Name</Label>
                                        <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="Name" name="full_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.full_name} />
                                        {formik.touched.full_name && formik.errors.full_name ? (
                                            <small className="text-danger validationError">{formik.errors.full_name}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Email ID </Label>
                                        <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="name@gmail.com" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                        {formik.touched.email && formik.errors.email ? (
                                            <small className="text-danger validationError">{formik.errors.email}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Mobile Number </Label>
                                        <Input size="lg" type="number" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="+91 5645312564" name="mobile" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mobile} />
                                        {formik.touched.mobile && formik.errors.mobile ? (
                                            <small className="text-danger validationError">{formik.errors.mobile}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' for="">City </Label>
                                        <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Your City" name='city' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city} />
                                        {formik.touched.city && formik.errors.city ? (
                                            <small className="text-danger validationError">{formik.errors.city}</small>
                                        ) : ''}
                                    </div>
                                    <div className='col-span-2'>
                                        <Button to="proile" className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg" type='submit' disabled={!formik.isValid || loading}>  {loading &&
                                            <ButtonLoading />
                                        }Save Information</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer position='bottom-right' /> */}
        </div>
    )
}

export default Profile



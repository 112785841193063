import * as Yup from 'yup';
const emailRegExp = /^[^<>()[\]\\,;:%#^\s@"$&!]+@(?:(?:\d{1,3}\.){3}\d{1,3}|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/;
// const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
export default Yup.object().shape({
    email: Yup.string().matches(emailRegExp, 'Email must be a valid email.').required('Email is required.'),
    phone_number: Yup.string().matches(/^[6-9]\d{9}$/, 'Invalid mobile number.'),
    consultation_type: Yup.string().required('Consultation type is required.'),
    // incident_type: Yup.string().required('Incident type required.'),
    case_name: Yup.string(),
    incident_location: Yup.string(),
    // suspect_details: Yup.string().required('Supect details required.'),
    // witness_details: Yup.string().required('Witness details required.'),
    // damages_desc: Yup.string().required('Damages description is required.'),
    // evidence_url: Yup.string().required('Evidence URL is required.'),
});

import React, { useState } from 'react'
import Navbar from '../../components/user/Navbar'
import { Button, Input, Label, } from '@heathmont/moon-core-tw'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { checkout, updateUserProfile, verifyPayment } from '../../services/user/case-service'
import { toast } from 'react-toastify'
// import registerCaseSchema from '../../validation-schemas/user/register-case-schema'
// import ButtonLoading from '../../components/loading/ButtonLoading'
import { userProfile } from '../../services/user/auth-service'
// import userProfileSchema from '../../validation-schemas/user/user-profile-schema'
import * as Yup from 'yup';
// import vakeel_img from '../../images/vakeel_img.jpg'
// import { Input } from 'postcss'

const userProfileSchema = Yup.object().shape({
    // full_name: Yup.string(),
    // city: Yup.string(),
    mobile: Yup.string().matches(/^[6-9]\d{9}$/, 'Invalid mobile number.').required('Mobile number is required.'),
});
const UpdateProfile = () => {
    const [btnType, setBtnType] = React.useState("")

    const [loading, setLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(userInfo, "userInfo")
    const [userData, setUserData] = useState({})
    const navigate = useNavigate()
    const getUserProfile = async () => {
        const userProfiles = await userProfile(userInfo?.user_id);
        setUserData(userProfiles?.data)
    }
    React.useEffect(() => {
        getUserProfile()
    }, [])
    const formik = useFormik({
        initialValues: {
            mobile: userData?.mobile,
            // email: userData?.email,
            full_name: userData?.full_name,
            city: userData?.city,
        },
        enableReinitialize: true,
        validationSchema: userProfileSchema,
        onSubmit: async (values) => {
            setLoading(true)
            // console.log(btnType, "btnType")
            const data = {
                reqBody: values,
                user_id: userInfo?.user_id
            }
            const response = await updateUserProfile(data);
            if (response?.status === 200) {
                let user_info = {
                    ...userInfo,
                    full_name: values.full_name,
                    city: values.city,
                    mobile: values.mobile,
                }
                localStorage.setItem('userInfo', JSON.stringify(user_info));
                 await userProfile(userInfo?.user_id);

                if (btnType === "Register") {
                    navigate("/user/register-case")
                }
                if (btnType === "Callback") {
                    handleGetConsultation()
                }
            } else {
                setLoading(false)
                toast.error(response?.data?.message, { autoClose: 3000 });
            }
        },
    });
    // console.log(isErrorInupdate, "isErrorInupdate")

    const handleGetConsultation = async () => {
        // const isFormValid = formik.values.mobile ? true : false
        // formik.handleSubmit()
        // if (isFormValid) {
        setLoading(true)
        setLoading(true)
        setTimeout(async () => {
            const body = { "type": "sos" }
            const checkoutRes = await checkout(body)
            // console.log(checkoutRes, "checkoutRes")
            if (checkoutRes?.status === 200) {
                setTimeout(() => {
                    const order_id = checkoutRes?.data?.data?.order?.id;
                    const amount = checkoutRes?.data?.data?.order?.amount;
                    const sos_id = checkoutRes?.data?.data?.sos_id;
                    setLoading(true)//!set to true after test
                    // consultNow(checkoutRes?.data?.order?.id, res?.data?.data?.id, res?.data?.data?.amount) //orderid- sosId
                    consultNow(order_id, sos_id, amount) //orderid- sosId
                }, 500);
            } else {
                toast.error(checkoutRes?.data?.message, { autoClose: 3000 });
            }
        }, 500);
        // }
    }
    const consultNow = async (order_id, sos_id, amount) => {
        const options = {
            key: "rzp_test_eDawdhRtZBJkL4",
            // key: "rzp_test_YD1PceY5BUC7kj",
            amount: amount * 100,
            currency: "INR",
            name: "Vakeel",
            description: "Test Transaction",
            image: "",
            order_id: order_id,
            handler: async (response) => {
                // console.log(response, "response")
                const body = {
                    "user_id": userInfo?.user_id,
                    "amount": amount,
                    "payment_data": response,
                    "type": "sos",
                    "sos_id": sos_id
                }
                const res = await verifyPayment(body)
                if (res?.status === 200) {
                    toast.success('Thank you for Registrating on Vakeel ,Our suppor team will call you back')
                    setTimeout(() => {
                        setLoading(false)
                        navigate("/user/payment-history")
                    }, 500);
                    // ! redirect to payment history
                }
            },
            modal: { ondismiss: function () { setLoading(false) } },
            // "callback_url": "http://localhost:5800/api/user/paymentVerification",
            prefill: {
                name: userInfo?.full_name,
                email: userInfo?.email,
                contact: userInfo?.mobile
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#3399cc"
            }
        };

        const razor = new window.Razorpay(options);
        razor.open();
    }
    // 'order_NaJXLpQ0g1ufaE'
    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-22'>
                <div className='container mx-auto px-5 max-md:px-3'>

                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo rounded-lg max-md:py-5 max-md:px-5 max-w-[900px] mx-auto'>
                        <div className='text-center mb-6 max-w-[700px] mx-auto'>
                            <h2 class="lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-3 text-white">Get Started!</h2>
                            <p class="text-md text-center font-medium mb-0 text-white">Help us with some information to create a initial consultation breif.
                                this service is free and your information is kept completely confidential</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='flex flex-col gap-4 mb-5'>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Name </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Your name" name='full_name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.full_name} />
                                    {formik.touched.full_name && formik.errors.full_name ? (
                                        <small className="text-danger validationError">{formik.errors.full_name}</small>
                                    ) : ''}
                                </div>
                                {/* <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Email id </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Your email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <small className="text-danger validationError">{formik.errors.email}</small>
                                    ) : ''}
                                </div> */}
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Phone number </Label>
                                    <Input disabled={userData?.mobile ? true : false} size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Your Phone number" name='mobile' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobile} />
                                    {formik.touched.mobile && formik.errors.mobile ? (
                                        <small className="text-danger validationError">{formik.errors.mobile}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">City </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Your City" name='city' onChange={formik.handleChange} value={formik.values.city} />
                                    {/* {formik.touched.city && formik.errors.city ? (
                                        <small className="text-danger validationError">{formik.errors.city}</small>
                                    ) : ''} */}
                                </div>
                                {/* <div className='col-span-2'>
                                    <Button type="submit" className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg" disabled={!formik.isValid}>{loading &&
                                        <ButtonLoading />
                                    }Update profile</Button>
                                </div> */}
                            </div>
                        </form>
                        {/* <div className="divide_line my-6 relative border-t-2"><span className='absolute -top-3.5 bg-popo left-1/2 px-2.5 -ml-3.5 text-goten'>Or</span></div> */}
                        <div style={{ display: 'flex', gap: '15px' }}>
                            <Button type="submit" className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg"
                                onClick={() => { formik.handleSubmit(); setBtnType("Callback") }} disabled={loading}>
                                Get a callback
                            </Button>
                            <Button type="submit" className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg"
                                onClick={() => {
                                    formik.handleSubmit();
                                    setBtnType("Register")
                                }
                                } disabled={loading}>
                                Register case
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
            <br />
        </div>
    )
}

export default UpdateProfile

// export const RazorBtn = () => {
//     const consultNow = async (order_id) => {
//         // console.log(order_id, "order_id")
//         const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
//         if (res) {
//             const options = {
//                 key: "rzp_test_eDawdhRtZBJkL4",
//                 // key: "rzp_test_YD1PceY5BUC7kj",
//                 amount: 5000,
//                 currency: "INR",
//                 name: "Vakeel",
//                 description: "Test Transaction",
//                 image: "",
//                 order_id: "order_NaJXLpQ0g1ufaE",
//                 handler: async (response) => {
//                     // Handle the successful payment response
//                     console.log(response)
//                 },
//                 // "callback_url": "http://localhost:5800/api/user/paymentVerification",
//                 prefill: {
//                     name: "Gaurav Kumar",
//                     email: "gaurav.kumar@example.com",
//                     contact: "9000090000"
//                 },
//                 notes: {
//                     address: "Razorpay Corporate Office"
//                 },
//                 theme: {
//                     color: "#3399cc"
//                 }
//             };

//             const razor = new window.Razorpay(options);
//             razor.open();
//         } else {
//             console.error('Failed to load Razorpay script');
//         }
//     }
//     const handlePayment = async () => {
//         const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
//         if (res) {
//             const options = {
//                 key: 'rzp_test_YD1PceY5BUC7kj', // Replace with your Razorpay key
//                 amount: 10000, // Amount in paise (100 paise = 1 INR)
//                 currency: 'INR',
//                 name: 'Your Company Name',
//                 description: 'Test Payment',
//                 image: 'https://your-company-logo.png',
//                 order_id: "order_NaJXLpQ0g1ufaE",
//                 handler: function (response) {
//                     console.log(response);
//                     // setPaymentSuccess(true);
//                 },
//                 prefill: {
//                     name: 'John Doe',
//                     email: 'john@example.com',
//                     contact: '9999999999'
//                 },
//                 notes: {
//                     address: 'Razorpay Corporate Office'
//                 },
//                 theme: {
//                     color: '#F37254'
//                 }
//             };

//             const razorpay = new window.Razorpay(options);
//             razorpay.open();
//         } else {
//             console.error('Failed to load Razorpay script');
//         }
//     };
//     return (<>
//         <Button onClick={consultNow}>
//             Razor
//         </Button>
//     </>)
// }

// export const loadScript = (src) => {
//     return new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = src;
//         script.onload = () => resolve(true);
//         script.onerror = () => reject(false);
//         document.body.appendChild(script);
//     });
// };
import React from 'react';
import { Dropdown, MenuItem } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { ControlsChevronDown, GenericLogIn, SoftwareLogin } from '@heathmont/moon-icons-tw';
import { useNavigate } from 'react-router-dom';
import userIcon from '../../images/usericon_s.svg'


const Navbar = () => {
    const navigate = useNavigate();

    return (
        <div className='bg-popo py-3 px-5 fixed top-0 w-full z-40 border-b'>
            <div className="flex items-center justify-between w-full gap-2">
                <img className='max-w-[150px] min-w-[150px] h-auto max-h-auto min-h-auto object-contain' src={header_logo} alt=''></img>
                <div>
                    <Dropdown className='header_drop'>
                        <Dropdown.Trigger>
                            {/* <Chip> */}
                            <div className='flex items-center gap-3'>
                            {/* <SoftwareLogin className='w-full max-w-9 min-w-9 h-full max-h-9 min-h-9 rounded-full object-cover'/> */}
                            <GenericLogIn className='w-full max-w-9 min-w-9 h-full max-h-9 min-h-9 rounded-full object-cover'/>
                                {/* <img className='w-full max-w-9 min-w-9 h-full max-h-9 min-h-9  border rounded-full object-cover' src={userIcon} alt='' /> */}
                                {/* <ControlsChevronDown /> */}
                                </div>

                            {/* </Chip> */}
                        </Dropdown.Trigger>
                        <Dropdown.Options className='bg-popo border-0'>
                            {/* <div className='flex items-center gap-4 p-2 border-b border-black-900'>
                                <div>
                                    <img className='max-w-9 min-w-9 h-full max-h-9 min-h-9 border rounded-full object-cover' src={userIcon} alt=''></img>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <h6 className="font-bold leading-5">{userInfo?.first_name} {userInfo?.last_name}</h6>
                                    <small className='capitalize'>{userInfo?.role}</small>
                                </div>
                            </div> */}
                            <Dropdown.Option>
                                <MenuItem onClick={()=>{
                                    navigate('/user/login')
                                    }}>
                                    <MenuItem.Title>Login as a user</MenuItem.Title>
                                </MenuItem>
                                <MenuItem onClick={()=>{
                                    navigate('/vakeel/login')
                                    }}>
                                    <MenuItem.Title>Login as a lawyer</MenuItem.Title>
                                </MenuItem>
                            </Dropdown.Option>
                        </Dropdown.Options>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
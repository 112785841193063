
import React, { useEffect, useState } from 'react'
import { Input, Button, Textarea, Label, NativeSelect } from '@heathmont/moon-core-tw';
import Navbar from '../../components/vakeel/Navbar';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { baseURLImg } from '../../config';
import { getProfile, profileUpdate } from '../../services/vakeel/profile-service';
import { toast } from 'react-toastify';
import profileSchema from '../../validation-schemas/vakeel/profile-schema';
import ButtonLoading from '../../components/loading/ButtonLoading';
import userIcon from '../../images/usericon_l.svg'

const caseTypes = ['Civil', 'Property', 'Corporate', 'Forms & Stamps', 'Dispute resolution', 'E-signature', 'Business incorporation', 'Criminal'];
const Profile = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [previewUrl, setPreviewUrl] = useState(null);

    const [profileImage, setProfileImage] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [isSubmitted, setIsubmitted] = useState(false)

    const getProfileDetails = async (user_id) => {
        const response = await getProfile(user_id);
        if (response.status === 200) {
            if (response.data.profile_image_url != null && response.data.profile_image_url !== '') {
                setPreviewUrl(`${baseURLImg}/${response.data.profile_image_url}`)
            }
            setProfileData(response.data);
            setIsubmitted(response.data.is_profile_completed)
        }
    }

    useEffect(() => {
        getProfileDetails(userInfo?.user_id)
    }, [])
    // console.log(profileData, "profileData")
    const formik = useFormik({
        initialValues: {
            full_name: profileData?.full_name,
            email: profileData?.email,
            mobile_number: profileData?.mobile_number,
            sanadNumber: profileData ? profileData.sanad_number : '',
            aadharNumber: profileData ? profileData.aadhar_number : '',
            areaOfExpertise: profileData ? profileData.area_of_expertise : '',
            experience: profileData ? profileData.experience_years : '',
            briefIntro: profileData ? profileData.brief_intro : ''
        },
        enableReinitialize: true,
        validationSchema: profileSchema,
        onSubmit: async (values, { resetForm }) => {
            handleUpdateProfile(values);
        },
    });

    const handleUpdateProfile = async (values) => {
        const formData = new FormData();
        console.log(values)
        formData.append('full_name', values.full_name);
        formData.append('email', values.email);
        formData.append('mobile_number', values.mobile_number);
        formData.append('aadhar_number', values.aadharNumber);
        formData.append('sanad_number', values.sanadNumber);
        formData.append('area_of_expertise', values.areaOfExpertise);
        formData.append('experience_years', values.experience);
        formData.append('brief_intro', values.briefIntro);
        formData.append('image', profileImage);

        setLoading(true);
        const response = await profileUpdate(formData, userInfo?.user_id);
        if (response.status === 200) {
            localStorage.setItem('profileImg', response.data.profile_image_url);
            let user_info = { ...userInfo, full_name: values.full_name, mobile_number: values.mobile_number, email: values.email }
            localStorage.setItem('userInfo', JSON.stringify(user_info));
            setTimeout(() => {
                setIsubmitted(response.data.is_profile_completed);
                setLoading(false);
                toast.success('Profile submitted successfully', { autoClose: 3000 });
            }, 2000)
        }
        else {
            toast.error(response.data.message, { autoClose: 3000 });
            setLoading(false);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Read the selected file and set the preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setProfileImage(file);
        } else {
            setPreviewUrl(null);
            setProfileImage(null);
        }
    };

    const uploadFile = () => {
        document.getElementById("profile_img").click()
    }
    if (isSubmitted && !profileData.is_approved && !profileData.is_rejected) {
        navigate('/profile-submitted')
    }
    // if (isSubmitted && !profileData.is_approved && !profileData.is_rejected) {
    //     return (
    //         <Confirmation />
    //     )
    // }
    return (
        <div>
            <Navbar />
            <div className='mt-24'>
                <div className='container mx-auto px-5 max-w-screen-xl max-md:px-3'>
                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo max-w-screen-xl mx-auto rounded-lg max-md:py-5 max-md:px-5'>
                        <h2 className='lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-7 text-white'>My Profile</h2>
                        <div className='flex max-lg:flex-col'>
                            <div className='w-2/5 max-lg:w-full max-lg:mb-4'>
                                <div className='flex flex-col items-center gap-4'>
                                    {/* <img className='w-full max-w-40 h-full max-h-40 rounded-full border-2 border-indigo-600'
                                        src={`${baseURLImg}/294981.png`} alt='#'></img> */}
                                    <div className='w-full max-w-40 min-w-40 h-full max-h-40 min-h-40 rounded-full border-2 border-chichi flex items-center justify-center'>
                                        <img className='w-full max-w-40 min-w-40 h-full max-h-40 min-h-40 rounded-full object-cover border-2 border-chichi'
                                            src={previewUrl == null ? userIcon : previewUrl} alt='#'></img>
                                    </div>
                                    {/* <img className='w-full max-w-40 h-full max-h-40 rounded-full border-2 border-indigo-600'
                                        src='https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250' alt='#'></img> */}
                                    <Link to="#" onClick={(e) => {
                                        if (loading) {
                                            e.preventDefault();
                                        }
                                        else {
                                            uploadFile()
                                        }
                                    }} className="mb-0 text-[16px] font-normal text-chichi opacity-75 hover:opacity-100">Upload a photo</Link>
                                    <input type='file' id='profile_img' style={{ visibility: 'hidden' }} onChange={handleFileChange} />
                                </div>
                            </div>
                            <form onSubmit={formik.handleSubmit} className='w-3/5 max-lg:w-full'>
                                <div className='flex flex-col gap-4'>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Full Name</Label>
                                        <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="Name" name="full_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.full_name} />
                                        {formik.touched.full_name && formik.errors.full_name ? (
                                            <small className="text-danger validationError">{formik.errors.full_name}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Email ID </Label>
                                        <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="name@gmail.com" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                        {formik.touched.email && formik.errors.email ? (
                                            <small className="text-danger validationError">{formik.errors.email}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Mobile Number </Label>
                                        <Input size="lg" type="number" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="+91 5645312564" name="mobile_number" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mobile_number} />
                                        {formik.touched.mobile_number && formik.errors.mobile_number ? (
                                            <small className="text-danger validationError">{formik.errors.mobile_number}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Sanad Number </Label>
                                        <Input size="lg" type="number" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="00000000000000000000" name="sanadNumber" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.sanadNumber} />
                                        {formik.touched.sanadNumber && formik.errors.sanadNumber ? (
                                            <small className="text-danger validationError">{formik.errors.sanadNumber}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Aadhar Card Number </Label>
                                        <Input size="lg" type="number" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="0000-0000-0000" name="aadharNumber" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.aadharNumber} />
                                        {formik.touched.aadharNumber && formik.errors.aadharNumber ? (
                                            <small className="text-danger validationError">{formik.errors.aadharNumber}</small>
                                        ) : ''}
                                    </div>
                                    {/* <div className="relative pb-2">
                                        <Label size="md" className='font-medium' htmlFor="">Area of Expertise </Label>
                                        <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="Civil law" name="areaOfExpertise" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.areaOfExpertise} />
                                        {formik.touched.areaOfExpertise && formik.errors.areaOfExpertise ? (
                                            <small className="text-danger validationError">{formik.errors.areaOfExpertise}</small>
                                        ) : ''}
                                    </div> */}
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' for="">Area of Expertise </Label>
                                        <NativeSelect readOnly={loading ? true : false} className="rounded-md h-14 leading-[3.5rem] text-white" size="lg" name="areaOfExpertise" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.areaOfExpertise}>
                                            <option className='text-white' value="">Select the area of your expertise</option>
                                            {caseTypes.map((x) => {
                                                return (
                                                    <option value={x}>{x}</option>
                                                )
                                            })}
                                        </NativeSelect>
                                        {formik.touched.areaOfExpertise && formik.errors.areaOfExpertise ? (
                                            <small className="text-danger validationError">{formik.errors.areaOfExpertise}</small>
                                        ) : ''}
                                    </div>
                                    <div className="relative pb-2">
                                        <Label size="md" className='font-medium text-white' htmlFor="">Experience (in years)</Label>
                                        <Input size="lg" type="number" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" readOnly={loading ? true : false} placeholder="12 years" name="experience" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.experience} />
                                        {formik.touched.experience && formik.errors.experience ? (
                                            <small className="text-danger validationError">{formik.errors.experience}</small>
                                        ) : ''}
                                    </div>
                                    <div className='col-span-2 relative pb-2'>
                                        <Label size="md" className="font-medium text-white" htmlFor="">Brief Introduction</Label>
                                        <Textarea className="rounded-md h-14 leading-3 text-white" size="lg" name="briefIntro" readOnly={loading ? true : false} onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.briefIntro} />
                                        {formik.touched.briefIntro && formik.errors.briefIntro ? (
                                            <small className="text-danger validationError">{formik.errors.briefIntro}</small>
                                        ) : ''}
                                    </div>
                                    <div className='col-span-2'>
                                        <Button to="proile" className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg" type='submit' disabled={!formik.isValid || loading}>  {loading &&
                                            <ButtonLoading />
                                        }Save Information</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer position='bottom-right' /> */}
        </div>
    )
}

export default Profile



import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, } from '@heathmont/moon-core-tw'
import { baseURLApi } from '../config';
import axios from 'axios';
import { toast } from 'react-toastify'

const DelayAppointment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const appointment = queryParams.get('appointment');
    const navigate = useNavigate()
    if (!appointment) {
        navigate('/')
    }
    const handleFastrack = async () => {
        try {
            const res = await axios.get(`${baseURLApi}vakeel/delay-appointment/${appointment}`,)
            console.log(res)
            toast.success(res.data.message)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className='h-screen flex items-center max-w-[600px] m-auto'>
                <div className='container mx-auto px-5 max-w-screen-xl max-md:px-3'>
                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo mx-auto rounded-lg max-md:py-5 max-md:px-5 mt-5 '>
                        <b className='text-lg text-center mb-3 text-white'>Delay appointment</b>
                        <p className='text-md text-center font-medium mb-0 text-white'>
                            Are you sure you want to wait for Vakeel to respond in 24 Hours?
                        </p>
                        <div className='block'>
                            <br />
                            <Button type="submit" className=' w-full block rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg"
                                onClick={handleFastrack} >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DelayAppointment
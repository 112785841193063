import React, { Component } from 'react';
import './messagebox.css';

class MessageBox extends Component {
    getConversations(messages) {
        if (messages === undefined) {
            return;
        }

        const listItems = messages.map((message, index) => {

            if (message.type === 0) {
                return (
                    <li className='header1' >

                        <div key={index} >

                            <li className='me' style={{ borderRadius: '12px 12px 0 12px' ,color:'#000'}}>
                                {message.text}
                            </li>
                        </div>

                    </li>

                );
            }
            else {
                return (
                    <li className='header2' style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <div>
                            <img src={require('../img/chatbot.png')} width='30px' alt='img' style={{ marginTop: '10px' }} />
                        </div>
                        <div key={index}>

                            <li className='him' style={{ borderRadius: '0 12px 12px 12px' }}>

                                {message.text}
                            </li>
                        </div>
                    </li>

                );
            }


        });
        return listItems;
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }
    render() {
        const chatList = this.getConversations(this.props.messageContent);
        return (

            <div className='chatbot'>
                <ul id='custom-ul'>
                    {chatList}
                </ul>
                <div style={{ float: "left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>


        )
    }
}

export default MessageBox;


import React, { useEffect, useState } from 'react';
import { Dropdown, MenuItem } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { ControlsChevronDown } from '@heathmont/moon-icons-tw';
import { Link, useNavigate } from 'react-router-dom';
import userIcon from '../../images/usericon_s.svg'
import { baseURLImg } from '../../config';
import { latestCase } from '../../services/user/case-service';


const Navbar = () => {
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('userInfo', userInfo)
    let userImg = localStorage.getItem('userProfileImg');
    if(userImg == 'null') userImg = null
    const [caseId, setCaseId] = useState(0)
    const getLatestCase = async (user_id) => {
        const response = await latestCase(user_id)
        if (response.status === 200) {
            // console.log('caseData123',  response.data);
            localStorage.setItem('caseType', response.data.incident_type)
            setCaseId(response.data?.case_detail_id)
        } else if (response.status === 404) {
            setCaseId(0)
        } else {
            console.log('err')
        }
    }

    useEffect(() => {
        getLatestCase(userInfo?.user_id)
    }, [])

    const logout = () => {
        localStorage.clear();
        navigate('/user/login');
    }
    return (
        <div className='bg-popo py-3 px-5 fixed top-0 w-full z-40 border-b'>
            <div className="flex items-center justify-between w-full gap-2">
                <Link to='/user'>
                    <img className='max-w-[100px] min-w-[100px] h-auto max-h-auto min-h-auto object-contain' src={header_logo} alt=''></img>
                </Link>
                <div>
                    <Dropdown className='header_drop'>
                        <Dropdown.Trigger>
                            <div className='flex items-center gap-3'>
                                <img className='w-full max-w-8 min-w-8 h-full max-h-8 min-h-8  border rounded-full object-cover' src={userImg !== '' && userImg != null ? `${baseURLImg}/${userImg}` : userIcon} alt='' />
                                <span style={{ whiteSpace: 'nowrap' }}>Hi {`${userInfo?.first_name || userInfo.full_name}`}</span> <ControlsChevronDown />
                            </div>
                        </Dropdown.Trigger>
                        <Dropdown.Options className='bg-popo border-0'>
                            <div className='flex items-center gap-4 p-2 border-b border-black-900'>
                                <div>
                                    <img className='max-w-9 min-w-9 h-full max-h-9 min-h-9 border rounded-full object-cover' src={userImg !== '' && userImg != null ? `${baseURLImg}/${userImg}` : userIcon} alt=''></img>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <h6 className="font-bold leading-5 text-white">{userInfo?.first_name} {userInfo?.last_name}</h6>
                                    <small className='capitalize text-white'>{userInfo?.role}</small>
                                </div>
                            </div>
                            <Dropdown.Option>
                                <MenuItem className="text-white" onClick={() => navigate('/user')}>
                                    <MenuItem.Title className="text-white">Home</MenuItem.Title>
                                </MenuItem>
                                <MenuItem className="text-white" onClick={() => navigate('/user/profile')}>
                                    <MenuItem.Title className="text-white">Profile</MenuItem.Title>
                                </MenuItem>
                                {/* <MenuItem onClick={()=>navigate('/user/lawyers')}>
                                    <MenuItem.Title>Lawyers</MenuItem.Title>
                                </MenuItem> */}
                                <MenuItem className="text-white" onClick={() => navigate('/user/register-case')}>
                                    <MenuItem.Title className="text-white">Register Case</MenuItem.Title>
                                </MenuItem>
                                <MenuItem className="text-white" onClick={() => navigate('/user/payment-history')}>
                                    <MenuItem.Title className="text-white">Payment History</MenuItem.Title>
                                </MenuItem>
                                <MenuItem className="text-white" onClick={() => {
                                    if (caseId != 0) {
                                        localStorage.setItem('currentCaseId', caseId)
                                        navigate(`/user/cases-details/${caseId}`)
                                    }
                                }}>
                                    <MenuItem.Title className="text-white">All Cases</MenuItem.Title>
                                </MenuItem>
                                {/* <MenuItem onClick={()=>console.log('')}>
                                    <MenuItem.Title>Update password</MenuItem.Title>
                                </MenuItem> */}
                                <MenuItem className="text-white" onClick={logout}>
                                    <MenuItem.Title className="text-white"> Log out</MenuItem.Title>
                                </MenuItem>
                            </Dropdown.Option>
                        </Dropdown.Options>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
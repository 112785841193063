import api from "../api"

const signup = (reqBody) => {
    return api.post('user/register', reqBody).catch((err) => err.response)
}
const signin = (reqBody) => {
    return api.post('user/login', reqBody).catch((err) => err.response)
}
const sendOTP = (reqBody) => {
    return api.post('otp/send-otp', reqBody).catch((err) => err.response)
}
const verifyOTP = (reqBody) => {
    return api.post('otp/verify-otp', reqBody).catch((err) => err.response)
}
const updateProfile = (user_id, reqBody) => {
    return api.put(`user/update-profile/${user_id}`, reqBody).catch((err) => err.response)
}
const userProfile = (user_id) => {
    return api.get(`user/user-details/${user_id}`).catch((err) => err.response)
}

export { signup, signin, updateProfile, userProfile, sendOTP, verifyOTP }
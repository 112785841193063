import React, { useEffect, useState } from 'react'
import Navbar from '../../components/user/Navbar'
import { Button, IconButton, Input, Label, Modal, NativeSelect } from '@heathmont/moon-core-tw'
import { Link, useNavigate } from 'react-router-dom'
import vakeel_img from '../../images/vakeel_img.jpg'
import userIcon from '../../images/usericon_l.svg'
import { getLawyers, getThreeLawyers } from '../../services/user/lawyers-service'
import { baseURLApi, baseURLImg } from '../../config'
import { useFormik } from 'formik'
import { ControlsClose } from '@heathmont/moon-icons-tw'
import { allCases, bookConsultation } from '../../services/user/case-service'
import { toast } from 'react-toastify'
import scheduleSchema from '../../validation-schemas/user/schedule-schema'
import axios from 'axios'
import { PopupButton, PopupModal } from 'react-calendly'

const Lawyers = () => {
    // const env = process.env
    // console.log(env, "env")
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [cases, setCases] = useState([]);
    const [vakeelId, setVakeelId] = useState('');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [lawyers, setLawyers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [amount, setAmount] = useState(0);
    // const [appointmentDetails, setAppointmentDetails] = useState({});
    const caseId = localStorage.getItem('currentCaseId');
    const caseType = localStorage.getItem('caseType');
    // console.log(caseType)
    const closeModal = () => {
        setIsOpen(false)
        formik.resetForm();
        setEndTime('')
    };
    const openModal = () => setIsOpen(true);

    const getAllLawyers = async () => {
        const body = {
            "area_of_expertise": caseType
        }
        const response = await getThreeLawyers(body);
        console.log(response, "Response")
        if (response.status === 200) {
            // console.log(response?.data?.data)
            // let filteredLawyers = response.data.filter((x) => x.area_of_expertise == caseType)
            // if (caseType == '' || filteredLawyers.length == 0) {
            //     filteredLawyers = response.data
            // }
            setLawyers(response?.data?.data)
        }
    }

    useEffect(() => {
        getAllLawyers()
    }, []);
    const [endTime, setEndTime] = useState('');
    const formik = useFormik({
        initialValues: {
            appointment_date: '',
            start_time: '',
            end_time: endTime,
            // case: ''
        },
        validationSchema: scheduleSchema,
        onSubmit: async (values) => {
            setLoading(true)
            const reqBody = {
                appointment_date: values.appointment_date,
                start_time: `${values.appointment_date} ${values.start_time}`,
                end_time: `${values.appointment_date} ${endTime}`,
                case_id: Number(caseId),
                case_name: '',
                case_type: '',
                client_id: userInfo?.user_id,
                vakeel_id: vakeelId,


            }
            // console.log('reqBody', reqBody)
            // setAppointmentDetails(reqBody);
            consultNow(reqBody)
            // const response = await bookConsultation(reqBody);
            // if (response.status === 201) {
            //     closeModal();
            //     toast.success('Your appointment has been booked.')
            // }
        },
    });

    const getAllCases = async () => {
        const response = await allCases(userInfo?.user_id);
        if (response.status === 200) {
            setCases(response.data);
        }
    };

    useEffect(() => {
        getAllCases();
    }, []);
    const today = new Date();
    const minDate = new Date(today);
    minDate.setDate(today.getDate() + 3); // Minimum date is 3 days from today

    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 23); // Maximum date is 23 days from today

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const token = userInfo?.token
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    // payment
    const consultNow = async (appointmentDetails) => {
        const reqBody = {
            amount: amount * 100
        }
        // const { data } = await axios.post('http://122.168.125.73:8058/api/user/checkout', reqBody)
        const { data } = await axios.post(`${baseURLApi}user/checkout`, reqBody, config)
        // console.log('data', data);
        // console.log('amount', data?.data?.order?.amount);
        // console.log('order', data?.data?.order?.id);
        const options = {
            key: "rzp_test_eDawdhRtZBJkL4",
            // key: "rzp_test_YD1PceY5BUC7kj",
            amount: data?.data?.order?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Vakeel",
            description: "Test Transaction",
            image: "",
            order_id: data?.data?.order?.id,
            handler: async (response) => {
                // Handle the successful payment response
                // console.log('appointmentDetails', appointmentDetails)
                const appointmentRes = await bookConsultation(appointmentDetails);
                if (appointmentRes.status === 201) {
                    closeModal();
                    toast.success('Your appointment has been booked.');
                    navigate(`/user/cases-details/${caseId}`)
                    // console.log('appointmentRes', appointmentRes)
                }
                // closeModal();
                // Send payment information to your server to store in the database
                const reqBody = {
                    user_id: userInfo.user_id,
                    // appointment_id: 1,
                    appointment_id: appointmentRes?.data?.data?.appointment_id,
                    transaction_type: '',
                    amount: data?.data?.order?.amount,
                    payment_data: {
                        razorpay_order_id: data?.data?.order?.id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                    }
                }
                // await axios.post('http://122.168.125.73:8058/api/user/paymentVerification', reqBody);
                await axios.post(`${baseURLApi}user/paymentVerification`, reqBody, config);

                // Handle any additional logic (e.g., redirect to a thank-you page)
            },
            // "callback_url": "http://localhost:5800/api/user/paymentVerification",
            modal: { ondismiss: function () { setLoading(false) } },
            prefill: {
                name: "Gaurav Kumar",
                email: "gaurav.kumar@example.com",
                contact: "9000090000"
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#3399cc"
            }
        };

        const razor = new window.Razorpay(options);
        razor.open();
    }

    const [showFullContent, setShowFullContent] = useState(false)

    // const content = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled `
    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };
    // const shouldDisplayButton = content.length > 150;
    const displayedContent = (content = '') => {
        if (showFullContent) {
            return content
        } else if (content.length > 150) {
            return content.slice(0, 150) + '...'
        } else {
            return content
        }
    }
    // const displayedContent = showFullContent
    //     ? content
    //     : content.length > 150
    //         ? content.slice(0, 150) + '...'
    //         : content;

    const timeSlots = [
        '9:00 AM', '9:20 AM', '9:40 AM',
        '10:00 AM', '10:20 AM', '10:40 AM',
        '11:00 AM', '11:20 AM', '11:40 AM',
        '12:00 PM', '12:20 PM', '12:40 PM',
        '1:00 PM', '1:20 PM', '1:40 PM',
        '2:00 PM', '2:20 PM', '2:40 PM',
        '3:00 PM', '3:20 PM', '3:40 PM',
        '4:00 PM', '4:20 PM', '4:40 PM',
        '5:00 PM', '5:20 PM', '5:40 PM',
        '6:00 PM', '6:20 PM', '6:40 PM',
        '7:00 PM', '7:20 PM', '7:40 PM',
        '8:00 PM', '8:20 PM', '8:40 PM',
        '9:00 PM'
        // Add more time slots as needed
    ];

    // const timeSlots = [
    //     '8:00 AM', '8:15 AM', '8:30 AM', '8:45 AM',
    //     '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM',
    //     '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
    //     '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
    //     // Add more time slots as needed
    //     '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
    //     '1:00 PM', '1:15 PM', '1:30 PM', '1:45 PM',
    //     '2:00 PM', '2:15 PM', '2:30 PM', '2:45 PM',
    //     '3:00 PM', '3:15 PM', '3:30 PM', '3:45 PM',
    //     '4:00 PM', '4:15 PM', '4:30 PM', '4:45 PM',
    //     '5:00 PM', '5:15 PM', '5:30 PM', '5:45 PM',
    //     '6:00 PM', '6:15 PM', '6:30 PM', '6:45 PM',
    //     '7:00 PM', '7:15 PM', '7:30 PM', '7:45 PM',
    //     // Add more time slots as needed
    // ];
    const handleTimeSelect = (selectedTime) => {
        // Find the index of the selected time
        const selectedIndex = timeSlots.indexOf(selectedTime);

        // Update the state with the next time slot (45 minutes later)
        if (selectedIndex !== -1 && selectedIndex + 1 < timeSlots.length) {
            setEndTime(timeSlots[selectedIndex + 2]);
            // console.log(timeSlots[selectedIndex + 3])
        }
    };
    // console.log(lawyers,"lawyers")
    return (
        <div>
            <Navbar />
            <div className='mt-22 max-md:mt-20'>
                <div className='banner_img bg-cover bg-center bg-no-repeat h-[350px] w-full'></div>
                <div className='container mx-auto px-5 max-md:px-3'>
                    <div className='mt-10'>
                        {lawyers.length > 0 && lawyers.map((lawyer, index) => {
                            return (
                                <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo max-w-[900px] mx-auto rounded-lg max-md:py-5 max-md:px-5 mb-5' key={index}>
                                    <div className='flex gap-10 max-md:flex-col-reverse'>
                                        <div className='w-full'>
                                            <h2 className='lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-2 capitalize text-white'>Adv. {lawyer.full_name}</h2>
                                            {/* <span className='w-full text-[16px] font-normal text-black mb-2 block'>4.7 (289 reviews)</span> */}
                                            <div className='flex gap-2 items-center'>
                                                <b className='text-[16px] whitespace-nowrap text-white'>₹ {lawyer.rate}/-</b>
                                                <ul className='flex gap-8 max-md:gap-4 items-center'>
                                                    <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>40 mins</li>
                                                    <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>{lawyer?.experience_years} years </li>
                                                    <li className='w-full mb-0 text-[16px] font-normal text-white whitespace-nowrap'>English & Hindi</li>
                                                </ul>
                                            </div>
                                            <ul className='border-t border-dashed py-4 mt-5 list-none pl-0'>
                                                <li className='w-full mb-0 text-[16px] font-normal text-white'><b>Legal Expertise</b>: {lawyer.area_of_expertise}</li>
                                                <li className='w-full mb-0 text-[16px] font-normal text-white'>{displayedContent(lawyer?.brief_intro)}</li>
                                            </ul>
                                            {lawyer?.brief_intro.length > 150 && (
                                                <Link className='text-[16px] visited:text-chichi font-medium text-chichi opacity-70 hover:text-chichi hover:opacity-100 focus:outline-none underline w-fit'
                                                    onClick={toggleContent}>{showFullContent ? 'See less' : 'See more'} </Link>
                                            )}
                                        </div>
                                        <div className='flex flex-col justify-between items-center gap-5 min-w-[220px] min-h-[220px]'>
                                            <img className="w-full h-full max-w-[140px] max-h-[140px] rounded-lg object-cover" src={lawyer?.profile_image_url ? `${baseURLImg}/${lawyer.profile_image_url}` : userIcon} alt=''></img>
                                            <Button to="/" className='w-fit rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' onClick={() => {
                                                openModal();
                                                // consultNow()
                                                setVakeelId(lawyer?.user_id);
                                                setAmount(lawyer?.rate)
                                                // console.log('lawyer', lawyer)
                                            }} size="lg">Consult now</Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <Modal open={isOpen} onClose={closeModal} className="bg-popo">
                            <Modal.Backdrop />
                            <Modal.Panel className='max-w-[700px] bg-popo'>
                                <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
                                    <h3 className="text-moon-18 text-white font-medium">
                                        Book an appointment
                                    </h3>
                                    <div className="">
                                        <IconButton onClick={closeModal} className='bg-black' icon={<ControlsClose />} />
                                    </div>
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="p-4">
                                        <div>
                                            <div className='mb-4 pb-2 relative text-white'>
                                                <label className='block mb-2 text-white text-[16px] font-normal '>Appointment Date</label>
                                                <Input className='rounded-md text-white' type="date" size="md" placeholder="Appointment Date"
                                                    name='appointment_date'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.appointment_date}
                                                    min={formatDate(minDate)}
                                                    max={formatDate(maxDate)}

                                                />
                                                {formik.touched.appointment_date && formik.errors.appointment_date ? (
                                                    <small className="text-danger validationError">{formik.errors.appointment_date}</small>
                                                ) : ''}
                                            </div>

                                            <div className='flex gap-3 w-full'>
                                                <div className='mb-4 pb-2 w-full relative'>
                                                    <label className='block mb-2 text-white text-[16px] font-normal '>Start Time</label>
                                                    <NativeSelect size="lg" name='start_time' onChange={(e) => {
                                                        formik.handleChange(e)
                                                        handleTimeSelect(e.target.value)
                                                    }} onBlur={formik.handleBlur} value={formik.values.start_time}>
                                                        <option value="">Select start time</option>
                                                        {timeSlots.slice(0, 35).map((slot) => (
                                                            <option key={slot} value={slot}>{slot}</option>
                                                        ))}
                                                    </NativeSelect>
                                                    {formik.touched.start_time && formik.errors.start_time ? (
                                                        <small className="text-danger validationError">{formik.errors.start_time}</small>
                                                    ) : ''}
                                                </div>
                                                <div className='mb-4 pb-2 w-full relative'>
                                                    <label className='block mb-2 text-[16px] font-normal text-white'> End Time</label>
                                                    <NativeSelect size="lg" name='end_time' onChange={formik.handleChange} onBlur={formik.handleBlur} value={endTime} readOnly>
                                                        <option value=''>End time</option>
                                                        <option value={endTime}>{endTime}</option>
                                                    </NativeSelect>
                                                    {/* <NativeSelect size="lg" name='end_time' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.end_time}>
                                                        <option value="">Select end time</option>
                                                        {timeSlots.map((slot) => (
                                                            <option key={slot} value={slot}>{slot}</option>
                                                        ))}
                                                    </NativeSelect> */}
                                                    {formik.touched.end_time && formik.errors.end_time ? (
                                                        <small className="text-danger validationError">{formik.errors.end_time}</small>
                                                    ) : ''}
                                                </div>
                                            </div>
                                            <div>
                                                <small className='text-white'>Please select time in between 9 AM to 9 PM. </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 border-t-2 border-beerus flex justify-end">
                                        <Button size='lg' type='submit' className='w-fit rounded-md bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500'
                                            disabled={!formik.isValid ||loading}>
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            </Modal.Panel>
                        </Modal>
                        {/* <PopupModal
                            url="https://calendly.com/rsgourind"
                            onModalClose={closeModal}
                            open={isOpen}
                            rootElement={document.getElementById("root")}
                        /> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lawyers

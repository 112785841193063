import React, { useState } from 'react'
import Navbar from '../../components/user/Navbar'
import { Button, Input, Label, NativeSelect, Textarea } from '@heathmont/moon-core-tw'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getEvidenceFile, submitCase , uplodaFile} from '../../services/user/case-service'
import { toast } from 'react-toastify'
import registerCaseSchema from '../../validation-schemas/user/register-case-schema'
import ButtonLoading from '../../components/loading/ButtonLoading'
import axios from 'axios'
import { baseURLApi } from '../../config'
import { userProfile } from '../../services/user/auth-service'
// import vakeel_img from '../../images/vakeel_img.jpg'
// import { Input } from 'postcss'

const caseTypes = ['Civil', 'Property', 'Corporate', 'Forms & Stamps', 'Dispute resolution', 'E-signature', 'Business incorporation', 'Criminal'];
const cases = [
    { type: 'Civil', name: 'Aiding and Abetting' },
    { type: 'Civil', name: 'Aggravated Assault' },
    { type: 'Civil', name: 'Abetting' },
    { type: 'Civil', name: 'Breach of Trust' },
    { type: 'Civil', name: 'Disorderly Conduct' },
    { type: 'Civil', name: 'Disturbing the Peace' },
    { type: 'Civil', name: 'Consumer Rights' },
    { type: 'Civil', name: 'Mischief' },
    { type: 'Civil', name: 'Indecent Exposure' },
    { type: 'Civil', name: 'Telemarketing Fraud' },
    { type: 'Property', name: 'Bribery (Corp)' },
    { type: 'Property', name: 'Property Dispute' },
    { type: 'Corporate', name: 'Tax Evasion / Fraud' },
    { type: 'Corporate', name: 'Corporate Fraud' },
    { type: 'Forms & Stamps', name: 'Form 16' },
    { type: 'Forms & Stamps', name: 'Form 10 A' },
    { type: 'Forms & Stamps', name: 'Form 20B' },
    { type: 'Forms & Stamps', name: 'Rent Agreement' },
    { type: 'Dispute resolution', name: 'Dowry Dispute' },
    { type: 'Dispute resolution', name: 'Domestic Dispute' },
    { type: 'Dispute resolution', name: 'RWA Dispute' },
    { type: 'E-signature', name: 'esign' },
    { type: 'E-signature', name: 'esignature' },
    { type: 'Business Incorporation', name: 'New Company' },
    { type: 'Business Incorporation', name: 'New Business' },
    { type: 'Business Incorporation', name: 'Registration of company' },
    { type: 'Business Incorporation', name: 'Company Registration' },
    { type: 'Criminal', name: 'Arson' },
    { type: 'Criminal', name: 'Registration' },
    { type: 'Criminal', name: 'Assault / Battery' },
    { type: 'Criminal', name: 'Attempt' },
    { type: 'Criminal', name: 'Acid Attack' },
]

const SubmitCase = () => {
    const [loading, setLoading] = useState(false);
    const [caseType, setCaseType] = useState('');
    const today = new Date().toISOString().split('T')[0];
    const maxDate = today;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate()
    
    const formik = useFormik({
        initialValues: {
            phone_number: userInfo.mobile,
            email: userInfo.email,
            case_name: '',
            consultation_type: '',
            incident_type: '',
            incident_date: null,
            incident_location: '',
            suspect_details: '',
            witness_details: '',
            damages_desc: '',
            evidence_url: '',
        },
        enableReinitialize: true,
        validationSchema: registerCaseSchema,
        onSubmit: async (values) => {
            // console.log(values)
            setLoading(true)
            const reqBody = { ...values, user_id: userInfo.user_id }
            // console.log('reqBody', reqBody)

            // return
            const response = await submitCase(reqBody);
            if (response.status === 201) {
                // console.log('new case', response.data);
                localStorage.setItem('currentCaseId', response.data.data.case_detail_id)
                localStorage.setItem('caseType', response.data.data.incident_type);

                handleUpload(response.data.data.case_detail_id)
                toast.success('Your case has been registered successfully.')
                setTimeout(() => {
                    setLoading(false)
                    navigate('/user/lawyers')
                }, 3000)
            } else {
                setLoading(false)
                toast.error(response.data.message, { autoClose: 3000 });
            }
        },
    });

    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        // setFile(e.target.files);
        setFiles([...e.target.files]);
        console.log('file', e.target.files);
        // let data = 
    };

    const handleUpload = async (caseId) => {
        try {
            if (files.length > 0) {
                const formData = new FormData();
                files.forEach((file, index) => {
                    formData.append('image', file);
                });
                // Make a POST request to your Node.js server for file upload
                const response = await uplodaFile(caseId, formData)//await axios.post(`${baseURLApi}user/case-data/${caseId}`, formData);
                if (response.status === 200) {
                    console.log('filename', response.data.data)
                    const arr = [];

                    response.data.data.forEach(async (x) => {
                        arr.push(x);
                        // console.log('x', x)
                    })
                    await getFile(caseId, arr)
                    console.log('arr', arr);
                }
            }

            console.log('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getFile = async (case_id, files) => {
        try {
            //   const formData = new FormData();
            //   formData.append('image', file);
            const reqBody = {
                file_name: files,
                caseId: case_id,
                isFetchUrl: false

            }
            // Make a POST request to your Node.js server for file upload
            const response = await getEvidenceFile(reqBody)//await axios.post(`${baseURLApi}user/get-file`, reqBody);

            console.log('File fetched successfully', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-22'>
                <div className='container mx-auto px-5 max-md:px-3'>

                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo rounded-lg max-md:py-5 max-md:px-5 max-w-[900px] mx-auto'>
                        <div className='text-center mb-6 max-w-[700px] mx-auto'>
                            <h2 class="lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-3 text-white">Get Started!</h2>
                            <p class="text-md text-center font-medium mb-0 text-white">Help us with some information to create a initial consultation breif.
                                this service is free and your information is kept completely confidential</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='flex flex-col gap-4 mb-5'>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Email </Label>
                                    <div className='flex gap-4'>
                                        <Input size="lg" disabled={userInfo.email} type="email" className="rounded-md min-w-[200px] h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Enter email address" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} max={maxDate} />
                                        {formik.touched.email && formik.errors.email ? (
                                            <small className="text-danger validationError">{formik.errors.email}</small>
                                        ) : ''}
                                        {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Set time" /> */}
                                    </div>
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Consultation type</Label>
                                    <NativeSelect size="lg" name='consultation_type' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.consultation_type}>
                                        <option value="">Select the type of service you need from us</option>
                                        <option value="Legal Advice Consultation">Legal Advice Consultation</option>
                                        <option value="Case Evaluation Consultation">Case Evaluation Consultation</option>
                                        <option value="Document Review Consultation">Document Review Consultation</option>
                                        <option value="Follow-up Consultation">Follow-up Consultation</option>
                                    </NativeSelect>
                                    {formik.touched.consultation_type && formik.errors.consultation_type ? (
                                        <small className="text-danger validationError">{formik.errors.consultation_type}</small>
                                    ) : ''}
                                </div>

                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Case Type </Label>
                                    <NativeSelect size="lg" name='incident_type' onChange={(e) => {
                                        setCaseType(e.target.value);
                                        formik.handleChange(e)
                                    }} onBlur={formik.handleBlur} value={formik.values.incident_type}>
                                        <option value="">Select the type of incident of your case</option>
                                        {caseTypes.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })}
                                    </NativeSelect>
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Case name</Label>
                                    <NativeSelect size="lg" name='case_name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.case_name}>
                                        <option value="">Select the type of incident of your case</option>
                                        {cases.filter((c) => c.type == caseType).map((x) => {
                                            return (
                                                <option value={x.name}>{x.name}</option>
                                            )
                                        })}
                                    </NativeSelect>
                                    {formik.touched.case_name && formik.errors.case_name ? (
                                        <small className="text-danger validationError">{formik.errors.case_name}</small>
                                    ) : ''}
                                </div>

                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Incident Occurance date </Label>
                                    <div className='flex gap-4'>
                                        <Input size="lg" type="date" className="rounded-md min-w-[200px] h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Select the approximate date and time of the incident" name='incident_date' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.incident_date} max={maxDate} />
                                        {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Set time" /> */}
                                    </div>
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Incident location </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem] text-white" id="exampleFormControlInput1" placeholder="Please enter the location" name='incident_location' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.incident_location} />
                                    {formik.touched.incident_location && formik.errors.incident_location ? (
                                        <small className="text-danger validationError">{formik.errors.incident_location}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Upload evidence files </Label>
                                    <Input size="md" type="file" multiple className="rounded-md h-14 leading-[1rem] text-white p-[0.9em]" id="exampleFormControlInput1" placeholder="Please provide proper evidence URL" name='evidence_url' onChange={handleFileChange} />
                                </div>
                                {/* <div className="relative pb-2">
                                    <Label size="md" className='font-medium' for="">Evidence URL </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Please provide proper evidence URL" name='evidence_url' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.evidence_url} />
                                    {formik.touched.evidence_url && formik.errors.evidence_url ? (
                                            <small className="text-danger validationError">{formik.errors.evidence_url}</small>
                                        ) : ''}
                                </div> */}
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Suspect detail </Label>
                                    {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please enter name and detail of the suspect if any" name='suspect_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.suspect_details} /> */}
                                    <Textarea className="rounded-md text-white" size="lg" name='suspect_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.suspect_details} />
                                    {formik.touched.suspect_details && formik.errors.suspect_details ? (
                                        <small className="text-danger validationError">{formik.errors.suspect_details}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Witness detail </Label>
                                    {/* <Input size="lg" type="name" className="rounded-md" id="exampleFormControlInput1" placeholder="Please enter name and detail of the witness if any" name='witness_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.witness_details} /> */}
                                    <Textarea className="rounded-md text-white" size="lg" name='witness_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.witness_details} />
                                    {formik.touched.witness_details && formik.errors.phone_number ? (
                                        <small className="text-danger validationError">{formik.errors.witness_details}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Damages in the incident </Label>
                                    <Textarea className="rounded-md text-white" size="lg" name='damages_desc' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.damages_desc} />
                                    {formik.touched.damages_desc && formik.errors.damages_desc ? (
                                        <small className="text-danger validationError">{formik.errors.damages_desc}</small>
                                    ) : ''}
                                </div>
                                <div className='col-span-2'>
                                    <Button type="submit" className='w-full rounded-md px-20 bg-goten text-black border hover:border hover:border-white hover:text-white ease-in-out duration-500' size="lg"
                                        disabled={loading}>
                                        {loading &&
                                            <ButtonLoading />
                                        }Submit Case</Button>
                                    {/* <Button type="button" className='w-full rounded-md mt-3' size="lg" onClick={handleUpload}>Upload</Button> */}
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SubmitCase

import api from "../api"

const submitCase = (reqBody) => {
    return api.post('user/register-case', reqBody).catch((err) => err.response)
}
const updateUserProfile = (data) => {
    return api.put(`user/update/${data.user_id}`, data.reqBody).catch((err) => err.response)
}
const createSos = (reqBody) => {
    return api.post('user/sos-detail', reqBody).catch((err) => err.response)
}
const createAnonmousCase = (reqBody) => {
    return api.post('anonymous', reqBody).catch((err) => err.response)
}
const getCallbackDetails = () => {
    return api.get('user/get-sos-detail').catch((err) => err.response)
}
const getAnonymousCallbackDetails = () => {
    return api.get('anonymous').catch((err) => err.response)
}
const checkout = (reqBody) => {
    return api.post('user/checkout', reqBody).catch((err) => err.response)
}
const verifyPayment = (reqBody) => {
    return api.post('user/paymentVerification', reqBody).catch((err) => err.response)
}
const allCases = (user_id) => {
    return api.get(`user/all-cases/${user_id}`).catch((err) => err.response)
}
const latestCase = (user_id) => {
    return api.get(`user/latest-case/${user_id}`).catch((err) => err.response)
}
const casesDetails = (user_id, caseId) => {
    return api.get(`user/case-details?user_id=${user_id}&caseId=${caseId}`).catch((err) => err.response)
}
const updateCaseDetails = (caseId, reqBody) => {
    return api.put(`user/update-case/${caseId}`, reqBody).catch((err) => err.response)
}
const bookConsultation = (reqBody) => {
    return api.post(`user/book-appointment`, reqBody).catch((err) => err.response)
}
const reqReschedule = (reqBody) => {
    return api.post(`user/reqest-reschedule`, reqBody).catch((err) => err.response)
}
const passbook = (id) => {
    return api.get(`user/passbook/${id}`).catch((err) => err.response)
}
const uplodaFile = (id , body) => {
    console.log(body , id)
    return api.post(`user/case-data/${id}` , body).catch((err) => err.response)
}

const getEvidenceFile = ( body) => {
    return api.post(`user/get-file`, body).catch((err) => err.response)
}

export {
    submitCase, allCases, casesDetails, bookConsultation, latestCase, passbook, updateCaseDetails,
    reqReschedule, updateUserProfile, createSos, checkout, verifyPayment, getCallbackDetails, createAnonmousCase,
    getAnonymousCallbackDetails,uplodaFile , getEvidenceFile
}
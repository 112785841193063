import React, { useEffect, useState } from 'react'
import Navbar from '../../components/user/Navbar'
import { Button, Input, Label, NativeSelect, Textarea } from '@heathmont/moon-core-tw'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { casesDetails, submitCase, updateCaseDetails, uplodaFile , getFile, getEvidenceFile} from '../../services/user/case-service'
import { toast } from 'react-toastify'
import registerCaseSchema from '../../validation-schemas/user/register-case-schema'
import ButtonLoading from '../../components/loading/ButtonLoading'
import axios from 'axios'
import { baseURLApi } from '../../config'
// import vakeel_img from '../../images/vakeel_img.jpg'
// import { Input } from 'postcss'

const caseTypes = ['Civil', 'Property', 'Corporate', 'Forms & Stamps', 'Dispute resolution', 'E-signature', 'Business incorporation', 'Criminal'];
const cases = [
    { type: 'Civil', name: 'Aiding and Abetting' },
    { type: 'Civil', name: 'Aggravated Assault' },
    { type: 'Civil', name: 'Abetting' },
    { type: 'Civil', name: 'Breach of Trust' },
    { type: 'Civil', name: 'Disorderly Conduct' },
    { type: 'Civil', name: 'Disturbing the Peace' },
    { type: 'Civil', name: 'Consumer Rights' },
    { type: 'Civil', name: 'Mischief' },
    { type: 'Civil', name: 'Indecent Exposure' },
    { type: 'Civil', name: 'Telemarketing Fraud' },
    { type: 'Property', name: 'Bribery (Corp)' },
    { type: 'Property', name: 'Property Dispute' },
    { type: 'Corporate', name: 'Tax Evasion / Fraud' },
    { type: 'Corporate', name: 'Corporate Fraud' },
    { type: 'Forms & Stamps', name: 'Form 16' },
    { type: 'Forms & Stamps', name: 'Form 10 A' },
    { type: 'Forms & Stamps', name: 'Form 20B' },
    { type: 'Forms & Stamps', name: 'Rent Agreement' },
    { type: 'Dispute resolution', name: 'Dowry Dispute' },
    { type: 'Dispute resolution', name: 'Domestic Dispute' },
    { type: 'Dispute resolution', name: 'RWA Dispute' },
    { type: 'E-signature', name: 'esign' },
    { type: 'E-signature', name: 'esignature' },
    { type: 'Business Incorporation', name: 'New Company' },
    { type: 'Business Incorporation', name: 'New Business' },
    { type: 'Business Incorporation', name: 'Registration of company' },
    { type: 'Business Incorporation', name: 'Company Registration' },
    { type: 'Criminal', name: 'Arson' },
    { type: 'Criminal', name: 'Registration' },
    { type: 'Criminal', name: 'Assault / Battery' },
    { type: 'Criminal', name: 'Attempt' },
    { type: 'Criminal', name: 'Acid Attack' },
]

const UpdateCase = () => {
    const [loading, setLoading] = useState(false);
    const [caseType, setCaseType] = useState('');
    const today = new Date().toISOString().split('T')[0];
    const maxDate = today;

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate()

    const params = useParams();
    const [caseInfo, setCaseInfo] = useState({});

    const getCaseDetails = async () => {
        const response = await casesDetails(userInfo?.user_id, params.id);
        if(response.status === 200){
            setCaseInfo(response.data);
            console.log('caseDetails', response.data)
            setCaseType(response.data.incident_type)
        }
    }

    useEffect(() => {
        getCaseDetails()
    }, [])

    const formik = useFormik({
        initialValues: {
            phone_number: userInfo.mobile,
            email: userInfo.email,
            case_name: caseInfo?.case_name,
            consultation_type: caseInfo?.consultation_type,
            incident_type: caseInfo?.incident_type,
            incident_date: caseInfo?.incident_date,
            incident_location: caseInfo?.incident_location,
            suspect_details: caseInfo?.suspect_details,
            witness_details: caseInfo?.witness_details,
            damages_desc: caseInfo.damages_desc,
            evidence_url: caseInfo?.evidence_url
        },
        validationSchema: registerCaseSchema,
        enableReinitialize:true,
        onSubmit: async (values) => {
            console.log(values)
            setLoading(true)

            const response = await updateCaseDetails(params.id,values);
            if (response.status === 200) {
                toast.success('Your case has been updated successfully.');
                setTimeout(()=>{
                    setLoading(false)
                }, 3000)
            }
        },
    });

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        console.log('file', e.target.files[0]);
    };

    const handleUpload = async (caseId) => {
        try {
            const formData = new FormData();
            formData.append('image', file);

            // Make a POST request to your Node.js server for file upload
           const response =  await uplodaFile(caseId, formData)//await axios.post(`${baseURLApi}user/case-data/${caseId}`, formData);
           if(response.status === 200){
            console.log('filename', response.data.data)
                await getFile(caseId, response.data.data)
                console.log('s3File', response)
           }

            console.log('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getFile = async (case_id, file) => {
        try {
            //   const formData = new FormData();
            //   formData.append('image', file);
            const reqBody = {
                file_name: file,
                caseId: case_id
                
            }
            // Make a POST request to your Node.js server for file upload
            const response = await getEvidenceFile(reqBody)//await axios.post(`${baseURLApi}user/get-file`, reqBody);

            console.log('File fetched successfully', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className='mt-24 max-md:mt-22'>
                <div className='container mx-auto px-5 max-md:px-3'>

                    <div className='w-full py-10 px-10 flex flex-col justify-center bg-popo rounded-lg max-md:py-5 max-md:px-5 max-w-[900px] mx-auto'>
                        <div className='text-center mb-6 max-w-[700px] mx-auto'>
                            <h2 class="lg:text-2xl md:text-2xl sm:text-2xl text-xl font-bold mb-3 text-white">Update case details</h2>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='flex flex-col gap-4 mb-5'>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">First name</Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Your name" name='first_name' value={userInfo?.first_name} readOnly />
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Last name</Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Your name" name='last_name' value={userInfo?.last_name} readOnly />
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Email id </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Your email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <small className="text-danger validationError">{formik.errors.email}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Phone number </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Your Phone number" name='phone_number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone_number} />
                                    {formik.touched.phone_number && formik.errors.phone_number ? (
                                        <small className="text-danger validationError">{formik.errors.phone_number}</small>
                                    ) : ''}
                                </div>

                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Consultation type</Label>
                                    <NativeSelect size="lg" name='consultation_type' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.consultation_type}>
                                        <option value="">Select the type of service you need from us</option>
                                        <option value="Legal Advice Consultation">Legal Advice Consultation</option>
                                        <option value="Case Evaluation Consultation">Case Evaluation Consultation</option>
                                        <option value="Document Review Consultation">Document Review Consultation</option>
                                        <option value="Follow-up Consultation">Follow-up Consultation</option>
                                    </NativeSelect>
                                    {formik.touched.consultation_type && formik.errors.consultation_type ? (
                                        <small className="text-danger validationError">{formik.errors.consultation_type}</small>
                                    ) : ''}
                                </div>

                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Case Type </Label>
                                    <NativeSelect size="lg" name='incident_type' onChange={(e) => {
                                        setCaseType(e.target.value);
                                        formik.handleChange(e)
                                    }} onBlur={formik.handleBlur} value={formik.values.incident_type}>
                                        <option value="">Select the type of incident of you case</option>
                                        {caseTypes.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })}
                                    </NativeSelect>
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Case name</Label>
                                    <NativeSelect size="lg" name='case_name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.case_name}>
                                        <option value="">Select the type of incident of you case</option>
                                        {cases.filter((c) => c.type == caseType).map((x) => {
                                            return (
                                                <option value={x.name}>{x.name}</option>
                                            )
                                        })}
                                    </NativeSelect>
                                    {formik.touched.case_name && formik.errors.case_name ? (
                                        <small className="text-danger validationError">{formik.errors.case_name}</small>
                                    ) : ''}
                                </div>

                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Incident Occurance date </Label>
                                    <div className='flex gap-4'>
                                        <Input size="lg" type="date" className="rounded-md min-w-[200px] h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Select the approximate date and time of the incident" name='incident_date' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.incident_date} max={maxDate} />
                                    </div>
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Incident location </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Please enter the location" name='incident_location' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.incident_location} />
                                    {formik.touched.incident_location && formik.errors.incident_location ? (
                                        <small className="text-danger validationError">{formik.errors.incident_location}</small>
                                    ) : ''}
                                </div>
                                {/* <div className="relative pb-2">
                                    <Label size="md" className='font-medium' for="">Evidence URL </Label>
                                    <Input size="lg" type="file" multiple className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Please provide proper evidence URL" name='evidence_url' onChange={handleFileChange} />
                                </div> */}
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Evidence URL </Label>
                                    <Input size="lg" type="name" className="rounded-md h-14 leading-[3.5rem]" id="exampleFormControlInput1" placeholder="Please provide proper evidence URL" name='evidence_url' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.evidence_url} />
                                    {formik.touched.evidence_url && formik.errors.evidence_url ? (
                                            <small className="text-danger validationError">{formik.errors.evidence_url}</small>
                                        ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Suspect detail </Label>
                                    <Textarea className="rounded-md" size="lg" name='suspect_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.suspect_details} />
                                    {formik.touched.suspect_details && formik.errors.suspect_details ? (
                                        <small className="text-danger validationError">{formik.errors.suspect_details}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Witness detail </Label>
                                    <Textarea className="rounded-md" size="lg" name='witness_details' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.witness_details} />
                                    {formik.touched.witness_details && formik.errors.phone_number ? (
                                        <small className="text-danger validationError">{formik.errors.witness_details}</small>
                                    ) : ''}
                                </div>
                                <div className="relative pb-2">
                                    <Label size="md" className='font-medium text-white' for="">Damages in the incident </Label>
                                    <Textarea className="rounded-md" size="lg" name='damages_desc' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.damages_desc} />
                                    {formik.touched.damages_desc && formik.errors.damages_desc ? (
                                        <small className="text-danger validationError">{formik.errors.damages_desc}</small>
                                    ) : ''}
                                </div>
                                <div className='col-span-2'>
                                    <Button type="submit" className='w-full rounded-md mt-3' size="lg" disabled={!formik.isValid}>{loading &&
                                        <ButtonLoading />
                                    }Update Case</Button>
                                    {/* <Button type="button" className='w-full rounded-md mt-3' size="lg" onClick={handleUpload}>Upload</Button> */}
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UpdateCase;

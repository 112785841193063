import api from "../api"
const appointmentList = (id) => {
  return api.get(`vakeel/appointments/${id}`).catch((err) => err.response)
}
const caseDetails = (id) => {
  return api.get(`vakeel/case-details/${id}`).catch((err) => err.response)
}
const allCase = (id) => {
  return api.get(`vakeel/appointments/${id}`).catch((err) => err.response)
}

const allVakeelCase = (id) => {
  return api.get(`vakeel/all-cases/${id}`).catch((err) => err.response)
}
const appointmensByDate = (id, date) => {
  return api.get(`vakeel/appointmentsByDate?vakeel_id=${id}&date=${date}`).catch((err) => err.response)
}
const rescheduleAppointment = (reqBody) => {
  return api.put(`vakeel/reschedule-appointment`, reqBody).catch((err) => err.response)
}
const approve = (id) => {
  return api.put(`vakeel/approve-consultation/${id}`).catch((err) => err.response)
}
const passbook = (id) => {
  return api.get(`vakeel/passbook/${id}`).catch((err) => err.response)
}
const addremarks = (id, reqBody) => {
  return api.post(`vakeel/addRemark/${id}`, reqBody).catch((err) => err.response)
}
export { appointmentList, caseDetails, passbook, appointmensByDate, allCase, rescheduleAppointment, allVakeelCase, addremarks, approve }
import { Navigate } from 'react-router-dom';
import React from 'react';
// import { isAuthenticated } from '../utils/auth';


const PrivateRoute = ({ children, role }) => {
    const userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {}
    // return auth ? children : <Navigate to="/" />;
    if(Object.keys(userInfo).length > 0){
        if(role === userInfo.role){
             return children
        } else if(role === 'user' && userInfo.role === 'vakeel'){
            return <Navigate to="/profile" />
        } else if(role === 'vakeel' && userInfo.role === 'user'){
            return <Navigate to="/user" />
        }
    } else {
        return <Navigate to="/" />
    }

};

export default PrivateRoute;

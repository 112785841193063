import React, { useEffect, useState } from 'react';
import { Dropdown, MenuItem } from '@heathmont/moon-core-tw';
import header_logo from '../../images/header_logo.svg'
import { ControlsChevronDown } from '@heathmont/moon-icons-tw';
import { Link, useNavigate } from 'react-router-dom';
import { baseURLImg } from '../../config';
import userIcon from '../../images/usericon_s.svg'
import { allVakeelCase } from '../../services/vakeel/dashboard-service';


const Navbar = () => {
    const navigate = useNavigate();
    const profileApproved = localStorage.getItem('profileApproved');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const userImg = localStorage.getItem('profileImg');
    const [caseInfo, setCaseInfo] = useState({})

    const getAllVakeelCases = async () => {
        const response = await allVakeelCase(userInfo?.user_id);
        if (response.status === 200) {
            // alert(1)
            if (response.data.length > 0) {
                setCaseInfo(response.data[0]);
            }
        } else {
            setCaseInfo({})
        }
    };

    useEffect(() => {
        getAllVakeelCases()
        // getAllCase();
    }, []);
    const logout = () => {
        localStorage.clear();
        navigate('/vakeel/login');
    }
    return (
        <div className='bg-popo py-3 px-5 fixed top-0 w-full z-40 border-b'>
            <div className="flex items-center justify-between w-full gap-2">
                <Link to='/dashboard'>
                    <img className='max-w-[100px] min-w-[100px] h-auto max-h-auto min-h-auto object-contain' src={header_logo} alt=''></img>
                </Link>
                <div>
                    <Dropdown className='header_drop'>
                        <Dropdown.Trigger>
                            <div className='flex items-center gap-3'><img className='w-full max-w-8 min-w-8 h-full max-h-8 min-h-8  border rounded-full object-cover' src={userImg !== '' && userImg != null ? `${baseURLImg}/${userImg}` : userIcon} alt='' />
                                <span style={{ whiteSpace: 'nowrap' }}>Hi {`${userInfo?.first_name || userInfo.full_name}`}</span> <ControlsChevronDown /></div>
                        </Dropdown.Trigger>
                        <Dropdown.Options className='bg-popo border-0'>
                            <div className='flex items-center gap-4 p-2 border-b border-black-900'>
                                <div>
                                    <img className='max-w-9 min-w-9 h-full max-h-9 min-h-9 border rounded-full object-cover' src={userImg !== '' && userImg != null ? `${baseURLImg}/${userImg}` : userIcon} alt=''></img>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <h6 className="font-bold leading-5 text-white">{userInfo?.first_name} {userInfo?.last_name}</h6>
                                    <small className='capitalize text-white'>{userInfo?.role}</small>
                                </div>
                            </div>
                            <Dropdown.Option>
                                {profileApproved === '1' ?
                                    <>
                                        <MenuItem onClick={() => navigate('/profile')} disabled={profileApproved === '0'}>
                                            <MenuItem.Title> Profile</MenuItem.Title>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate('/dashboard')} disabled={profileApproved === '0'}>
                                            <MenuItem.Title> Diary</MenuItem.Title>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            if (Object.keys(caseInfo).length > 0) {
                                                let data = {
                                                    "appointment_id": caseInfo?.appointment_id,
                                                    "client_user_id": caseInfo?.client_id,
                                                    "appointment_date": caseInfo?.appointment_date,
                                                    "start_time": caseInfo?.start_time,
                                                    "end_time": caseInfo?.end_time,
                                                    "case_name": "",
                                                    "case_type": "",
                                                    "status": true,
                                                    "client_first_name": caseInfo?.client_first_name,
                                                    "client_last_name": caseInfo?.client_last_name,
                                                    "lawyer_first_name": '',
                                                    "lawyer_last_name": ''
                                                }
                                                localStorage.setItem('eventDetails', JSON.stringify(data));
                                                navigate(`/case-detail/${caseInfo?.appointment_id}`);
                                            }
                                        }} disabled={profileApproved === '0'}>
                                            <MenuItem.Title> All Cases</MenuItem.Title>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate('/passbook')} disabled={profileApproved === '0'}>
                                            <MenuItem.Title> Passbook</MenuItem.Title>
                                        </MenuItem>
                                    </> : ''
                                }
                                <MenuItem onClick={logout}>
                                    <MenuItem.Title> Log out</MenuItem.Title>
                                </MenuItem>
                            </Dropdown.Option>
                        </Dropdown.Options>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default Navbar;